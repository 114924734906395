.blockquote{
	position: relative;
	margin: 30px 0;
	&:before{
		content: "\f11f";
		position: absolute;
		font-family: Flaticon;
	}
	&.style-1{
		padding-left: 60px;
		&:before{
			color: $primary;
			left: 0;
			top: 50%;
			font-size: 42px;
			transform: translateY(-50%);
		}
		.blockquote-content{
			font-size: 22px;
			line-height: 32px;
			margin-bottom: 0;
			border-left: 3px solid $primary-light;
			padding-left: 15px;
		}
	}
	&.style-2{
		padding: 20px 30px;
		&:before{
			color: $primary;
			left: 30px;
			font-size: 36px;
			opacity: 0.3;
			z-index: -1;
			top: -7px;
		}
		&:after{
			content: "";
			width: 10px;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			background-color: $primary;
			border-radius: 30px;
		}
		.blockquote-content{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 0;
		}
	}
	&.style-3{
		padding: 20px 30px 20px 80px;
		background-color: #f8f5ff;
		text-align: center;	
		&:before{
			color: $primary;
			left: 20px;
			top: 50%;
			font-size: 42px;
			transform: translateY(-50%);
		}
		.blockquote-content{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 0;
		}
	}
}