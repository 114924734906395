// Newsletter
.dlab-subscribe {
    &.style-1 {
        padding            : 75px 60px;
        background-color   : $white;
        z-index            : 99;
        position           : relative;
        border-radius      : 10px;
        box-shadow         : 0 0px 10px 4px rgba(31, 66, 135, 0.1);
        background-image   : url(.././public/images/pattern/pattern3.png);
        background-position: bottom;
        background-repeat  : no-repeat;
        background-size    : cover;
        margin             : 0 50px;

        form {
            margin-left: 50px;

            .input-group,
            .form-group {
                margin-bottom: 0;
            }

            button {
                width        : 45px;
                height       : 45px;
                border-radius: 50px;
                padding      : 0;
                position     : absolute;
                right        : 0;
                z-index      : 99;
            }
        }

        @include respond('tab-land') {
            margin : 0;
            padding: 40px;
        }

        @include respond('tab-port') {
            form {
                margin-left: 0;
                margin-top : 30px;
            }
        }

        @include respond('phone-land') {
            .title {
                font-size: 32px;
            }
        }

        @include respond('phone') {
            padding: 30px;

            .title {
                font-size: 32px;
            }

            form {
                margin-top: 15px;
            }
        }
    }

    &.style-2 {
        form {
            .form-control {
                height       : 56px;
                border-radius: 50px !important;
            }

            button {
                position: absolute;
                right   : 4px;
                top     : 4px;
                z-index : 9;
            }

            @include respond('phone') {
                button {
                    padding-left : 20px;
                    padding-right: 20px;
                }
            }
        }
    }

    &.style-3 {
        form {
            .form-control {
                height       : 56px;
                border-radius: 0 22px 0 22px !important;
            }

            button {
                position: absolute;
                right   : 4px;
                top     : 4px;
                z-index : 99;
            }
        }
    }
}