// alignleft
.alignleft{
	float: left;
	margin-right: 25px;
	margin-bottom: 25px;
}

// alignright
.alignright{
	float: right;
	margin-left: 25px;
	margin-bottom: 25px;
}

// blog-single
.blog-single{
	&.style-1{
		box-shadow: unset;
		border-radius: 0;
		overflow: unset;
		margin-bottom: 50px;
		.dlab-meta{
			padding-top: 15px;
		}
	}
	&.style-2,
	&.style-3{
		margin-bottom: 50px;
		.meta-bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
		}
		@include respond('phone'){
			.meta-bottom{
				display: block;
			}
		}
	}
}

// comments-area 
.comments-area,
.comment-respond,
.extra-blog{
	&.style-1{
		.comments-title,
		.comment-reply-title,
		.blog-title{
			position: relative;
			padding-left: 20px;
			margin-bottom: 40px;

			&:before{
				content: "";
				height: 100%;
				width: 5px;
				border-radius: 10px;
				background-color: #896eff;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}

//  .extra-blog 
.extra-blog{
	margin-bottom: 30px;
}

// comments-area
.comments-area{
	.comment-list{
		margin-bottom: 50px;
		&>.comment{
			.comment-body{
				position: relative;
				padding: 0 0 0 110px;
				margin-bottom: 30px;
				.comment-author{
					.avatar{
						position: absolute;
						left: 0;
						height: 95px;
						width: 95px;
					}
					.fn{
						font-family: $font-family-title;
						font-size: 18px;
						line-height: 18px;
						color: $secondary;
						font-weight: 700;
						font-style: normal;
						margin-bottom: 10px;
						display: block;
					}
				}
				p{
					font-size: 15px;
					margin-bottom: 10px;
				}
				.reply{
					.comment-reply-link{
						font-weight: 600;
						text-transform: uppercase;
						font-size: 14px;
						i{
							margin-right: 8px;
						}
					}
				}
			}
			&>.children{
				padding-left: 50px;
			}
			
		}
	}

	&.style-1{
		.comment-list{
			&>.comment{
				border-bottom: 1px solid $border-color;
				margin-bottom: 30px;
				.comment-body{
					.comment-author{
						.avatar{
							border-radius: 10px;
						}
					}
				}
				&:last-child{
					border-bottom: 0;
					margin-bottom: 0;
				}
			}
		}
	}
	&.style-2{
		.comment-list{
			&>.comment{
				border-bottom: 1px solid $border-color;
				margin-bottom: 30px;
				.comment-body{
					.comment-author{
						.avatar{
							border-radius: 50%;
							box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
							border: 5px solid $white;
						}
					}
				}
				&:last-child{
					border-bottom: 0;
					margin-bottom: 0;
				}
			}
		}
	}
	&.style-3{
		.comment-list{
			&>.comment{
				margin-bottom: 30px;
				.comment-body{
					.comment-author{
						.avatar{
							border-radius: 5px;
							box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
							border: 5px solid $white;
						}
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}	
}

.comment-respond{
	.comment-form{
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		margin-right: -10px;
		p{
			margin-bottom: 20px;
			padding-left: 10px;
			padding-right: 10px;
			label{
				display: none;
			}
			&.comment-form-author input,
			&.comment-form-email input,
			&.comment-form-url input,
			textarea{
				width: 100%;
				box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
				height: 45px;
				border-radius: 30px !important;
				padding: 10px 20px;
				position: relative;
				font-size: 15px;
				border: 1px solid;
				border-color: $white;
			}
			.textarea{
				height: 120px!important;
			}
		}
		.comment-form-comment{
			width: 100%;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	&.style-1,
	&.style-3{
		.comment-form{
			.comment-form-author,
			.comment-form-email,
			.comment-form-url{
				width: 33.33%;
			}
		}
	}
	&.style-2{
		.comment-form{
			.comment-form-author,
			.comment-form-email{
				width: 50%;
			}
			.comment-form-url{
				width: 100%;
			}
		}
	}
	&.style-3{
		.comment-form{
			.comment-form-author input,
			.comment-form-email input,
			.comment-form-url input,
			textarea{
				border-radius: 0 15px 0px 15px !important;
			}
		}
	}
	
	@include respond('phone'){
		.comment-form{
			.comment-form-author,
			.comment-form-email,
			.comment-form-url{
				width: 100%!important;
			}
		}	
	}
}

    