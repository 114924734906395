/*
    Name: Samar 
    Author: DexignZone
    Portfolio : https://themeforest.net/user/dexignzone/portfolio
   

    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    6. Dashboard
    7. Pages
    
    
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/font-awesome.min.css");
@import url("../icons/flaticon/flaticon.css");
@import url("../icons/themify/themify-icons.css");
:root {
  --gradient: linear-gradient(to right,#896eff 0,#5f3bff 51%,#896eff 100%);
  --gradient-sec: linear-gradient(to right,#130065 0,#2b0ead 51%,#130065 100%); }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #5543d1;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff586e;
  --orange: #ff8853;
  --yellow: #ffa808;
  --green: #029e76;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #896eff;
  --secondary: #1f2471;
  --success: #029e76;
  --info: #00aeff;
  --warning: #fea500;
  --danger: #ff5269;
  --light: #faf8f2;
  --dark: #262F5A;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #896eff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #4b22ff;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #AEAED5;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000; }

h1, .h1 {
  font-size: 3.375rem; }

h2, .h2 {
  font-size: 2.375rem; }

h3, .h3 {
  font-size: 2rem; }

h4, .h4 {
  font-size: 1.5625rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1.5rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1199px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-sm-first {
    order: -1; }

  .order-sm-last {
    order: 13; }

  .order-sm-0 {
    order: 0; }

  .order-sm-1 {
    order: 1; }

  .order-sm-2 {
    order: 2; }

  .order-sm-3 {
    order: 3; }

  .order-sm-4 {
    order: 4; }

  .order-sm-5 {
    order: 5; }

  .order-sm-6 {
    order: 6; }

  .order-sm-7 {
    order: 7; }

  .order-sm-8 {
    order: 8; }

  .order-sm-9 {
    order: 9; }

  .order-sm-10 {
    order: 10; }

  .order-sm-11 {
    order: 11; }

  .order-sm-12 {
    order: 12; }

  .offset-sm-0 {
    margin-left: 0; }

  .offset-sm-1 {
    margin-left: 8.3333333333%; }

  .offset-sm-2 {
    margin-left: 16.6666666667%; }

  .offset-sm-3 {
    margin-left: 25%; }

  .offset-sm-4 {
    margin-left: 33.3333333333%; }

  .offset-sm-5 {
    margin-left: 41.6666666667%; }

  .offset-sm-6 {
    margin-left: 50%; }

  .offset-sm-7 {
    margin-left: 58.3333333333%; }

  .offset-sm-8 {
    margin-left: 66.6666666667%; }

  .offset-sm-9 {
    margin-left: 75%; }

  .offset-sm-10 {
    margin-left: 83.3333333333%; }

  .offset-sm-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-md-first {
    order: -1; }

  .order-md-last {
    order: 13; }

  .order-md-0 {
    order: 0; }

  .order-md-1 {
    order: 1; }

  .order-md-2 {
    order: 2; }

  .order-md-3 {
    order: 3; }

  .order-md-4 {
    order: 4; }

  .order-md-5 {
    order: 5; }

  .order-md-6 {
    order: 6; }

  .order-md-7 {
    order: 7; }

  .order-md-8 {
    order: 8; }

  .order-md-9 {
    order: 9; }

  .order-md-10 {
    order: 10; }

  .order-md-11 {
    order: 11; }

  .order-md-12 {
    order: 12; }

  .offset-md-0 {
    margin-left: 0; }

  .offset-md-1 {
    margin-left: 8.3333333333%; }

  .offset-md-2 {
    margin-left: 16.6666666667%; }

  .offset-md-3 {
    margin-left: 25%; }

  .offset-md-4 {
    margin-left: 33.3333333333%; }

  .offset-md-5 {
    margin-left: 41.6666666667%; }

  .offset-md-6 {
    margin-left: 50%; }

  .offset-md-7 {
    margin-left: 58.3333333333%; }

  .offset-md-8 {
    margin-left: 66.6666666667%; }

  .offset-md-9 {
    margin-left: 75%; }

  .offset-md-10 {
    margin-left: 83.3333333333%; }

  .offset-md-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-lg-first {
    order: -1; }

  .order-lg-last {
    order: 13; }

  .order-lg-0 {
    order: 0; }

  .order-lg-1 {
    order: 1; }

  .order-lg-2 {
    order: 2; }

  .order-lg-3 {
    order: 3; }

  .order-lg-4 {
    order: 4; }

  .order-lg-5 {
    order: 5; }

  .order-lg-6 {
    order: 6; }

  .order-lg-7 {
    order: 7; }

  .order-lg-8 {
    order: 8; }

  .order-lg-9 {
    order: 9; }

  .order-lg-10 {
    order: 10; }

  .order-lg-11 {
    order: 11; }

  .order-lg-12 {
    order: 12; }

  .offset-lg-0 {
    margin-left: 0; }

  .offset-lg-1 {
    margin-left: 8.3333333333%; }

  .offset-lg-2 {
    margin-left: 16.6666666667%; }

  .offset-lg-3 {
    margin-left: 25%; }

  .offset-lg-4 {
    margin-left: 33.3333333333%; }

  .offset-lg-5 {
    margin-left: 41.6666666667%; }

  .offset-lg-6 {
    margin-left: 50%; }

  .offset-lg-7 {
    margin-left: 58.3333333333%; }

  .offset-lg-8 {
    margin-left: 66.6666666667%; }

  .offset-lg-9 {
    margin-left: 75%; }

  .offset-lg-10 {
    margin-left: 83.3333333333%; }

  .offset-lg-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xl-first {
    order: -1; }

  .order-xl-last {
    order: 13; }

  .order-xl-0 {
    order: 0; }

  .order-xl-1 {
    order: 1; }

  .order-xl-2 {
    order: 2; }

  .order-xl-3 {
    order: 3; }

  .order-xl-4 {
    order: 4; }

  .order-xl-5 {
    order: 5; }

  .order-xl-6 {
    order: 6; }

  .order-xl-7 {
    order: 7; }

  .order-xl-8 {
    order: 8; }

  .order-xl-9 {
    order: 9; }

  .order-xl-10 {
    order: 10; }

  .order-xl-11 {
    order: 11; }

  .order-xl-12 {
    order: 12; }

  .offset-xl-0 {
    margin-left: 0; }

  .offset-xl-1 {
    margin-left: 8.3333333333%; }

  .offset-xl-2 {
    margin-left: 16.6666666667%; }

  .offset-xl-3 {
    margin-left: 25%; }

  .offset-xl-4 {
    margin-left: 33.3333333333%; }

  .offset-xl-5 {
    margin-left: 41.6666666667%; }

  .offset-xl-6 {
    margin-left: 50%; }

  .offset-xl-7 {
    margin-left: 58.3333333333%; }

  .offset-xl-8 {
    margin-left: 66.6666666667%; }

  .offset-xl-9 {
    margin-left: 75%; }

  .offset-xl-10 {
    margin-left: 83.3333333333%; }

  .offset-xl-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1440) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xxl-first {
    order: -1; }

  .order-xxl-last {
    order: 13; }

  .order-xxl-0 {
    order: 0; }

  .order-xxl-1 {
    order: 1; }

  .order-xxl-2 {
    order: 2; }

  .order-xxl-3 {
    order: 3; }

  .order-xxl-4 {
    order: 4; }

  .order-xxl-5 {
    order: 5; }

  .order-xxl-6 {
    order: 6; }

  .order-xxl-7 {
    order: 7; }

  .order-xxl-8 {
    order: 8; }

  .order-xxl-9 {
    order: 9; }

  .order-xxl-10 {
    order: 10; }

  .order-xxl-11 {
    order: 11; }

  .order-xxl-12 {
    order: 12; }

  .offset-xxl-0 {
    margin-left: 0; }

  .offset-xxl-1 {
    margin-left: 8.3333333333%; }

  .offset-xxl-2 {
    margin-left: 16.6666666667%; }

  .offset-xxl-3 {
    margin-left: 25%; }

  .offset-xxl-4 {
    margin-left: 33.3333333333%; }

  .offset-xxl-5 {
    margin-left: 41.6666666667%; }

  .offset-xxl-6 {
    margin-left: 50%; }

  .offset-xxl-7 {
    margin-left: 58.3333333333%; }

  .offset-xxl-8 {
    margin-left: 66.6666666667%; }

  .offset-xxl-9 {
    margin-left: 75%; }

  .offset-xxl-10 {
    margin-left: 83.3333333333%; }

  .offset-xxl-11 {
    margin-left: 91.6666666667%; } }
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #888; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #E1E1F0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #E1E1F0; }
  .table tbody + tbody {
    border-top: 2px solid #E1E1F0; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #E1E1F0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E1E1F0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #888;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ded6ff; }
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c2b4ff; }

.table-hover .table-primary:hover {
  background-color: #c9bdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c9bdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c0c2d7; }
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8b8db5; }

.table-hover .table-secondary:hover {
  background-color: #b0b3cd; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b0b3cd; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e4d9; }
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7bcdb8; }

.table-hover .table-success:hover {
  background-color: #a6ddcf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a6ddcf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e8ff; }
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ad5ff; }

.table-hover .table-info:hover {
  background-color: #9fe0ff; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9fe0ff; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe6b8; }
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fed07a; }

.table-hover .table-warning:hover {
  background-color: #ffdd9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffdd9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcfd5; }
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa5b1; }

.table-hover .table-danger:hover {
  background-color: #ffb6bf; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb6bf; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefdfb; }
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfbf8; }

.table-hover .table-light:hover {
  background-color: #f9f3e7; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f9f3e7; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c5d1; }
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8e93a9; }

.table-hover .table-dark:hover {
  background-color: #b3b7c6; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b3b7c6; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #E1E1F0; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }
@media (max-width: 1439.98) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f1eeff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #888;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #AEAED5; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #029e76; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(2, 158, 118, 0.9);
  border-radius: 1.5rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #029e76;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #029e76;
    box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #029e76;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #029e76;
    box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #029e76; }
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #029e76; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #029e76; }
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #03d09c;
  background-color: #03d09c; }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25); }
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #029e76; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #029e76; }
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #029e76;
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff5269; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 82, 105, 0.9);
  border-radius: 1.5rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff5269;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5269' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5269' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff5269;
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ff5269;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5269' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff5269' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ff5269;
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff5269; }
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff5269; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ff5269; }
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff8595;
  background-color: #ff8595; }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff5269; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff5269; }
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff5269;
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #888;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #888;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #896eff;
  border-color: #896eff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #6a48ff;
    border-color: #5f3bff; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0rem rgba(155, 132, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #896eff;
    border-color: #896eff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5f3bff;
    border-color: #552eff; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(155, 132, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #1f2471;
  border-color: #1f2471; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #171a53;
    border-color: #141749; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0rem rgba(65, 69, 134, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #1f2471;
    border-color: #1f2471; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #141749;
    border-color: #11143f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(65, 69, 134, 0.5); }

.btn-success {
  color: #fff;
  background-color: #029e76;
  border-color: #029e76; }
  .btn-success:hover {
    color: #fff;
    background-color: #02785a;
    border-color: #016c50; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0rem rgba(40, 173, 139, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #029e76;
    border-color: #029e76; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #016c50;
    border-color: #015f47; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(40, 173, 139, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00aeff;
  border-color: #00aeff; }
  .btn-info:hover {
    color: #fff;
    background-color: #0094d9;
    border-color: #008bcc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0rem rgba(38, 186, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00aeff;
    border-color: #00aeff; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008bcc;
    border-color: #0083bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(38, 186, 255, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fea500;
  border-color: #fea500; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d88c00;
    border-color: #cb8400; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0rem rgba(221, 146, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cb8400;
    border-color: #be7c00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(221, 146, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ff5269;
  border-color: #ff5269; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff2c48;
    border-color: #ff1f3d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0rem rgba(255, 108, 128, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ff5269;
    border-color: #ff5269; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff1f3d;
    border-color: #ff1232; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(255, 108, 128, 0.5); }

.btn-light {
  color: #212529;
  background-color: #faf8f2;
  border-color: #faf8f2; }
  .btn-light:hover {
    color: #212529;
    background-color: #efe9d6;
    border-color: #ece4cd; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0rem rgba(217, 216, 212, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #faf8f2;
    border-color: #faf8f2; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #ece4cd;
    border-color: #e8dfc4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 216, 212, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #262F5A;
  border-color: #262F5A; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1b213f;
    border-color: #171c36; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0rem rgba(71, 78, 115, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #262F5A;
    border-color: #262F5A; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #171c36;
    border-color: #13182d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(71, 78, 115, 0.5); }

.btn-outline-primary {
  color: #896eff;
  border-color: #896eff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #896eff;
    border-color: #896eff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #896eff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #896eff;
    border-color: #896eff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.5); }

.btn-outline-secondary {
  color: #1f2471;
  border-color: #1f2471; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #1f2471;
    border-color: #1f2471; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0rem rgba(31, 36, 113, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #1f2471;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1f2471;
    border-color: #1f2471; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(31, 36, 113, 0.5); }

.btn-outline-success {
  color: #029e76;
  border-color: #029e76; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #029e76;
    border-color: #029e76; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #029e76;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #029e76;
    border-color: #029e76; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5); }

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00aeff;
    border-color: #00aeff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00aeff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00aeff;
    border-color: #00aeff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5); }

.btn-outline-warning {
  color: #fea500;
  border-color: #fea500; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fea500;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fea500;
    border-color: #fea500; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5); }

.btn-outline-danger {
  color: #ff5269;
  border-color: #ff5269; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff5269;
    border-color: #ff5269; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff5269;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff5269;
    border-color: #ff5269; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5); }

.btn-outline-light {
  color: #faf8f2;
  border-color: #faf8f2; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #faf8f2;
    border-color: #faf8f2; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #faf8f2;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #faf8f2;
    border-color: #faf8f2; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5); }

.btn-outline-dark {
  color: #262F5A;
  border-color: #262F5A; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #262F5A;
    border-color: #262F5A; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #262F5A;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #262F5A;
    border-color: #262F5A; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5); }

.btn-link {
  font-weight: 400;
  color: #896eff;
  text-decoration: none; }
  .btn-link:hover {
    color: #4b22ff;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #888;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }

  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }
@media (min-width: 1440) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }
.dropleft .dropdown-toggle::after {
  display: none; }
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #896eff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 1.5rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #896eff;
    background-color: #896eff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f1eeff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 1.5rem; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #896eff;
  background-color: #896eff; }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(137, 110, 255, 0.5); }
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(137, 110, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(137, 110, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(137, 110, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 1.5rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #f1eeff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #f1eeff;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 1.5rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 1.5rem 1.5rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(137, 110, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(137, 110, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(137, 110, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #896eff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #896eff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0rem;
    margin-left: 0rem;
    background-color: #896eff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 1.5rem; }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #896eff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }
.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1.5rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }
@media (max-width: 1439.98) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.5rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }
.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(1.5rem - 1px) calc(1.5rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(1.5rem - 1px) calc(1.5rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(1.5rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(1.5rem - 1px);
  border-top-right-radius: calc(1.5rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(1.5rem - 1px);
  border-bottom-left-radius: calc(1.5rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 1.5rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }
.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 1.5rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #896eff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #4b22ff;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem; }
.page-item:last-child .page-link {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem; }
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #896eff;
  border-color: #896eff; }
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #896eff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #5f3bff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(137, 110, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #1f2471; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #141749; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(31, 36, 113, 0.5); }

.badge-success {
  color: #fff;
  background-color: #029e76; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #016c50; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5); }

.badge-info {
  color: #fff;
  background-color: #00aeff; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #008bcc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #fea500; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cb8400; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ff5269; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ff1f3d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5); }

.badge-light {
  color: #212529;
  background-color: #faf8f2; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #ece4cd; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #262F5A; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #171c36; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 1.5rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #473985;
  background-color: #e7e2ff;
  border-color: #ded6ff; }
  .alert-primary hr {
    border-top-color: #c9bdff; }
  .alert-primary .alert-link {
    color: #342a61; }

.alert-secondary {
  color: #10133b;
  background-color: #d2d3e3;
  border-color: #c0c2d7; }
  .alert-secondary hr {
    border-top-color: #b0b3cd; }
  .alert-secondary .alert-link {
    color: #050613; }

.alert-success {
  color: #01523d;
  background-color: #ccece4;
  border-color: #b8e4d9; }
  .alert-success hr {
    border-top-color: #a6ddcf; }
  .alert-success .alert-link {
    color: #002018; }

.alert-info {
  color: #005a85;
  background-color: #ccefff;
  border-color: #b8e8ff; }
  .alert-info hr {
    border-top-color: #9fe0ff; }
  .alert-info .alert-link {
    color: #003752; }

.alert-warning {
  color: #845600;
  background-color: #ffedcc;
  border-color: #ffe6b8; }
  .alert-warning hr {
    border-top-color: #ffdd9f; }
  .alert-warning .alert-link {
    color: #513500; }

.alert-danger {
  color: #852b37;
  background-color: #ffdce1;
  border-color: #ffcfd5; }
  .alert-danger hr {
    border-top-color: #ffb6bf; }
  .alert-danger .alert-link {
    color: #5e1f27; }

.alert-light {
  color: #82817e;
  background-color: #fefefc;
  border-color: #fefdfb; }
  .alert-light hr {
    border-top-color: #f9f3e7; }
  .alert-light .alert-link {
    color: #686765; }

.alert-dark {
  color: #14182f;
  background-color: #d4d5de;
  border-color: #c2c5d1; }
  .alert-dark hr {
    border-top-color: #b3b7c6; }
  .alert-dark .alert-link {
    color: #05060b; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 1.5rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #896eff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #888;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #896eff;
    border-color: #896eff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 0; } }
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 0; } }
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 0; } }
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 0; } }
@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 0; } }
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #473985;
  background-color: #ded6ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #473985;
    background-color: #c9bdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #473985;
    border-color: #473985; }

.list-group-item-secondary {
  color: #10133b;
  background-color: #c0c2d7; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #10133b;
    background-color: #b0b3cd; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #10133b;
    border-color: #10133b; }

.list-group-item-success {
  color: #01523d;
  background-color: #b8e4d9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #01523d;
    background-color: #a6ddcf; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #01523d;
    border-color: #01523d; }

.list-group-item-info {
  color: #005a85;
  background-color: #b8e8ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #005a85;
    background-color: #9fe0ff; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #005a85;
    border-color: #005a85; }

.list-group-item-warning {
  color: #845600;
  background-color: #ffe6b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #845600;
    background-color: #ffdd9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #845600;
    border-color: #845600; }

.list-group-item-danger {
  color: #852b37;
  background-color: #ffcfd5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #852b37;
    background-color: #ffb6bf; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #852b37;
    border-color: #852b37; }

.list-group-item-light {
  color: #82817e;
  background-color: #fefdfb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #82817e;
    background-color: #f9f3e7; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #82817e;
    border-color: #82817e; }

.list-group-item-dark {
  color: #14182f;
  background-color: #c2c5d1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #14182f;
    background-color: #b3b7c6; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #14182f;
    border-color: #14182f; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E1E1F0;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #E1E1F0;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }

  .modal-sm {
    max-width: 300px; } }
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 1.5rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #888; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #896eff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #5f3bff !important; }

.bg-secondary {
  background-color: #1f2471 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #141749 !important; }

.bg-success {
  background-color: #029e76 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #016c50 !important; }

.bg-info {
  background-color: #00aeff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008bcc !important; }

.bg-warning {
  background-color: #fea500 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb8400 !important; }

.bg-danger {
  background-color: #ff5269 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1f3d !important; }

.bg-light {
  background-color: #faf8f2 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ece4cd !important; }

.bg-dark {
  background-color: #262F5A !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #171c36 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #E1E1F0 !important; }

.border-top {
  border-top: 1px solid #E1E1F0 !important; }

.border-right {
  border-right: 1px solid #E1E1F0 !important; }

.border-bottom {
  border-bottom: 1px solid #E1E1F0 !important; }

.border-left {
  border-left: 1px solid #E1E1F0 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #896eff !important; }

.border-secondary {
  border-color: #1f2471 !important; }

.border-success {
  border-color: #029e76 !important; }

.border-info {
  border-color: #00aeff !important; }

.border-warning {
  border-color: #fea500 !important; }

.border-danger {
  border-color: #ff5269 !important; }

.border-light {
  border-color: #faf8f2 !important; }

.border-dark {
  border-color: #262F5A !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 1.5rem !important; }

.rounded-top {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important; }

.rounded-right {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important; }

.rounded-left {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }

  .d-sm-inline {
    display: inline !important; }

  .d-sm-inline-block {
    display: inline-block !important; }

  .d-sm-block {
    display: block !important; }

  .d-sm-table {
    display: table !important; }

  .d-sm-table-row {
    display: table-row !important; }

  .d-sm-table-cell {
    display: table-cell !important; }

  .d-sm-flex {
    display: flex !important; }

  .d-sm-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }

  .d-md-inline {
    display: inline !important; }

  .d-md-inline-block {
    display: inline-block !important; }

  .d-md-block {
    display: block !important; }

  .d-md-table {
    display: table !important; }

  .d-md-table-row {
    display: table-row !important; }

  .d-md-table-cell {
    display: table-cell !important; }

  .d-md-flex {
    display: flex !important; }

  .d-md-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }

  .d-lg-inline {
    display: inline !important; }

  .d-lg-inline-block {
    display: inline-block !important; }

  .d-lg-block {
    display: block !important; }

  .d-lg-table {
    display: table !important; }

  .d-lg-table-row {
    display: table-row !important; }

  .d-lg-table-cell {
    display: table-cell !important; }

  .d-lg-flex {
    display: flex !important; }

  .d-lg-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }

  .d-xl-inline {
    display: inline !important; }

  .d-xl-inline-block {
    display: inline-block !important; }

  .d-xl-block {
    display: block !important; }

  .d-xl-table {
    display: table !important; }

  .d-xl-table-row {
    display: table-row !important; }

  .d-xl-table-cell {
    display: table-cell !important; }

  .d-xl-flex {
    display: flex !important; }

  .d-xl-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1440) {
  .d-xxl-none {
    display: none !important; }

  .d-xxl-inline {
    display: inline !important; }

  .d-xxl-inline-block {
    display: inline-block !important; }

  .d-xxl-block {
    display: block !important; }

  .d-xxl-table {
    display: table !important; }

  .d-xxl-table-row {
    display: table-row !important; }

  .d-xxl-table-cell {
    display: table-cell !important; }

  .d-xxl-flex {
    display: flex !important; }

  .d-xxl-inline-flex {
    display: inline-flex !important; } }
@media print {
  .d-print-none {
    display: none !important; }

  .d-print-inline {
    display: inline !important; }

  .d-print-inline-block {
    display: inline-block !important; }

  .d-print-block {
    display: block !important; }

  .d-print-table {
    display: table !important; }

  .d-print-table-row {
    display: table-row !important; }

  .d-print-table-cell {
    display: table-cell !important; }

  .d-print-flex {
    display: flex !important; }

  .d-print-inline-flex {
    display: inline-flex !important; } }
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }

  .flex-sm-column {
    flex-direction: column !important; }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-sm-wrap {
    flex-wrap: wrap !important; }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-sm-fill {
    flex: 1 1 auto !important; }

  .flex-sm-grow-0 {
    flex-grow: 0 !important; }

  .flex-sm-grow-1 {
    flex-grow: 1 !important; }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-sm-start {
    justify-content: flex-start !important; }

  .justify-content-sm-end {
    justify-content: flex-end !important; }

  .justify-content-sm-center {
    justify-content: center !important; }

  .justify-content-sm-between {
    justify-content: space-between !important; }

  .justify-content-sm-around {
    justify-content: space-around !important; }

  .align-items-sm-start {
    align-items: flex-start !important; }

  .align-items-sm-end {
    align-items: flex-end !important; }

  .align-items-sm-center {
    align-items: center !important; }

  .align-items-sm-baseline {
    align-items: baseline !important; }

  .align-items-sm-stretch {
    align-items: stretch !important; }

  .align-content-sm-start {
    align-content: flex-start !important; }

  .align-content-sm-end {
    align-content: flex-end !important; }

  .align-content-sm-center {
    align-content: center !important; }

  .align-content-sm-between {
    align-content: space-between !important; }

  .align-content-sm-around {
    align-content: space-around !important; }

  .align-content-sm-stretch {
    align-content: stretch !important; }

  .align-self-sm-auto {
    align-self: auto !important; }

  .align-self-sm-start {
    align-self: flex-start !important; }

  .align-self-sm-end {
    align-self: flex-end !important; }

  .align-self-sm-center {
    align-self: center !important; }

  .align-self-sm-baseline {
    align-self: baseline !important; }

  .align-self-sm-stretch {
    align-self: stretch !important; } }
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }

  .flex-md-column {
    flex-direction: column !important; }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-md-wrap {
    flex-wrap: wrap !important; }

  .flex-md-nowrap {
    flex-wrap: nowrap !important; }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-md-fill {
    flex: 1 1 auto !important; }

  .flex-md-grow-0 {
    flex-grow: 0 !important; }

  .flex-md-grow-1 {
    flex-grow: 1 !important; }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-md-start {
    justify-content: flex-start !important; }

  .justify-content-md-end {
    justify-content: flex-end !important; }

  .justify-content-md-center {
    justify-content: center !important; }

  .justify-content-md-between {
    justify-content: space-between !important; }

  .justify-content-md-around {
    justify-content: space-around !important; }

  .align-items-md-start {
    align-items: flex-start !important; }

  .align-items-md-end {
    align-items: flex-end !important; }

  .align-items-md-center {
    align-items: center !important; }

  .align-items-md-baseline {
    align-items: baseline !important; }

  .align-items-md-stretch {
    align-items: stretch !important; }

  .align-content-md-start {
    align-content: flex-start !important; }

  .align-content-md-end {
    align-content: flex-end !important; }

  .align-content-md-center {
    align-content: center !important; }

  .align-content-md-between {
    align-content: space-between !important; }

  .align-content-md-around {
    align-content: space-around !important; }

  .align-content-md-stretch {
    align-content: stretch !important; }

  .align-self-md-auto {
    align-self: auto !important; }

  .align-self-md-start {
    align-self: flex-start !important; }

  .align-self-md-end {
    align-self: flex-end !important; }

  .align-self-md-center {
    align-self: center !important; }

  .align-self-md-baseline {
    align-self: baseline !important; }

  .align-self-md-stretch {
    align-self: stretch !important; } }
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }

  .flex-lg-column {
    flex-direction: column !important; }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-lg-wrap {
    flex-wrap: wrap !important; }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-lg-fill {
    flex: 1 1 auto !important; }

  .flex-lg-grow-0 {
    flex-grow: 0 !important; }

  .flex-lg-grow-1 {
    flex-grow: 1 !important; }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-lg-start {
    justify-content: flex-start !important; }

  .justify-content-lg-end {
    justify-content: flex-end !important; }

  .justify-content-lg-center {
    justify-content: center !important; }

  .justify-content-lg-between {
    justify-content: space-between !important; }

  .justify-content-lg-around {
    justify-content: space-around !important; }

  .align-items-lg-start {
    align-items: flex-start !important; }

  .align-items-lg-end {
    align-items: flex-end !important; }

  .align-items-lg-center {
    align-items: center !important; }

  .align-items-lg-baseline {
    align-items: baseline !important; }

  .align-items-lg-stretch {
    align-items: stretch !important; }

  .align-content-lg-start {
    align-content: flex-start !important; }

  .align-content-lg-end {
    align-content: flex-end !important; }

  .align-content-lg-center {
    align-content: center !important; }

  .align-content-lg-between {
    align-content: space-between !important; }

  .align-content-lg-around {
    align-content: space-around !important; }

  .align-content-lg-stretch {
    align-content: stretch !important; }

  .align-self-lg-auto {
    align-self: auto !important; }

  .align-self-lg-start {
    align-self: flex-start !important; }

  .align-self-lg-end {
    align-self: flex-end !important; }

  .align-self-lg-center {
    align-self: center !important; }

  .align-self-lg-baseline {
    align-self: baseline !important; }

  .align-self-lg-stretch {
    align-self: stretch !important; } }
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }

  .flex-xl-column {
    flex-direction: column !important; }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xl-wrap {
    flex-wrap: wrap !important; }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-xl-fill {
    flex: 1 1 auto !important; }

  .flex-xl-grow-0 {
    flex-grow: 0 !important; }

  .flex-xl-grow-1 {
    flex-grow: 1 !important; }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-xl-start {
    justify-content: flex-start !important; }

  .justify-content-xl-end {
    justify-content: flex-end !important; }

  .justify-content-xl-center {
    justify-content: center !important; }

  .justify-content-xl-between {
    justify-content: space-between !important; }

  .justify-content-xl-around {
    justify-content: space-around !important; }

  .align-items-xl-start {
    align-items: flex-start !important; }

  .align-items-xl-end {
    align-items: flex-end !important; }

  .align-items-xl-center {
    align-items: center !important; }

  .align-items-xl-baseline {
    align-items: baseline !important; }

  .align-items-xl-stretch {
    align-items: stretch !important; }

  .align-content-xl-start {
    align-content: flex-start !important; }

  .align-content-xl-end {
    align-content: flex-end !important; }

  .align-content-xl-center {
    align-content: center !important; }

  .align-content-xl-between {
    align-content: space-between !important; }

  .align-content-xl-around {
    align-content: space-around !important; }

  .align-content-xl-stretch {
    align-content: stretch !important; }

  .align-self-xl-auto {
    align-self: auto !important; }

  .align-self-xl-start {
    align-self: flex-start !important; }

  .align-self-xl-end {
    align-self: flex-end !important; }

  .align-self-xl-center {
    align-self: center !important; }

  .align-self-xl-baseline {
    align-self: baseline !important; }

  .align-self-xl-stretch {
    align-self: stretch !important; } }
@media (min-width: 1440) {
  .flex-xxl-row {
    flex-direction: row !important; }

  .flex-xxl-column {
    flex-direction: column !important; }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xxl-wrap {
    flex-wrap: wrap !important; }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-xxl-fill {
    flex: 1 1 auto !important; }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-xxl-start {
    justify-content: flex-start !important; }

  .justify-content-xxl-end {
    justify-content: flex-end !important; }

  .justify-content-xxl-center {
    justify-content: center !important; }

  .justify-content-xxl-between {
    justify-content: space-between !important; }

  .justify-content-xxl-around {
    justify-content: space-around !important; }

  .align-items-xxl-start {
    align-items: flex-start !important; }

  .align-items-xxl-end {
    align-items: flex-end !important; }

  .align-items-xxl-center {
    align-items: center !important; }

  .align-items-xxl-baseline {
    align-items: baseline !important; }

  .align-items-xxl-stretch {
    align-items: stretch !important; }

  .align-content-xxl-start {
    align-content: flex-start !important; }

  .align-content-xxl-end {
    align-content: flex-end !important; }

  .align-content-xxl-center {
    align-content: center !important; }

  .align-content-xxl-between {
    align-content: space-between !important; }

  .align-content-xxl-around {
    align-content: space-around !important; }

  .align-content-xxl-stretch {
    align-content: stretch !important; }

  .align-self-xxl-auto {
    align-self: auto !important; }

  .align-self-xxl-start {
    align-self: flex-start !important; }

  .align-self-xxl-end {
    align-self: flex-end !important; }

  .align-self-xxl-center {
    align-self: center !important; }

  .align-self-xxl-baseline {
    align-self: baseline !important; }

  .align-self-xxl-stretch {
    align-self: stretch !important; } }
.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }

  .float-sm-right {
    float: right !important; }

  .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }

  .float-md-right {
    float: right !important; }

  .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }

  .float-lg-right {
    float: right !important; }

  .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }

  .float-xl-right {
    float: right !important; }

  .float-xl-none {
    float: none !important; } }
@media (min-width: 1440) {
  .float-xxl-left {
    float: left !important; }

  .float-xxl-right {
    float: right !important; }

  .float-xxl-none {
    float: none !important; } }
.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }

  .m-sm-1 {
    margin: 0.25rem !important; }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }

  .m-sm-2 {
    margin: 0.5rem !important; }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }

  .m-sm-3 {
    margin: 1rem !important; }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }

  .m-sm-4 {
    margin: 1.5rem !important; }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }

  .m-sm-5 {
    margin: 3rem !important; }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }

  .p-sm-0 {
    padding: 0 !important; }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }

  .p-sm-1 {
    padding: 0.25rem !important; }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }

  .p-sm-2 {
    padding: 0.5rem !important; }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }

  .p-sm-3 {
    padding: 1rem !important; }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }

  .p-sm-4 {
    padding: 1.5rem !important; }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }

  .p-sm-5 {
    padding: 3rem !important; }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }

  .m-sm-n1 {
    margin: -0.25rem !important; }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }

  .m-sm-n2 {
    margin: -0.5rem !important; }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }

  .m-sm-n3 {
    margin: -1rem !important; }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }

  .m-sm-n4 {
    margin: -1.5rem !important; }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }

  .m-sm-n5 {
    margin: -3rem !important; }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }

  .m-sm-auto {
    margin: auto !important; }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }

  .m-md-1 {
    margin: 0.25rem !important; }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }

  .m-md-2 {
    margin: 0.5rem !important; }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }

  .m-md-3 {
    margin: 1rem !important; }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }

  .m-md-4 {
    margin: 1.5rem !important; }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }

  .m-md-5 {
    margin: 3rem !important; }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }

  .p-md-0 {
    padding: 0 !important; }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }

  .p-md-1 {
    padding: 0.25rem !important; }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }

  .p-md-2 {
    padding: 0.5rem !important; }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }

  .p-md-3 {
    padding: 1rem !important; }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }

  .p-md-4 {
    padding: 1.5rem !important; }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }

  .p-md-5 {
    padding: 3rem !important; }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }

  .m-md-n1 {
    margin: -0.25rem !important; }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }

  .m-md-n2 {
    margin: -0.5rem !important; }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }

  .m-md-n3 {
    margin: -1rem !important; }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }

  .m-md-n4 {
    margin: -1.5rem !important; }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }

  .m-md-n5 {
    margin: -3rem !important; }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }

  .m-md-auto {
    margin: auto !important; }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }

  .m-lg-1 {
    margin: 0.25rem !important; }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }

  .m-lg-2 {
    margin: 0.5rem !important; }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }

  .m-lg-3 {
    margin: 1rem !important; }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }

  .m-lg-4 {
    margin: 1.5rem !important; }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }

  .m-lg-5 {
    margin: 3rem !important; }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }

  .p-lg-0 {
    padding: 0 !important; }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }

  .p-lg-1 {
    padding: 0.25rem !important; }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }

  .p-lg-2 {
    padding: 0.5rem !important; }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }

  .p-lg-3 {
    padding: 1rem !important; }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }

  .p-lg-4 {
    padding: 1.5rem !important; }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }

  .p-lg-5 {
    padding: 3rem !important; }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }

  .m-lg-n1 {
    margin: -0.25rem !important; }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }

  .m-lg-n2 {
    margin: -0.5rem !important; }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }

  .m-lg-n3 {
    margin: -1rem !important; }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }

  .m-lg-n4 {
    margin: -1.5rem !important; }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }

  .m-lg-n5 {
    margin: -3rem !important; }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }

  .m-lg-auto {
    margin: auto !important; }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }

  .m-xl-1 {
    margin: 0.25rem !important; }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }

  .m-xl-2 {
    margin: 0.5rem !important; }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }

  .m-xl-3 {
    margin: 1rem !important; }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }

  .m-xl-4 {
    margin: 1.5rem !important; }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }

  .m-xl-5 {
    margin: 3rem !important; }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }

  .p-xl-0 {
    padding: 0 !important; }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }

  .p-xl-1 {
    padding: 0.25rem !important; }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }

  .p-xl-2 {
    padding: 0.5rem !important; }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }

  .p-xl-3 {
    padding: 1rem !important; }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }

  .p-xl-4 {
    padding: 1.5rem !important; }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }

  .p-xl-5 {
    padding: 3rem !important; }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }

  .m-xl-n1 {
    margin: -0.25rem !important; }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }

  .m-xl-n2 {
    margin: -0.5rem !important; }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }

  .m-xl-n3 {
    margin: -1rem !important; }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }

  .m-xl-n4 {
    margin: -1.5rem !important; }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }

  .m-xl-n5 {
    margin: -3rem !important; }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }

  .m-xl-auto {
    margin: auto !important; }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
@media (min-width: 1440) {
  .m-xxl-0 {
    margin: 0 !important; }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }

  .m-xxl-1 {
    margin: 0.25rem !important; }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }

  .m-xxl-2 {
    margin: 0.5rem !important; }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }

  .m-xxl-3 {
    margin: 1rem !important; }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }

  .m-xxl-4 {
    margin: 1.5rem !important; }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }

  .m-xxl-5 {
    margin: 3rem !important; }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }

  .p-xxl-0 {
    padding: 0 !important; }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }

  .p-xxl-1 {
    padding: 0.25rem !important; }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }

  .p-xxl-2 {
    padding: 0.5rem !important; }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }

  .p-xxl-3 {
    padding: 1rem !important; }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }

  .p-xxl-4 {
    padding: 1.5rem !important; }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }

  .p-xxl-5 {
    padding: 3rem !important; }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }

  .m-xxl-n1 {
    margin: -0.25rem !important; }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }

  .m-xxl-n2 {
    margin: -0.5rem !important; }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }

  .m-xxl-n3 {
    margin: -1rem !important; }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }

  .m-xxl-n4 {
    margin: -1.5rem !important; }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }

  .m-xxl-n5 {
    margin: -3rem !important; }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }

  .m-xxl-auto {
    margin: auto !important; }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }

  .text-sm-right {
    text-align: right !important; }

  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }

  .text-md-right {
    text-align: right !important; }

  .text-md-center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }

  .text-lg-right {
    text-align: right !important; }

  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }

  .text-xl-right {
    text-align: right !important; }

  .text-xl-center {
    text-align: center !important; } }
@media (min-width: 1440) {
  .text-xxl-left {
    text-align: left !important; }

  .text-xxl-right {
    text-align: right !important; }

  .text-xxl-center {
    text-align: center !important; } }
.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #896eff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #4b22ff !important; }

.text-secondary {
  color: #1f2471 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0f1135 !important; }

.text-success {
  color: #029e76 !important; }

a.text-success:hover, a.text-success:focus {
  color: #01523e !important; }

.text-info {
  color: #00aeff !important; }

a.text-info:hover, a.text-info:focus {
  color: #007ab3 !important; }

.text-warning {
  color: #fea500 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b27300 !important; }

.text-danger {
  color: #ff5269 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff0627 !important; }

.text-light {
  color: #faf8f2 !important; }

a.text-light:hover, a.text-light:focus {
  color: #e5dabb !important; }

.text-dark {
  color: #262F5A !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0f1324 !important; }

.text-body {
  color: #888 !important; }

.text-muted {
  color: #AEAED5 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }

  a:not(.btn) {
    text-decoration: underline; }

  abbr[title]::after {
    content: " (" attr(title) ")"; }

  pre {
    white-space: pre-wrap !important; }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr,
  img {
    page-break-inside: avoid; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  @page {
    size: a3; }
  body {
    min-width: 992px !important; }

  .container {
    min-width: 992px !important; }

  .navbar {
    display: none; }

  .badge {
    border: 1px solid #000; }

  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }

  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #E1E1F0; }

  .table .thead-dark th {
    color: inherit;
    border-color: #E1E1F0; } }
* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body {
  line-height: 1.6;
  color: #505489;
  font-size: 15px; }
  body.fixed {
    overflow: hidden;
    position: fixed; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #1f2471; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  color: #1f2471; }

ul {
  padding: 0;
  margin: 0; }

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

li {
  list-style: none; }

a {
  text-decoration: none;
  outline: none;
  color: #896eff; }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none; }

b,
strong,
.strong {
  font-weight: 500;
  color: #000; }

.h-0 {
  height: 0; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px; }
  #main-wrapper.show {
    opacity: 1; }

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px; }

.bg-gray {
  background-color: #f8f5ff; }

::selection {
  color: #fff;
  background: #896eff; }

.font-10 {
  font-size: 10px; }

.font-12 {
  font-size: 12px; }

.font-13 {
  font-size: 13px; }

.font-14 {
  font-size: 14px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px; }

.font-20 {
  font-size: 20px; }

.font-22 {
  font-size: 22px; }

.font-24 {
  font-size: 24px; }

.font-26 {
  font-size: 26px; }

.font-30 {
  font-size: 30px; }

.font-35 {
  font-size: 35px; }

.font-40 {
  font-size: 40px; }

.font-45 {
  font-size: 45px; }

.font-50 {
  font-size: 50px; }

.font-55 {
  font-size: 55px; }

.font-60 {
  font-size: 60px; }

.font-70 {
  font-size: 70px; }

.font-75 {
  font-size: 75px; }

.font-80 {
  font-size: 80px; }

.font-90 {
  font-size: 90px; }

.font-100 {
  font-size: 100px; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.p-a0 {
  padding: 0; }

.p-a5 {
  padding: 5px; }

.p-a10 {
  padding: 10px; }

.p-a15 {
  padding: 15px; }

.p-a20 {
  padding: 20px; }

.p-a25 {
  padding: 25px; }

.p-a30 {
  padding: 30px; }

.p-a40 {
  padding: 40px; }

.p-a50 {
  padding: 50px; }

.p-a60 {
  padding: 60px; }

.p-a70 {
  padding: 70px; }

.p-a80 {
  padding: 80px; }

.p-a90 {
  padding: 90px; }

.p-a100 {
  padding: 100px; }

.p-t0 {
  padding-top: 0; }

.p-t5 {
  padding-top: 5px; }

.p-t10 {
  padding-top: 10px; }

.p-t15 {
  padding-top: 15px; }

.p-t20 {
  padding-top: 20px; }

.p-t30 {
  padding-top: 30px; }

.p-t40 {
  padding-top: 40px; }

.p-t50 {
  padding-top: 50px; }

.p-t60 {
  padding-top: 60px; }

.p-t70 {
  padding-top: 70px; }

.p-t80 {
  padding-top: 80px; }

.p-t90 {
  padding-top: 90px; }

.p-t100 {
  padding-top: 100px; }

.p-b0 {
  padding-bottom: 0; }

.p-b5 {
  padding-bottom: 5px; }

.p-b10 {
  padding-bottom: 10px; }

.p-b15 {
  padding-bottom: 15px; }

.p-b20 {
  padding-bottom: 20px; }

.p-b30 {
  padding-bottom: 30px; }

.p-b40 {
  padding-bottom: 40px; }

.p-b50 {
  padding-bottom: 50px; }

.p-b60 {
  padding-bottom: 60px; }

.p-b70 {
  padding-bottom: 70px; }

.p-b80 {
  padding-bottom: 80px; }

.p-b90 {
  padding-bottom: 90px; }

.p-b100 {
  padding-bottom: 100px; }

.p-l0 {
  padding-left: 0; }

.p-l5 {
  padding-left: 5px; }

.p-l10 {
  padding-left: 10px; }

.p-l15 {
  padding-left: 15px; }

.p-l20 {
  padding-left: 20px; }

.p-l30 {
  padding-left: 30px; }

.p-l40 {
  padding-left: 40px; }

.p-l50 {
  padding-left: 50px; }

.p-l60 {
  padding-left: 60px; }

.p-l70 {
  padding-left: 70px; }

.p-l80 {
  padding-left: 80px; }

.p-l90 {
  padding-left: 90px; }

.p-l100 {
  padding-left: 100px; }

.p-r0 {
  padding-right: 0; }

.p-r5 {
  padding-right: 5px; }

.p-r10 {
  padding-right: 10px; }

.p-r15 {
  padding-right: 15px; }

.p-r20 {
  padding-right: 20px; }

.p-r30 {
  padding-right: 30px; }

.p-r40 {
  padding-right: 40px; }

.p-r50 {
  padding-right: 50px; }

.p-r60 {
  padding-right: 60px; }

.p-r70 {
  padding-right: 70px; }

.p-r80 {
  padding-right: 80px; }

.p-r90 {
  padding-right: 90px; }

.p-r100 {
  padding-right: 100px; }

.p-lr0 {
  padding-left: 0;
  padding-right: 0; }

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px; }

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px; }

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px; }

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px; }

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px; }

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px; }

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px; }

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px; }

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px; }

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px; }

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px; }

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px; }

.p-tb0 {
  padding-bottom: 0;
  padding-top: 0; }

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px; }

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px; }

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px; }

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px; }

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px; }

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px; }

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px; }

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px; }

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px; }

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px; }

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px; }

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px; }

.m-auto {
  margin: auto; }

.m-a0 {
  margin: 0; }

.m-a5 {
  margin: 5px; }

.m-a10 {
  margin: 10px; }

.m-a15 {
  margin: 15px; }

.m-a20 {
  margin: 20px; }

.m-a30 {
  margin: 30px; }

.m-a40 {
  margin: 40px; }

.m-a50 {
  margin: 50px; }

.m-a60 {
  margin: 60px; }

.m-a70 {
  margin: 70px; }

.m-a80 {
  margin: 80px; }

.m-a90 {
  margin: 90px; }

.m-a100 {
  margin: 100px; }

.m-t0 {
  margin-top: 0; }

.m-t5 {
  margin-top: 5px; }

.m-t10 {
  margin-top: 10px; }

.m-t15 {
  margin-top: 15px; }

.m-t20 {
  margin-top: 20px; }

.m-t30 {
  margin-top: 30px; }

.m-t40 {
  margin-top: 40px; }

.m-t50 {
  margin-top: 50px; }

.m-t60 {
  margin-top: 60px; }

.m-t70 {
  margin-top: 70px; }

.m-t80 {
  margin-top: 80px; }

.m-t90 {
  margin-top: 90px; }

.m-t100 {
  margin-top: 100px; }

.m-b0 {
  margin-bottom: 0; }

.m-b5 {
  margin-bottom: 5px; }

.m-b10 {
  margin-bottom: 10px; }

.m-b15 {
  margin-bottom: 15px; }

.m-b20 {
  margin-bottom: 20px; }

.m-b30 {
  margin-bottom: 30px; }

.m-b40 {
  margin-bottom: 40px; }

.m-b50 {
  margin-bottom: 50px; }

.m-b60 {
  margin-bottom: 60px; }

.m-b70 {
  margin-bottom: 70px; }

.m-b80 {
  margin-bottom: 80px; }

.m-b90 {
  margin-bottom: 90px; }

.m-b100 {
  margin-bottom: 100px; }

.m-l0 {
  margin-left: 0; }

.m-l5 {
  margin-left: 5px; }

.m-l10 {
  margin-left: 10px; }

.m-l15 {
  margin-left: 15px; }

.m-l20 {
  margin-left: 20px; }

.m-l30 {
  margin-left: 30px; }

.m-l40 {
  margin-left: 40px; }

.m-l50 {
  margin-left: 50px; }

.m-l60 {
  margin-left: 60px; }

.m-l70 {
  margin-left: 70px; }

.m-l80 {
  margin-left: 80px; }

.m-l90 {
  margin-left: 90px; }

.m-l100 {
  margin-left: 100px; }

.m-r0 {
  margin-right: 0; }

.m-r5 {
  margin-right: 5px; }

.m-r10 {
  margin-right: 10px; }

.m-r15 {
  margin-right: 15px; }

.m-r20 {
  margin-right: 20px; }

.m-r30 {
  margin-right: 30px; }

.m-r40 {
  margin-right: 40px; }

.m-r50 {
  margin-right: 50px; }

.m-r60 {
  margin-right: 60px; }

.m-r70 {
  margin-right: 70px; }

.m-r80 {
  margin-right: 80px; }

.m-r90 {
  margin-right: 90px; }

.m-r100 {
  margin-right: 100px; }

.m-lr0 {
  margin-left: 0;
  margin-right: 0; }

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px; }

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px; }

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px; }

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px; }

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px; }

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px; }

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px; }

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px; }

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px; }

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px; }

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px; }

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px; }

.m-tb0 {
  margin-bottom: 0;
  margin-top: 0; }

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px; }

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px; }

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px; }

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px; }

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px; }

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px; }

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px; }

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px; }

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px; }

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px; }

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px; }

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px; }

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0; }

  .m-lg-t5 {
    margin-top: 5px; }

  .m-lg-t10 {
    margin-top: 10px; }

  .m-lg-t15 {
    margin-top: 15px; }

  .m-lg-t20 {
    margin-top: 20px; }

  .m-lg-t30 {
    margin-top: 30px; }

  .m-lg-t40 {
    margin-top: 40px; }

  .m-lg-t50 {
    margin-top: 50px; }

  .m-lg-t60 {
    margin-top: 60px; }

  .m-lg-t70 {
    margin-top: 70px; }

  .m-lg-t80 {
    margin-top: 80px; }

  .m-lg-t90 {
    margin-top: 90px; }

  .m-lg-t100 {
    margin-top: 100px; }

  .m-lg-b0 {
    margin-bottom: 0; }

  .m-lg-b5 {
    margin-bottom: 5px; }

  .m-lg-b10 {
    margin-bottom: 10px; }

  .m-lg-b15 {
    margin-bottom: 15px; }

  .m-lg-b20 {
    margin-bottom: 20px; }

  .m-lg-b30 {
    margin-bottom: 30px; }

  .m-lg-b40 {
    margin-bottom: 40px; }

  .m-lg-b50 {
    margin-bottom: 50px; }

  .m-lg-b60 {
    margin-bottom: 60px; }

  .m-lg-b70 {
    margin-bottom: 70px; }

  .m-lg-b80 {
    margin-bottom: 80px; }

  .m-lg-b90 {
    margin-bottom: 90px; }

  .m-lg-b100 {
    margin-bottom: 100px; } }
@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0; }

  .m-md-t5 {
    margin-top: 5px; }

  .m-md-t10 {
    margin-top: 10px; }

  .m-md-t15 {
    margin-top: 15px; }

  .m-md-t20 {
    margin-top: 20px; }

  .m-md-t30 {
    margin-top: 30px; }

  .m-md-t40 {
    margin-top: 40px; }

  .m-md-t50 {
    margin-top: 50px; }

  .m-md-t60 {
    margin-top: 60px; }

  .m-md-t70 {
    margin-top: 70px; }

  .m-md-t80 {
    margin-top: 80px; }

  .m-md-t90 {
    margin-top: 90px; }

  .m-md-t100 {
    margin-top: 100px; }

  .m-md-b0 {
    margin-bottom: 0; }

  .m-md-b5 {
    margin-bottom: 5px; }

  .m-md-b10 {
    margin-bottom: 10px; }

  .m-md-b15 {
    margin-bottom: 15px; }

  .m-md-b20 {
    margin-bottom: 20px; }

  .m-md-b30 {
    margin-bottom: 30px; }

  .m-md-b40 {
    margin-bottom: 40px; }

  .m-md-b50 {
    margin-bottom: 50px; }

  .m-md-b60 {
    margin-bottom: 60px; }

  .m-md-b70 {
    margin-bottom: 70px; }

  .m-md-b80 {
    margin-bottom: 80px; }

  .m-md-b90 {
    margin-bottom: 90px; }

  .m-md-b100 {
    margin-bottom: 100px; } }
@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0; }

  .m-sm-t5 {
    margin-top: 5px; }

  .m-sm-t10 {
    margin-top: 10px; }

  .m-sm-t15 {
    margin-top: 15px; }

  .m-sm-t20 {
    margin-top: 20px; }

  .m-sm-t30 {
    margin-top: 30px; }

  .m-sm-t40 {
    margin-top: 40px; }

  .m-sm-t50 {
    margin-top: 50px; }

  .m-sm-t60 {
    margin-top: 60px; }

  .m-sm-t70 {
    margin-top: 70px; }

  .m-sm-t80 {
    margin-top: 80px; }

  .m-sm-t90 {
    margin-top: 90px; }

  .m-sm-t100 {
    margin-top: 100px; }

  .m-sm-b0 {
    margin-bottom: 0; }

  .m-sm-b5 {
    margin-bottom: 5px; }

  .m-sm-b10 {
    margin-bottom: 10px; }

  .m-sm-b15 {
    margin-bottom: 15px; }

  .m-sm-b20 {
    margin-bottom: 20px; }

  .m-sm-b30 {
    margin-bottom: 30px; }

  .m-sm-b40 {
    margin-bottom: 40px; }

  .m-sm-b50 {
    margin-bottom: 50px; }

  .m-sm-b60 {
    margin-bottom: 60px; }

  .m-sm-b70 {
    margin-bottom: 70px; }

  .m-sm-b80 {
    margin-bottom: 80px; }

  .m-sm-b90 {
    margin-bottom: 90px; }

  .m-sm-b100 {
    margin-bottom: 100px; } }
@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0; }

  .m-xs-t5 {
    margin-top: 5px; }

  .m-xs-t10 {
    margin-top: 10px; }

  .m-xs-t15 {
    margin-top: 15px; }

  .m-xs-t20 {
    margin-top: 20px; }

  .m-xs-t30 {
    margin-top: 30px; }

  .m-xs-t40 {
    margin-top: 40px; }

  .m-xs-t50 {
    margin-top: 50px; }

  .m-xs-t60 {
    margin-top: 60px; }

  .m-xs-t70 {
    margin-top: 70px; }

  .m-xs-t80 {
    margin-top: 80px; }

  .m-xs-t90 {
    margin-top: 90px; }

  .m-xs-t100 {
    margin-top: 100px; }

  .m-xs-b0 {
    margin-bottom: 0; }

  .m-xs-b5 {
    margin-bottom: 5px; }

  .m-xs-b10 {
    margin-bottom: 10px; }

  .m-xs-b15 {
    margin-bottom: 15px; }

  .m-xs-b20 {
    margin-bottom: 20px; }

  .m-xs-b30 {
    margin-bottom: 30px; }

  .m-xs-b40 {
    margin-bottom: 40px; }

  .m-xs-b50 {
    margin-bottom: 50px; }

  .m-xs-b60 {
    margin-bottom: 60px; }

  .m-xs-b70 {
    margin-bottom: 70px; }

  .m-xs-b80 {
    margin-bottom: 80px; }

  .m-xs-b90 {
    margin-bottom: 90px; }

  .m-xs-b100 {
    margin-bottom: 100px; } }
.max-w50 {
  max-width: 50px; }

.max-w60 {
  max-width: 60px; }

.max-w80 {
  max-width: 80px; }

.max-w100 {
  max-width: 100px; }

.max-w200 {
  max-width: 200px; }

.max-w300 {
  max-width: 300px; }

.max-w400 {
  max-width: 400px; }

.max-w500 {
  max-width: 500px; }

.max-w600 {
  max-width: 600px; }

.max-w700 {
  max-width: 700px; }

.max-w800 {
  max-width: 800px; }

.max-w900 {
  max-width: 900px; }

.max-w1000 {
  max-width: 1000px; }

.bg-img-fix {
  background-attachment: unset;
  background-size: cover; }

.full-img {
  width: 100%; }

.sticky-top {
  top: 120px; }

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center; }
  .page-title p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #7272A8;
    text-transform: capitalize; }
    @media only screen and (max-width: 575px) {
      .page-title p {
        font-size: 16px; } }
  .page-title h3 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: capitalize; }
    @media only screen and (max-width: 575px) {
      .page-title h3 {
        font-size: 28px; } }

section {
  position: relative; }
  section:after {
    content: attr(data-content);
    position: absolute;
    top: -28px;
    font-size: 200px;
    font-weight: 700;
    color: transparent;
    line-height: 1;
    -webkit-text-stroke: 1px #000;
    z-index: -1;
    font-family: "Source Sans Pro", sans-serif;
    opacity: 0.1;
    left: 0; }
    @media only screen and (max-width: 1400px) {
      section:after {
        font-size: 140px;
        top: -20px; } }
    @media only screen and (max-width: 991px) {
      section:after {
        font-size: 80px;
        top: -10px; } }
    @media only screen and (max-width: 575px) {
      section:after {
        font-size: 50px;
        top: -5px; } }

.section-head {
  margin-bottom: 40px; }
  @media only screen and (max-width: 1199px) {
    .section-head {
      margin-bottom: 30px; } }
  .section-head h2.title {
    font-size: 38px;
    line-height: 1.2;
    font-weight: 700; }
    @media only screen and (max-width: 1400px) {
      .section-head h2.title {
        font-size: 40px; } }
    @media only screen and (max-width: 1199px) {
      .section-head h2.title {
        font-size: 35px; } }
    @media only screen and (max-width: 575px) {
      .section-head h2.title {
        font-size: 28px; } }
  .section-head h3.title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 700; }
    @media only screen and (max-width: 1400px) {
      .section-head h3.title {
        font-size: 35px; } }
    @media only screen and (max-width: 1199px) {
      .section-head h3.title {
        font-size: 30px; } }
    @media only screen and (max-width: 575px) {
      .section-head h3.title {
        font-size: 24px; } }
  .section-head h4.title {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 700; }
    @media only screen and (max-width: 1400px) {
      .section-head h4.title {
        font-size: 30px; } }
    @media only screen and (max-width: 1199px) {
      .section-head h4.title {
        font-size: 24px; } }
    @media only screen and (max-width: 575px) {
      .section-head h4.title {
        font-size: 20px; } }
  .section-head p {
    font-size: 15px;
    line-height: 25px; }
  .section-head.text-center p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .section-head.head-col {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .section-head.head-col {
        display: block; } }
    .section-head.head-col .title {
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .section-head.head-col .title {
          margin-bottom: 10px; } }
    .section-head.head-col p {
      padding-left: 15px;
      border-left: 4px solid #896eff;
      margin-left: 15px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .section-head.head-col p {
          padding-left: 0;
          border-left: 0;
          margin-left: 0; } }

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0; }
  .row.spno [class*="col"],
  .row.spno [class*="col"],
  .spno [class*="col"],
  .spno [class*="col"] {
    padding-left: 0;
    padding-right: 0; }

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row.sp4 [class*="col"],
  .row.sp4 [class*="col"],
  .sp4 [class*="col"],
  .sp4 [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px; }
  .row.sp15 [class*="col"],
  .row.sp15 [class*="col"],
  .sp15 [class*="col"],
  .sp15 [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.rounded-xl {
  border-radius: 30px !important; }

.rounded-lg {
  border-radius: 20px !important; }

.rounded-md {
  border-radius: 10px !important; }

.rounded-sm {
  border-radius: 5px !important; }

.text-maroon {
  color: #9e0168; }

.text-orange {
  color: #ff8853; }

.text-yellow {
  color: #ffa808; }

.text-skyblue {
  color: #00aeff; }

.text-red {
  color: #ff586e; }

.text-green {
  color: #029e76; }

.text-blue {
  color: #5543d1; }

.bg-maroon {
  background-color: #9e0168; }

.bg-orange {
  background-color: #ff8853; }

.bg-yellow {
  background-color: #ffa808; }

.bg-skyblue {
  background-color: #00aeff; }

.bg-red {
  background-color: #ff586e; }

.bg-green {
  background-color: #029e76; }

.bg-blue {
  background-color: #5543d1; }

.bgl-primary {
  background-color: rgba(137, 110, 255, 0.1); }

.bgl-secondary {
  background-color: rgba(31, 36, 113, 0.1); }

.bgl-success {
  background-color: rgba(2, 158, 118, 0.1); }

.bgl-warning {
  background-color: rgba(254, 165, 0, 0.1); }

.bgl-danger {
  background-color: rgba(255, 82, 105, 0.1); }

.bgl-info {
  background-color: rgba(0, 174, 255, 0.1); }

.bgl-dark {
  background-color: rgba(38, 47, 90, 0.1); }

.bgl-light {
  background-color: rgba(250, 248, 242, 0.1); }

.bgl-yellow {
  background-color: rgba(255, 168, 8, 0.1); }

.shadow-primary {
  box-shadow: 0 2px 15px rgba(137, 110, 255, 0.5); }

.shadow-secondary {
  box-shadow: 0 2px 15px rgba(31, 36, 113, 0.5); }

.shadow-green {
  box-shadow: 0 2px 15px rgba(2, 158, 118, 0.5); }

.shadow-skyblue {
  box-shadow: 0 2px 15px rgba(0, 174, 255, 0.5); }

.shadow-red {
  box-shadow: 0 2px 15px rgba(255, 88, 110, 0.5); }

.shadow-yellow {
  box-shadow: 0 2px 15px rgba(255, 168, 8, 0.5); }

.shadow-orange {
  box-shadow: 0 2px 15px rgba(255, 136, 83, 0.5); }

.shadow-maroon {
  box-shadow: 0 2px 15px rgba(158, 1, 104, 0.5); }

.shadow-blue {
  box-shadow: 0 2px 15px rgba(85, 67, 209, 0.5); }

.dlab-content-bx.style-3 span {
  font-size: 48px;
  margin-right: 5px; }

.zi-9999 {
  z-index: 9999; }

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }
#bg {
  background-attachment: fixed;
  background-size: cover; }

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
.boxed .tp-banner-container,
.boxed .rev_slider_wrapper {
  left: 0 !important;
  width: 100% !important; }
.boxed .tp-rightarrow {
  right: 0 !important; }
.boxed .tp-leftarrow {
  left: 0 !important; }
.boxed.footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px; }
.boxed.is-fixed .main-bar {
  left: 50%;
  width: 1200px;
  margin: 0 -600px; }
.boxed.is-fixed.header-curve .logo-header:after {
  right: auto;
  left: -15px;
  width: 90%; }

/* Frame */
.frame {
  padding: 30px; }
  .frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .frame .tp-banner-container,
  .frame .rev_slider_wrapper {
    left: 0 !important;
    width: 100% !important; }
  .frame .tp-rightarrow {
    right: 0 !important; }
  .frame .tp-leftarrow {
    left: 0 !important; }
  .frame .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0; }
  .frame .is-fixed.header-curve .logo-header:after {
    right: auto;
    left: -15px;
    width: 90%; }
  .frame button.scroltop {
    right: 50px;
    bottom: 50px; }

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0; }

  .frame button.scroltop {
    right: 15px;
    bottom: 15px; } }
.card {
  border: 0px solid #E1E1F0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff; }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E1F0;
    background: #fff;
    padding: 20px 30px; }
  .card-title {
    font-size: 24px;
    margin-bottom: 0px; }
  .card .card-body {
    padding: 20px 30px;
    background: #fff;
    border-radius: 15px; }

.dlab-blog .post-tags {
  display: block !important; }
  .dlab-blog .post-tags a {
    margin-left: 5px;
    color: #1f2471;
    font-weight: 500; }
.dlab-blog .dlab-meta {
  margin-bottom: 10px; }
  .dlab-blog .dlab-meta > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px; }
    .dlab-blog .dlab-meta > ul > li {
      font-weight: 500;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding-left: 12px;
      padding-right: 12px; }
      .dlab-blog .dlab-meta > ul > li i {
        font-size: 18px;
        color: #896eff; }
      .dlab-blog .dlab-meta > ul > li.post-comment {
        margin-left: auto;
        padding-right: 5px; }
        .dlab-blog .dlab-meta > ul > li.post-comment span {
          color: #505489;
          margin-left: 10px; }
      .dlab-blog .dlab-meta > ul > li.post-comment i, .dlab-blog .dlab-meta > ul > li.post-share i {
        font-weight: 600; }
      .dlab-blog .dlab-meta > ul > li.post-author a {
        color: #505489;
        margin-left: 3px; }
      .dlab-blog .dlab-meta > ul > li.post-share {
        position: relative; }
        .dlab-blog .dlab-meta > ul > li.post-share ul {
          display: inline-block;
          position: absolute;
          right: 40px;
          background-color: #896eff;
          box-shadow: -2px 9px 20px 0 rgba(0, 0, 0, 0.2);
          display: flex;
          border-radius: 4px;
          transform: scale(0.8);
          -moz-transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -ms-transform: scale(0.8);
          -o-transform: scale(0.8);
          transition: all 0.5s;
          -moz-transition: all 0.5s;
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          z-index: 2;
          opacity: 0;
          padding: 5px;
          visibility: hidden; }
          .dlab-blog .dlab-meta > ul > li.post-share ul:after {
            content: "";
            position: absolute;
            right: -7px;
            top: 50%;
            background: #896eff;
            width: 8px;
            height: 8px;
            display: block;
            transform: rotate(45deg) translateX(-50%);
            -moz-transform: rotate(45deg) translateX(-50%);
            -webkit-transform: rotate(45deg) translateX(-50%);
            -ms-transform: rotate(45deg) translateX(-50%);
            -o-transform: rotate(45deg) translateX(-50%); }
          .dlab-blog .dlab-meta > ul > li.post-share ul li a {
            width: 30px;
            height: 30px;
            display: block;
            line-height: 30px;
            text-align: center;
            color: #fff; }
        .dlab-blog .dlab-meta > ul > li.post-share:hover ul {
          transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          right: 40px;
          opacity: 1;
          visibility: visible; }
.dlab-blog .dlab-title {
  margin-bottom: 15px; }
.dlab-blog.style-1 {
  box-shadow: 0px 0 10px rgba(31, 66, 135, 0.3);
  border-radius: 10px;
  overflow: hidden; }
  .dlab-blog.style-1 .dlab-info {
    padding: 30px; }
    .dlab-blog.style-1 .dlab-info .dlab-meta {
      border-bottom: 1px solid #E1E1F0;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .dlab-blog.style-1 .dlab-info .meta-bottom {
      border-top: 1px solid #E1E1F0;
      border-bottom: 0;
      padding: 15px 0 0;
      margin: 20px 0 0; }
.dlab-blog.blog-half {
  display: flex;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0 10px rgba(31, 66, 135, 0.3);
  border-radius: 10px; }
  .dlab-blog.blog-half .dlab-media {
    width: 180px;
    min-width: 180px;
    margin-right: 20px;
    border-radius: 10px; }
    .dlab-blog.blog-half .dlab-media img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  @media only screen and (max-width: 575px) {
    .dlab-blog.blog-half {
      display: block;
      padding: 20px; }
      .dlab-blog.blog-half .dlab-media {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 20px; } }

.form-group {
  position: relative; }
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #888;
    margin-bottom: 10px;
    background: transparent;
    border-color: #E1E1F0; }

.form-control {
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #888;
  transition: all 0.3s ease-in-out;
  background: #fff;
  border: 1px solid transparent; }
  .form-control .select {
    padding: 12px 0; }
  .form-control span {
    margin-top: 0; }
  .form-control::-webkit-input-placeholder {
    color: #888; }
  .form-control:-ms-input-placeholder {
    color: #888; }
  .form-control::placeholder {
    color: #888; }
  .form-control:focus, .form-control:active, .form-control.active {
    border-color: #896eff; }

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important; }

.input-group-text {
  padding: 5px 15px;
  background: #fff;
  margin-bottom: 0px !important;
  color: #888;
  border-color: transparent; }

.input-group-append .input-group-text {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.input-group-prepend .input-group-text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

button:hover,
button:active,
button:focus {
  outline: 0; }

/*Styling Selectbox*/
.toggle {
  cursor: pointer;
  display: block; }

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s; }
  .toggle-switch:before, .toggle-switch:after {
    content: ""; }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s; }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%); }
  .toggle-checkbox:checked + .toggle-switch {
    background: #896eff; }
    .toggle-checkbox:checked + .toggle-switch:before {
      left: 26px; }

.toggle-checkbox {
  position: absolute;
  visibility: hidden; }

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400; }

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #E1E1F0;
  border-radius: 5px;
  color: #AEAED5; }
  .file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden; }
  .file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: #896eff;
    color: #fff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0; }
  .file-upload-wrapper:hover:before {
    background: #5f3bff; }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #888; }
.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0; }
  .ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #888;
    font-size: 0.875rem;
    text-decoration: none; }
    .ui-datepicker-calendar tbody td a:hover {
      background-color: #896eff;
      color: white; }
    .ui-datepicker-calendar tbody td a.ui-state-active {
      background-color: #896eff;
      color: white; }

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all; }
  .ui-datepicker-header a.ui-corner-all:hover {
    background-color: white;
    color: #fff; }
.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg); }
.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%; }
.ui-datepicker-header a > span {
  display: none; }

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem; }

.ui-datepicker-week-col {
  color: #888;
  font-weight: 400;
  font-size: 0.75rem; }

label.error {
  color: #ff5269;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400; }

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }
  .nice-select ul {
    width: 100% !important; }

.nice-select .list {
  background: #262F5A; }

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #262F5A;
  color: #896eff; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select {
  border: 1px solid transparent; }
  .bootstrap-select .dropdown-toggle {
    border: 0 !important;
    padding: 0;
    font-weight: normal;
    text-transform: unset;
    outline: none; }
    .bootstrap-select .dropdown-toggle:focus {
      outline: 0 !important;
      outline-offset: 0; }
    .bootstrap-select .dropdown-toggle:after {
      content: none; }
  .bootstrap-select.show {
    box-shadow: none !important;
    outline: 0px !important; }
    .bootstrap-select.show .dropdown-toggle {
      box-shadow: none !important;
      outline: 0px !important; }
    .bootstrap-select.show.btn-group .dropdown-menu.inner {
      display: block; }
  .bootstrap-select .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 10px 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px; }
    .bootstrap-select .dropdown-menu ul li {
      padding: 0; }
      .bootstrap-select .dropdown-menu ul li a {
        display: block;
        padding: 8px 20px;
        clear: both;
        font-weight: 400;
        color: #333;
        white-space: nowrap; }
        .bootstrap-select .dropdown-menu ul li a img {
          border-radius: 100%;
          -webkit-border-radius: 100%;
          margin-right: 5px; }
        .bootstrap-select .dropdown-menu ul li a:hover {
          background-color: #f2f2f4; }
      .bootstrap-select .dropdown-menu ul li.selected a {
        background-color: #896eff;
        color: #fff; }
  .bootstrap-select:focus, .bootstrap-select:active, .bootstrap-select.active {
    border-color: #896eff; }

.input-group {
  margin-bottom: 20px; }

.input-group-prepend {
  position: absolute;
  left: 10px;
  top: 22.50px;
  z-index: 99;
  transform: translateY(-50%);
  background-color: transparent;
  border-right: 1px solid #1f2471;
  margin-right: 10px; }
  .input-group-prepend .input-group-text {
    justify-content: center;
    padding: 0;
    width: 40px;
    height: 20px;
    background-color: transparent; }
    .input-group-prepend .input-group-text i {
      color: #1f2471;
      font-size: 18px; }

.input-group-prepend + .form-control,
.input-group-prepend + .form-control + .bootstrap-select,
.input-group-prepend + .form-control + .custom-file-label {
  padding: 10px 20px 10px 65px; }

.form-control {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 1.5rem !important;
  padding: 10px 20px;
  position: relative;
  font-size: 15px; }

textarea {
  height: 120px !important;
  resize: none; }

.bootstrap-select.btn-group {
  padding: 10px 20px;
  border-width: 1px; }
  .bootstrap-select.btn-group.show {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
    border-color: #896eff; }
  .bootstrap-select.btn-group .form-control .dropdown-toggle.btn-default {
    padding: 0; }
  .bootstrap-select.btn-group .dropdown-toggle .caret {
    font-size: 10px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); }
    .bootstrap-select.btn-group .dropdown-toggle .caret:before {
      content: "\f078"; }

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent; }
  .custom-file-label:after {
    content: none !important; }

.dlab-form.style-1 {
  padding: 50px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  background-color: #fff;
  border-radius: 10px; }
  .dlab-form.style-1 .form-control {
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  @media only screen and (max-width: 991px) {
    .dlab-form.style-1 {
      padding: 30px; } }

.btn {
  padding: 12px 35px;
  display: inline-block;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500; }

.btn.btn-lg, .btn-group-lg > .btn {
  padding: 10px 35px;
  font-size: 16px; }

.btn-light {
  background-color: #fff; }
  .btn-light:hover {
    background-color: #896eff;
    color: #fff !important;
    border-color: transparent; }
  .btn-light.text-primary:hover {
    color: #fff !important; }

.gradient {
  background-image: linear-gradient(to right, #896eff 0, #5f3bff 51%, #896eff 100%);
  background-size: 200%;
  border-color: transparent !important;
  transition: all 0.5s; }

.gradient-sec {
  background-image: var(--gradient-sec);
  background-size: 200%;
  border-color: transparent !important;
  transition: all 0.5s; }

.btn-link {
  padding: 0;
  border: 0; }
  .btn-link i {
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 15px 20px rgba(31, 66, 135, 0.1);
    font-size: 24px;
    background-color: #896eff;
    color: #fff; }
  .btn-link:hover, .btn-link:focus {
    text-decoration: none; }

.btn-corner {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem; }
  .btn-corner:hover, .btn-corner:active, .btn-corner:focus {
    border-top-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }

.btn-link i {
  display: inline-block;
  overflow: hidden; }

.btn-link:hover i:before {
  display: inline-block;
  -webkit-animation: toLeftFromRight 0.5s forwards;
  -moz-animation: toLeftFromRight 0.5s forwards;
  animation: toLeftFromRight 0.5s forwards; }

@-webkit-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  51% {
    opacity: 1; } }
@-moz-keyframes toLeftFromRight {
  49% {
    -moz-transform: translateX(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  51% {
    opacity: 1; } }
@keyframes toLeftFromRight {
  49% {
    transform: translateX(100%); }
  50% {
    opacity: 0;
    transform: translateX(-100%); }
  51% {
    opacity: 1; } }
.table-responsive-sm {
  min-width: 48rem; }

.table {
  margin-bottom: 0px;
  font-weight: 400; }
  .table tr td,
  .table tr th {
    padding: 15px; }

.table tr th {
  color: #000;
  font-weight: 500; }

.table-striped thead tr th {
  padding-bottom: 15px !important; }
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff; }
.table-striped tfoot {
  border-top: 2px solid #E1E1F0; }
  .table-striped tfoot tr th {
    padding-top: 10px; }

.dlab-separator {
  display: inline-block;
  height: 4px;
  width: 90px;
  margin-bottom: 10px;
  position: relative; }
  .dlab-separator.m-b0 {
    margin-bottom: 0; }
  .dlab-separator.style-liner {
    width: 20px; }
  .dlab-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px; }
  .dlab-separator[class*="style-"]:after, .dlab-separator[class*="style-"]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40px;
    width: 70px;
    height: 2px;
    background: #eee;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .dlab-separator[class*="style-"]:before {
    left: auto;
    right: 40px; }
  .dlab-separator.style-skew {
    width: 15px;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg); }
    .dlab-separator.style-skew[class*="style-"]:after, .dlab-separator.style-skew[class*="style-"]:before {
      width: 80px;
      height: 4px;
      left: 20px;
      -moz-transform: translateY(-50%) skewX(-10deg);
      -webkit-transform: translateY(-50%) skewX(-10deg);
      -o-transform: translateY(-50%) skewX(-10deg);
      -ms-transform: translateY(-50%) skewX(-10deg);
      transform: translateY(-50%) skewX(-10deg); }
    .dlab-separator.style-skew[class*="style-"]:before {
      right: 20px;
      left: auto; }
  .dlab-separator.bnr-title {
    height: 1px;
    width: 155px;
    opacity: 0.5; }
    .dlab-separator.bnr-title:before, .dlab-separator.bnr-title:after {
      position: absolute;
      content: "";
      background-color: inherit; }
    .dlab-separator.bnr-title:before {
      height: inherit;
      right: -80px;
      width: 25px; }
    .dlab-separator.bnr-title:after {
      height: inherit;
      right: -90px;
      top: 0;
      width: 6px; }
    .dlab-separator.bnr-title i {
      background-color: inherit;
      display: block;
      height: inherit;
      position: absolute;
      right: -50px;
      width: 45px; }

.dlab-separator-outer {
  overflow: hidden; }

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px; }
  .icon-bx-xl.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-xl i {
    font-size: 80px;
    vertical-align: middle; }
  .icon-bx-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px; }

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px; }
  .icon-bx-lg.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-lg i {
    font-size: 60px;
    vertical-align: middle; }
  .icon-bx-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 55px; }

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px; }
  .icon-bx-md.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-md i {
    font-size: 45px;
    vertical-align: middle; }
  .icon-bx-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 40px; }

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 80px;
  height: 80px;
  line-height: 80px; }
  .icon-bx-sm.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-sm i {
    font-size: 30px;
    vertical-align: middle; }
  .icon-bx-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 30px; }

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  line-height: 40px; }
  .icon-bx-xs.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-xs i {
    font-size: 20px;
    vertical-align: middle; }
  .icon-bx-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px; }

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px; }
  .icon-xl i {
    vertical-align: middle;
    font-size: 80px; }
  .icon-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px; }

.icon-lg {
  display: inline-block;
  text-align: center;
  width: 80px; }
  .icon-lg i {
    vertical-align: middle;
    font-size: 60px; }
  .icon-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 70px; }

.icon-md {
  display: inline-block;
  text-align: center;
  width: 60px; }
  .icon-md i {
    vertical-align: middle;
    font-size: 45px; }
  .icon-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 50px; }

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px; }
  .icon-sm i {
    vertical-align: middle;
    font-size: 30px; }
  .icon-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 30px; }

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px; }
  .icon-xs i {
    vertical-align: middle;
    font-size: 20px; }
  .icon-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px; }

.icon-bx-wraper {
  position: relative; }
  .icon-bx-wraper .dlab-tilte {
    margin-top: 0; }
  .icon-bx-wraper .after-titile-line {
    margin-bottom: 10px; }
  .icon-bx-wraper p:last-child {
    margin: 0; }
  .icon-bx-wraper.center {
    text-align: center; }
  .icon-bx-wraper.left .icon-bx-xl,
  .icon-bx-wraper.left .icon-bx-lg,
  .icon-bx-wraper.left .icon-bx-md,
  .icon-bx-wraper.left .icon-bx-sm,
  .icon-bx-wraper.left .icon-bx-xs {
    float: left;
    margin-right: 20px; }
  .icon-bx-wraper.left .icon-xl,
  .icon-bx-wraper.left .icon-lg,
  .icon-bx-wraper.left .icon-md,
  .icon-bx-wraper.left .icon-sm,
  .icon-bx-wraper.left .icon-xs {
    float: left;
    margin-right: 10px; }
  .icon-bx-wraper.right {
    text-align: right; }
    .icon-bx-wraper.right .icon-bx-xl,
    .icon-bx-wraper.right .icon-bx-lg,
    .icon-bx-wraper.right .icon-bx-md,
    .icon-bx-wraper.right .icon-bx-sm,
    .icon-bx-wraper.right .icon-bx-xs {
      float: right;
      margin-left: 20px; }
    .icon-bx-wraper.right .icon-xl,
    .icon-bx-wraper.right .icon-lg,
    .icon-bx-wraper.right .icon-md,
    .icon-bx-wraper.right .icon-sm,
    .icon-bx-wraper.right .icon-xs {
      float: right;
      margin-left: 10px; }
  .icon-bx-wraper.bx-style-1, .icon-bx-wraper.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ebedf2; }
  .icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .icon-bx-wraper.bx-style-2.center .icon-bx-xl {
    margin-left: -75px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-lg {
    margin-left: -60px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-md {
    margin-left: -50px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-sm {
    margin-left: -40px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-xs {
    margin-left: -20px; }
  .icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    right: 0;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%); }

.icon-content {
  overflow: hidden; }

[class*="icon-bx-"][class*="bg-"] a {
  color: #fff; }

[class*="icon-bx-"].bg-white a {
  color: inherit; }

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto; }

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle; }

.dlab-box,
.dlab-info,
.dlab-tilte,
.dlab-tilte-inner {
  position: relative; }

.dlab-tilte-inner {
  display: inline-block; }

.dlab-box[class*="border-"],
.dlab-info[class*="border-"] {
  border-color: #eee; }

.dlab-info.border-1,
.dlab-info.border-2,
.dlab-info.border-3,
.dlab-info.border-4,
.dlab-info.border-5 {
  border-top: none; }

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid; }

.border-1 {
  border-width: 1px; }

.border-2 {
  border-width: 2px; }

.border-3 {
  border-width: 3px; }

.border-4 {
  border-width: 4px; }

.border-5 {
  border-width: 5px; }

.left-border,
.right-border {
  position: relative; }
  .left-border:before,
  .right-border:before {
    content: "";
    position: absolute;
    top: 5px;
    width: 1px;
    height: 90%;
    background: #CCC; }

.right-border:before {
  right: 0; }

.dlab-media,
.dlab-post-media {
  position: relative;
  overflow: hidden; }
  .dlab-media img,
  .dlab-post-media img {
    width: 100%;
    height: auto; }

.dlab-box-bg {
  overflow: hidden;
  background-size: cover; }
  .dlab-box-bg .btn {
    overflow: unset; }
  .dlab-box-bg .glyph-icon {
    font-size: 50px; }
  .dlab-box-bg .icon-bx-wraper {
    background-color: #f8f8f8; }
  .dlab-box-bg .text-primary,
  .dlab-box-bg .icon-content .dlab-tilte,
  .dlab-box-bg .icon-content p,
  .dlab-box-bg .icon-box-btn .btn {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .dlab-box-bg.active .icon-bx-wraper {
    background-color: rgba(0, 0, 0, 0.7); }
  .dlab-box-bg.active .text-primary,
  .dlab-box-bg.active .icon-content .dlab-tilte,
  .dlab-box-bg.active .icon-content p,
  .dlab-box-bg.active .icon-box-btn .btn {
    color: #fff;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .dlab-box-bg .icon-box-btn .btn {
    background-color: #eeeeee;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 0;
    color: #1abc9c;
    font-weight: 600;
    margin-bottom: 0; }

.icon-bx-wraper.style-1 {
  padding: 30px;
  transition: all 0.5s;
  border-radius: 10px;
  background-color: #fff; }
  .icon-bx-wraper.style-1 .dlab-title {
    margin-bottom: 15px; }
  .icon-bx-wraper.style-1 > [class*="icon-bx-"] {
    width: 90px;
    height: 90px;
    line-height: 90px;
    margin: 24px 0 45px;
    position: relative;
    z-index: 1; }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"]:after {
      content: "";
      position: absolute;
      right: -20px;
      top: -20px;
      height: 133px;
      width: 82px;
      background-position: right;
      z-index: -1;
      transition: all 0.5s; }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-yellow:after {
      background-image: url(.././public/images/pattern/dots/yellow.png); }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-red:after {
      background-image: url(.././public/images/pattern/dots/red.png); }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-green:after {
      background-image: url(.././public/images/pattern/dots/green.png); }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-skyblue:after {
      background-image: url(.././public/images/pattern/dots/skyblue.png); }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-orange:after {
      background-image: url(.././public/images/pattern/dots/orange.png); }
    .icon-bx-wraper.style-1 > [class*="icon-bx-"].bg-maroon:after {
      background-image: url(.././public/images/pattern/dots/maroon.png); }
  .icon-bx-wraper.style-1:before, .icon-bx-wraper.style-1:after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    opacity: 0;
    transition: all 0.5s;
    transform: scale(0.5);
    z-index: 0; }
  .icon-bx-wraper.style-1:before {
    background-image: url(.././public/images/pattern/pattern5.png);
    height: 100%;
    top: -30px;
    left: -30px;
    width: 100%; }
  .icon-bx-wraper.style-1:after {
    background-image: url(.././public/images/pattern/pattern6.png);
    height: 290px;
    bottom: -40px;
    right: -30px;
    width: 250px; }
  .icon-bx-wraper.style-1:hover, .icon-bx-wraper.style-1.active {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
    .icon-bx-wraper.style-1:hover > [class*="icon-bx-"]:after, .icon-bx-wraper.style-1.active > [class*="icon-bx-"]:after {
      right: 30px; }
    .icon-bx-wraper.style-1:hover:before, .icon-bx-wraper.style-1:hover:after, .icon-bx-wraper.style-1.active:before, .icon-bx-wraper.style-1.active:after {
      opacity: 1;
      transform: scale(1); }
  @media only screen and (max-width: 1400px) {
    .icon-bx-wraper.style-1:before {
      top: -15px;
      left: -15px; }
    .icon-bx-wraper.style-1:after {
      bottom: -20px;
      right: -10px; } }
.icon-bx-wraper.style-2 {
  display: flex;
  align-items: center; }
  .icon-bx-wraper.style-2 > [class*="icon-bx-"] {
    position: relative;
    margin: 20px;
    min-width: 100px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 1; }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"] .icon-cell {
      font-size: 50px;
      color: #fff;
      z-index: 2;
      position: relative; }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"]:before {
      content: "";
      position: absolute;
      width: 165px;
      height: 165px;
      left: calc(50% - 82px);
      top: calc(50% - 82px);
      background-size: contain;
      z-index: -1;
      background-repeat: no-repeat; }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-red:before {
      background-image: url(.././public/images/pattern/icon-bx/red.png); }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-yellow:before {
      background-image: url(.././public/images/pattern/icon-bx/yellow.png); }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-green:before {
      background-image: url(.././public/images/pattern/icon-bx/green.png); }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-skyblue:before {
      background-image: url(.././public/images/pattern/icon-bx/skyblue.png); }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-maroon:before {
      background-image: url(.././public/images/pattern/icon-bx/maroon.png); }
    .icon-bx-wraper.style-2 > [class*="icon-bx-"].text-orange:before {
      background-image: url(.././public/images/pattern/icon-bx/orange.png); }
  .icon-bx-wraper.style-2 .icon-content {
    padding-left: 20px; }
  .icon-bx-wraper.style-2:hover > [class*="icon-bx-"] {
    background-color: transparent !important; }
    .icon-bx-wraper.style-2:hover > [class*="icon-bx-"]:before {
      -webkit-animation: rotate360 5s infinite;
      animation: rotate360 5s infinite; }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-2 > [class*="icon-bx-"] {
      min-width: 80px;
      width: 80px;
      height: 80px;
      line-height: 80px; }
      .icon-bx-wraper.style-2 > [class*="icon-bx-"]:before {
        width: 126px;
        height: 126px;
        left: calc(50% - 63px);
        top: calc(50% - 63px); } }
.icon-bx-wraper.style-3 {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 9; }
  .icon-bx-wraper.style-3 > [class*="icon-bx-"] {
    position: relative;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: 15px 15px 30px;
    transition: all 0.5s; }
    .icon-bx-wraper.style-3 > [class*="icon-bx-"] .icon-cell i {
      font-size: 40px; }
    .icon-bx-wraper.style-3 > [class*="icon-bx-"]:before {
      content: "";
      position: absolute;
      height: 100px;
      width: 100px;
      left: -13px;
      top: -15px;
      background-image: url(.././public/images/pattern/pattern1.png);
      background-repeat: no-repeat;
      background-size: cover; }
  .icon-bx-wraper.style-3 .wraper-effect {
    border-radius: 50%;
    position: absolute;
    transition: all 0.5s;
    height: 350px;
    width: 350px;
    background-color: rgba(255, 255, 255, 0.2);
    right: -100%;
    bottom: -100%;
    z-index: -1; }
    .icon-bx-wraper.style-3 .wraper-effect:before, .icon-bx-wraper.style-3 .wraper-effect:after {
      content: "";
      border-radius: 50%;
      position: absolute;
      transition: all 0.5s;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }
    .icon-bx-wraper.style-3 .wraper-effect:before {
      background-color: rgba(255, 255, 255, 0.2);
      height: 250px;
      width: 250px; }
    .icon-bx-wraper.style-3 .wraper-effect:after {
      background-color: #896eff;
      height: 150px;
      width: 150px; }
  .icon-bx-wraper.style-3:hover, .icon-bx-wraper.style-3.active {
    background-image: var(--gradient);
    background-size: 200%; }
    .icon-bx-wraper.style-3:hover .dlab-title,
    .icon-bx-wraper.style-3:hover p,
    .icon-bx-wraper.style-3:hover .icon-cell, .icon-bx-wraper.style-3.active .dlab-title,
    .icon-bx-wraper.style-3.active p,
    .icon-bx-wraper.style-3.active .icon-cell {
      color: #fff; }
    .icon-bx-wraper.style-3:hover > [class*="icon-bx-"], .icon-bx-wraper.style-3.active > [class*="icon-bx-"] {
      background-color: rgba(255, 255, 255, 0.2); }
    .icon-bx-wraper.style-3:hover .wraper-effect, .icon-bx-wraper.style-3.active .wraper-effect {
      right: -150px;
      bottom: -150px; }
    .icon-bx-wraper.style-3:hover > [class*="icon-bx-"]:before, .icon-bx-wraper.style-3.active > [class*="icon-bx-"]:before {
      background-image: url(.././public/images/pattern/pattern1-light.png); }
  .icon-bx-wraper.style-3.left > [class*="icon-bx-"]:after {
    content: "";
    width: 70px;
    height: 50vh;
    background-color: rgba(137, 110, 255, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
    top: -4px;
    border-radius: 50px; }
  .icon-bx-wraper.style-3.left .icon-content {
    padding-left: 25px; }
  .icon-bx-wraper.style-3.left:hover > [class*="icon-bx-"], .icon-bx-wraper.style-3.left.active > [class*="icon-bx-"] {
    background-color: transparent; }
    .icon-bx-wraper.style-3.left:hover > [class*="icon-bx-"]:after, .icon-bx-wraper.style-3.left.active > [class*="icon-bx-"]:after {
      background-color: rgba(255, 255, 255, 0.2); }
  @media only screen and (max-width: 767px) {
    .icon-bx-wraper.style-3.left {
      margin-right: 0;
      margin-left: 0; } }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-3.left {
      padding: 30px 20px 30px 15px; }
      .icon-bx-wraper.style-3.left > [class*="icon-bx-"]:after {
        height: 70px;
        top: 0; }
      .icon-bx-wraper.style-3.left .icon-content {
        padding-left: 15px; } }
.icon-bx-wraper.style-4 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  padding: 30px;
  transition: all 0.5s; }
  .icon-bx-wraper.style-4 .icon-bx-sm {
    width: 70px;
    height: 70px;
    line-height: 70px; }
    .icon-bx-wraper.style-4 .icon-bx-sm i {
      font-size: 35px; }
  .icon-bx-wraper.style-4 .title {
    font-size: 20px;
    color: #262F5A;
    font-weight: 500; }
  .icon-bx-wraper.style-4:hover {
    transform: translateY(-15px); }
  @media only screen and (max-width: 767px) {
    .icon-bx-wraper.style-4 {
      padding: 30px 20px; } }
.icon-bx-wraper.style-5 {
  padding: 30px;
  transition: all 0.5s; }
  .icon-bx-wraper.style-5 .btn {
    height: 45px;
    width: 45px;
    text-align: center;
    border-radius: 50px;
    padding: 0;
    line-height: 43px;
    font-size: 20px;
    transition: all 0.5s; }
  .icon-bx-wraper.style-5 .icon-bx {
    background-image: url(.././public/images/pattern/pattern10.png);
    background-color: rgba(137, 110, 255, 0.1);
    background-position: center;
    background-repeat: no-repeat;
    width: 165px;
    height: 165px;
    margin: auto;
    line-height: 160px;
    transition: all 0.5s; }
    .icon-bx-wraper.style-5 .icon-bx .icon-cell {
      font-size: 60px;
      color: #896eff;
      transition: all 0.5s; }
  .icon-bx-wraper.style-5:hover, .icon-bx-wraper.style-5.active {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
    .icon-bx-wraper.style-5:hover .btn, .icon-bx-wraper.style-5.active .btn {
      background-image: var(--gradient-sec);
      background-size: 200%;
      border-color: transparent;
      color: #fff; }
    .icon-bx-wraper.style-5:hover .icon-bx, .icon-bx-wraper.style-5.active .icon-bx {
      background-image: url(.././public/images/pattern/pattern10.png), var(--gradient-sec);
      background-size: cover, 200%; }
      .icon-bx-wraper.style-5:hover .icon-bx .icon-cell, .icon-bx-wraper.style-5.active .icon-bx .icon-cell {
        color: #fff; }
.icon-bx-wraper.style-6 {
  padding: 10px 40px; }
  .icon-bx-wraper.style-6 > [class*="icon-bx-"] {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
    margin-bottom: 25px;
    transition: all 0.5s; }
  .icon-bx-wraper.style-6 .icon-bx-sm .icon-cell i {
    font-size: 40px; }
  .icon-bx-wraper.style-6 .icon-bx-lg .icon-cell i {
    font-size: 50px; }
  .icon-bx-wraper.style-6:hover > [class*="icon-bx-"] {
    background-image: var(--gradient);
    background-size: 200%; }
    .icon-bx-wraper.style-6:hover > [class*="icon-bx-"] .icon-cell {
      color: #fff !important; }
  @media only screen and (max-width: 1199px) {
    .icon-bx-wraper.style-6 {
      padding: 0; } }

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }
.site-filters {
  margin-bottom: 30px; }
  .site-filters ul {
    margin: 0;
    list-style: none; }
  .site-filters li {
    display: inline-block;
    padding: 0;
    margin-bottom: 3px; }
    .site-filters li.btn {
      box-shadow: none;
      -webkit-box-shadow: none;
      outline: none !important; }
    .site-filters li input {
      display: none; }
    .site-filters li a {
      margin: 0 5px 5px 0; }
    .site-filters li.active [class*="btn"] {
      color: #fff;
      background-color: #EFBB20; }
  .site-filters.center {
    text-align: center; }
    .site-filters.center ul {
      display: inline-block;
      margin: auto; }
    .site-filters.center [class*="btn"] {
      display: inline-block;
      margin: 0 5px 10px;
      text-transform: uppercase; }
      @media only screen and (max-width: 575px) {
        .site-filters.center [class*="btn"] {
          margin: 0 1px 5px;
          padding: 5px 10px;
          font-size: 12px; } }

.site-filters.style-1 {
  background-color: rgba(137, 110, 255, 0.1);
  border-radius: 50px;
  padding: 10px; }
  .site-filters.style-1 .filters {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap; }
    .site-filters.style-1 .filters li {
      margin: 0 5px;
      padding: 0; }
      .site-filters.style-1 .filters li a {
        font-size: 18px;
        color: #1f2471;
        padding: 10px 30px;
        border-radius: 30px;
        display: block;
        margin: 0;
        text-transform: capitalize;
        cursor: pointer; }
      .site-filters.style-1 .filters li.active a {
        background-color: #896eff;
        box-shadow: 0 2px 15px rgba(137, 110, 255, 0.5);
        color: #fff; }
  @media only screen and (max-width: 1199px) {
    .site-filters.style-1 .filters li a {
      font-size: 16px;
      padding: 10px 25px; } }
  @media only screen and (max-width: 991px) {
    .site-filters.style-1 .filters {
      display: block; }
      .site-filters.style-1 .filters li {
        margin: 0; }
        .site-filters.style-1 .filters li a {
          font-size: 14px;
          padding: 8px 18px; } }
  @media only screen and (max-width: 575px) {
    .site-filters.style-1 {
      background-color: transparent;
      padding: 0; }
      .site-filters.style-1 .filters li {
        margin: 0; }
        .site-filters.style-1 .filters li a {
          padding: 8px 20px; } }
.site-filters.style-2 .filters li {
  margin: 0 20px 10px; }
  .site-filters.style-2 .filters li a {
    border-bottom: 3px solid;
    border-color: transparent;
    font-size: 18px;
    color: #1f2471;
    text-transform: capitalize;
    margin: 0;
    padding: 0 0 5px; }
  .site-filters.style-2 .filters li.active a {
    border-color: #896eff;
    color: #896eff; }
@media only screen and (max-width: 575px) {
  .site-filters.style-2 .filters li {
    margin: 0 5px 5px; }
    .site-filters.style-2 .filters li a {
      font-size: 14px;
      border-bottom: 2px; } }
.site-filters.style-3 .filters li {
  margin: 0 5px 10px;
  padding: 0; }
  .site-filters.style-3 .filters li a {
    font-size: 18px;
    color: #1f2471;
    text-transform: capitalize;
    background-color: rgba(137, 110, 255, 0.1);
    padding: 10px 30px;
    border-radius: 30px;
    display: block;
    margin: 0 0 10px; }
  .site-filters.style-3 .filters li.active a {
    background-image: var(--gradient);
    background-size: 200%;
    color: #fff;
    box-shadow: 0 2px 15px rgba(137, 110, 255, 0.5); }
@media only screen and (max-width: 767px) {
  .site-filters.style-3 .filters li {
    margin: 0; }
    .site-filters.style-3 .filters li a {
      font-size: 14px;
      padding: 8px 24px; } }

.dlab-img-overlay1,
.dlab-img-overlay2,
.dlab-img-overlay3,
.dlab-img-overlay4,
.dlab-img-overlay5,
.dlab-img-overlay6,
.dlab-img-overlay7,
.dlab-img-overlay8,
.dlab-img-overlay9,
.dlab-img-overlay11,
.dlab-img-overlay12 {
  position: relative; }
  .dlab-img-overlay1:before, .dlab-img-overlay1:after,
  .dlab-img-overlay2:before,
  .dlab-img-overlay2:after,
  .dlab-img-overlay3:before,
  .dlab-img-overlay3:after,
  .dlab-img-overlay4:before,
  .dlab-img-overlay4:after,
  .dlab-img-overlay5:before,
  .dlab-img-overlay5:after,
  .dlab-img-overlay6:before,
  .dlab-img-overlay6:after,
  .dlab-img-overlay7:before,
  .dlab-img-overlay7:after,
  .dlab-img-overlay8:before,
  .dlab-img-overlay8:after,
  .dlab-img-overlay9:before,
  .dlab-img-overlay9:after,
  .dlab-img-overlay11:before,
  .dlab-img-overlay11:after,
  .dlab-img-overlay12:before,
  .dlab-img-overlay12:after {
    content: "";
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0.4;
    z-index: 1;
    -webkit-transition: all 0.8s linear;
    -moz-transition: all 0.8s linear;
    -ms-transition: all 0.8s linear;
    -o-transition: all 0.8s linear;
    transition: all 0.8s linear; }
  .dlab-img-overlay1.overlay-primary,
  .dlab-img-overlay2.overlay-primary,
  .dlab-img-overlay3.overlay-primary,
  .dlab-img-overlay4.overlay-primary,
  .dlab-img-overlay5.overlay-primary,
  .dlab-img-overlay6.overlay-primary,
  .dlab-img-overlay7.overlay-primary,
  .dlab-img-overlay8.overlay-primary,
  .dlab-img-overlay9.overlay-primary,
  .dlab-img-overlay11.overlay-primary,
  .dlab-img-overlay12.overlay-primary {
    background: #896eff; }

.dlab-box:hover .dlab-img-overlay1:before {
  opacity: 0.5; }
.dlab-box:hover .dlab-img-overlay2:before {
  opacity: 0.9; }
.dlab-box:hover .dlab-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
.dlab-box:hover .dlab-img-overlay4:before {
  width: 100%;
  left: 0; }
.dlab-box:hover .dlab-img-overlay5:before {
  height: 100%;
  top: 0; }
.dlab-box:hover .dlab-img-overlay6:before {
  height: 100%; }
.dlab-box:hover .dlab-img-overlay7:before,
.dlab-box:hover .dlab-img-overlay7:after {
  width: 50%; }
.dlab-box:hover .dlab-img-overlay8:before,
.dlab-box:hover .dlab-img-overlay8:after {
  width: 100%;
  opacity: 0.3; }
.dlab-box:hover .dlab-img-overlay9:before,
.dlab-box:hover .dlab-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3; }

.dlab-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0; }
.dlab-img-overlay1:hover:before {
  opacity: 0.5; }

.dlab-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); }
  .dlab-img-overlay2:before:hover:before {
    opacity: 0.9; }

.dlab-img-overlay3:before {
  left: 50%;
  top: 50%; }
.dlab-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.dlab-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%; }
.dlab-img-overlay4:hover:before {
  width: 100%;
  left: 0; }

.dlab-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%; }
.dlab-img-overlay5:hover:before {
  height: 100%;
  top: 0; }

.dlab-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%; }
.dlab-img-overlay6:hover:before {
  height: 100%; }

.dlab-img-overlay7:before, .dlab-img-overlay7:after {
  height: 100%; }
.dlab-img-overlay7:after {
  left: auto;
  right: 0; }
.dlab-img-overlay7:hover:before, .dlab-img-overlay7:hover:after {
  width: 50%; }

.dlab-img-overlay8:before, .dlab-img-overlay8:after {
  height: 100%; }
.dlab-img-overlay8:after {
  left: auto;
  right: 0; }
.dlab-img-overlay8:hover:before, .dlab-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3; }

.dlab-img-overlay9:before:before, .dlab-img-overlay9:before:after,
.dlab-img-overlay9:after:before,
.dlab-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0; }
.dlab-img-overlay9:before:hover:before, .dlab-img-overlay9:before:hover:after,
.dlab-img-overlay9:after:hover:before,
.dlab-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3; }

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative; }
  .overlay-black-light:before,
  .overlay-black-middle:before,
  .overlay-black-dark:before,
  .overlay-gradient-light:before,
  .overlay-gradient-middle:before,
  .overlay-gradient-dark:before,
  .overlay-white-light:before,
  .overlay-white-middle:before,
  .overlay-white-dark:before,
  .overlay-primary-light:before,
  .overlay-primary-middle:before,
  .overlay-primary-dark:before,
  .overlay-primary:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .overlay-black-light .container,
  .overlay-black-light .container-fluid,
  .overlay-black-middle .container,
  .overlay-black-middle .container-fluid,
  .overlay-black-dark .container,
  .overlay-black-dark .container-fluid,
  .overlay-gradient-light .container,
  .overlay-gradient-light .container-fluid,
  .overlay-gradient-middle .container,
  .overlay-gradient-middle .container-fluid,
  .overlay-gradient-dark .container,
  .overlay-gradient-dark .container-fluid,
  .overlay-white-light .container,
  .overlay-white-light .container-fluid,
  .overlay-white-middle .container,
  .overlay-white-middle .container-fluid,
  .overlay-white-dark .container,
  .overlay-white-dark .container-fluid,
  .overlay-primary-light .container,
  .overlay-primary-light .container-fluid,
  .overlay-primary-middle .container,
  .overlay-primary-middle .container-fluid,
  .overlay-primary-dark .container,
  .overlay-primary-dark .container-fluid,
  .overlay-primary .container,
  .overlay-primary .container-fluid {
    position: relative;
    z-index: 1; }

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #000; }

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: var(--gradient);
  background-size: 200%; }

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #FFF; }

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: #896eff; }

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3; }

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.5; }

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.8; }

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .overlay-bx .align-b {
    height: auto;
    left: 0;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    transform: translate(0);
    -o-transform: translate(0);
    -moz-transform: translate(0);
    -webkit-transform: translate(0); }
  .overlay-bx .align-m {
    height: auto;
    left: 50%;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px; }

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px; }
  .overlay-icon a {
    display: inline-block;
    padding: 0;
    margin: 0 2px; }
    .overlay-icon a i {
      background-color: #FFF; }

.overlay-bx:hover a > i,
.dlab-media:hover .overlay-bx a > i,
.dlab-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

.overlay-bx:hover,
.dlab-media:hover .overlay-bx,
.dlab-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible; }

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer; }
.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px; }

/* info box show */
.dlab-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2; }
  .dlab-info-has.p-a15 {
    padding: 15px; }
  .dlab-info-has.p-a20 {
    padding: 20px; }
  .dlab-info-has h1,
  .dlab-info-has h2,
  .dlab-info-has h3,
  .dlab-info-has h4,
  .dlab-info-has h5,
  .dlab-info-has a,
  .dlab-info-has p,
  .dlab-info-has h6 {
    color: #fff; }
  .dlab-info-has .dlab-info-has-text {
    margin-bottom: 20px;
    line-height: 24px; }
  .dlab-info-has.bg-white h1,
  .dlab-info-has.bg-white h2,
  .dlab-info-has.bg-white h3,
  .dlab-info-has.bg-white h4,
  .dlab-info-has.bg-white h5,
  .dlab-info-has.bg-white h6 {
    color: #3d474a; }
    .dlab-info-has.bg-white h1 a,
    .dlab-info-has.bg-white h2 a,
    .dlab-info-has.bg-white h3 a,
    .dlab-info-has.bg-white h4 a,
    .dlab-info-has.bg-white h5 a,
    .dlab-info-has.bg-white h6 a {
      color: #3d474a; }
  .dlab-info-has.bg-white p {
    color: #3d474a; }

.dlab-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9); }

.dlab-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9); }

.dlab-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6); }

.dlab-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important; }

.dlab-media {
  overflow: hidden;
  position: relative; }
  .dlab-media:hover .dlab-info-has {
    opacity: 1;
    width: 100%;
    margin-bottom: 0; }

.dlab-box:hover .dlab-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0; }

.overlay-bx.no-hover,
.dlab-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
  visibility: visible; }

.testimonial-pic {
  background: #FFF;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #FFF; }
  .testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%; }
    .testimonial-pic.radius img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      -webkit-border-radius: 100%; }
  .testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35); }

.quote-left {
  position: relative; }
  .quote-left:before {
    font-family: "FontAwesome";
    position: absolute;
    z-index: 10;
    top: 15px;
    content: "\f10d";
    left: 0; }

.quote-right {
  position: relative; }
  .quote-right:before {
    font-family: "FontAwesome";
    position: absolute;
    z-index: 10;
    top: 15px;
    content: "\f10e";
    right: 0; }

.testimonial-text {
  padding: 15px;
  position: relative;
  font-size: 15px;
  font-weight: 400; }
  .testimonial-text p:last-child {
    margin: 0; }

.testimonial-detail {
  padding: 5px; }

.testimonial-name {
  font-size: 22px;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  color: #1f2471;
  line-height: 26px;
  margin-bottom: 5px; }

.testimonial-position {
  font-size: 15px; }

.testimonial-name,
.testimonial-position {
  display: block; }

.testimonial-bg {
  color: #fff; }

.client-box .owl-theme .owl-dots {
  text-align: center; }

.client-says {
  padding-left: 180px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .client-says {
      padding-left: 0; } }
  .client-says .testimonial-detail {
    padding: 0; }
  .client-says .testimonial-pic {
    width: 150px;
    height: 150px;
    border: 0;
    position: absolute;
    left: 0;
    top: 0; }
    @media only screen and (max-width: 767px) {
      .client-says .testimonial-pic {
        width: 70px;
        height: 70px;
        position: unset;
        margin-bottom: 15px;
        text-align: center; } }
  .client-says .testimonial-text {
    padding: 0; }
    .client-says .testimonial-text p {
      position: relative;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      font-weight: 400;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .client-says .testimonial-text p {
          font-size: 15px;
          line-height: 24px; } }
  .client-says .testimonial-name {
    color: #896eff;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 600; }
  .client-says .testimonial-position {
    font-size: 14px;
    font-style: inherit;
    text-transform: capitalize;
    color: #999; }

.testimonial-two .client-says {
  padding-left: 130px; }
  .testimonial-two .client-says .testimonial-pic {
    width: 90px;
    height: 90px; }
  .testimonial-two .client-says .testimonial-text p {
    font-size: 16px; }
  .testimonial-two .client-says .testimonial-name {
    font-size: 20px; }
  .testimonial-two .client-says .testimonial-position {
    font-size: 13px;
    color: #868686; }

.testimonial-two-dots-bx.owl-theme .owl-dots .owl-dot span {
  height: 13px;
  width: 13px;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1; }
.testimonial-two-dots-bx.owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  background-color: #896eff;
  width: 13px; }

.testimonial-1 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
  .testimonial-1 .testimonial-text {
    border-bottom: 1px solid #E1E1F0;
    padding: 0 0 20px;
    margin-bottom: 20px; }
  .testimonial-1 .testimonial-pic {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 3px solid;
    border-color: #896eff;
    overflow: hidden;
    margin-right: 15px; }
  .testimonial-1 .star-rating li {
    font-size: 18px;
    margin: 0 2px; }
  .testimonial-1 .testimonial-detail {
    padding: 0;
    display: flex;
    align-items: center; }

.testimonial-2 {
  display: flex;
  align-items: center;
  width: 800px;
  margin: auto; }
  .testimonial-2 .testimonial-pic {
    overflow: unset;
    margin-right: 20px;
    width: auto;
    height: auto;
    background-color: transparent;
    border: 0; }
    .testimonial-2 .testimonial-pic img {
      border-radius: 50%;
      height: 150px;
      width: 150px;
      min-width: 150px; }
    .testimonial-2 .testimonial-pic:before {
      right: 10px;
      font-size: 50px;
      top: auto;
      bottom: -30px; }
  .testimonial-2 .testimonial-name {
    font-size: 20px;
    font-weight: 600;
    padding-left: 40px;
    position: relative; }
    .testimonial-2 .testimonial-name:before {
      content: "";
      background-color: #1f2471;
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 30px; }
  @media only screen and (max-width: 991px) {
    .testimonial-2 {
      width: 100%; } }
  @media only screen and (max-width: 575px) {
    .testimonial-2 {
      display: block; }
      .testimonial-2 .testimonial-pic {
        margin-bottom: 30px; }
      .testimonial-2 .testimonial-text {
        padding: 0; } }

.testimonial-3 {
  text-align: center;
  width: 750px;
  margin: auto; }
  .testimonial-3 .testimonial-pic {
    width: 170px;
    height: 170px;
    border: 2px solid #896eff;
    border-radius: 50%;
    position: relative; }
    .testimonial-3 .testimonial-pic img {
      width: 125px;
      height: 125px;
      box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%; }
  @media only screen and (max-width: 991px) {
    .testimonial-3 {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .testimonial-3 .testimonial-pic {
      width: 140px;
      height: 140px; }
      .testimonial-3 .testimonial-pic img {
        width: 100px;
        height: 100px; } }

.slick-list {
  margin: 0 -15px; }

.slick-slide > div {
  padding: 0 15px; }

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 15px; }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 0px;
    height: 0px;
    background: transparent;
    border-radius: 30px; }
    .slick-dots li button:hover, .slick-dots li button:focus {
      outline: none; }
    .slick-dots li button:hover:before, .slick-dots li button:focus:before {
      opacity: 1; }
    .slick-dots li button:before {
      width: 10px;
      height: 10px;
      content: '';
      opacity: 1;
      background: #896eff;
      border-radius: 30px;
      margin: 4px; }
  .slick-dots li.slick-active li {
    width: 30px;
    margin: 0 15px; }
  .slick-dots li.slick-active button:before {
    width: 30px;
    opacity: 1; }

.owl-none .owl-nav {
  display: none; }

.owl-nav .owl-prev,
.owl-nav .owl-next {
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block; }

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin-top: 20px;
  text-align: center; }
.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }
.owl-theme .owl-dots {
  text-align: center; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #d6d6d6;
      display: block;
      -webkit-backface-visibility: visible;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -ms-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
      background: #869791; }
.owl-theme.dots-style-1 .owl-dots .owl-dot span {
  background: rgba(0, 0, 0, 0.2);
  width: 10px;
  height: 10px;
  margin: 5px; }
.owl-theme.dots-style-1 .owl-dots .owl-dot:hover span, .owl-theme.dots-style-1 .owl-dots .owl-dot.active span {
  background: #000; }
.owl-theme.dots-style-3 .owl-dots {
  margin-top: 30px;
  position: unset;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto; }
  .owl-theme.dots-style-3 .owl-dots .owl-dot {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    margin: 4px;
    padding: 3px; }
    .owl-theme.dots-style-3 .owl-dots .owl-dot span {
      height: 8px;
      width: 8px;
      margin: 0; }
    .owl-theme.dots-style-3 .owl-dots .owl-dot:hover, .owl-theme.dots-style-3 .owl-dots .owl-dot.active {
      border: 1px solid #FF3B68; }
      .owl-theme.dots-style-3 .owl-dots .owl-dot:hover span, .owl-theme.dots-style-3 .owl-dots .owl-dot.active span {
        background-color: #FF3B68; }

.owl-btn-top-c .owl-nav {
  text-align: center; }

.owl-btn-top-l .owl-nav {
  text-align: left; }
  .owl-btn-top-l .owl-nav .owl-prev {
    margin-left: 0; }

.owl-btn-top-r .owl-nav {
  text-align: right; }
  .owl-btn-top-r .owl-nav .owl-next {
    margin-right: 0; }

.owl-btn-top-lr .owl-nav {
  text-align: left; }
  .owl-btn-top-lr .owl-nav .owl-next {
    margin-right: 0;
    float: right; }

.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
  padding: 40px 8px; }

.owl-btn-center .owl-nav {
  justify-content: center; }

.owl-btn-center-lr .owl-prev,
.owl-btn-center-lr .owl-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer; }
.owl-btn-center-lr .owl-prev {
  left: 0; }
.owl-btn-center-lr .owl-next {
  left: auto;
  right: 0; }

.owl-carousel .owl-item img {
  transform-style: inherit; }

.owl-btn-1 .owl-nav .owl-prev,
.owl-btn-1 .owl-nav .owl-next {
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  margin: 0 -70px !important;
  padding: 0;
  text-align: center;
  width: 40px; }
  @media only screen and (max-width: 1400px) {
    .owl-btn-1 .owl-nav .owl-prev,
    .owl-btn-1 .owl-nav .owl-next {
      margin: 0 -5px !important; } }
.owl-btn-1.owl-btn-gradient .owl-nav .owl-prev,
.owl-btn-1.owl-btn-gradient .owl-nav .owl-next {
  background-image: var(--gradient);
  background-size: 200%;
  color: #fff; }

.owl-btn-2 .owl-nav .owl-prev,
.owl-btn-2 .owl-nav .owl-next {
  border-radius: 50px;
  height: 45px;
  line-height: 45px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 45px; }

.owl-btn-3 .owl-nav .owl-prev,
.owl-btn-3 .owl-nav .owl-next {
  border-radius: 3px;
  line-height: 40px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  margin: 0 -70px !important;
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
  font-size: 35px;
  height: auto;
  width: auto;
  opacity: 0.2; }
  .owl-btn-3 .owl-nav .owl-prev:hover,
  .owl-btn-3 .owl-nav .owl-next:hover {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    opacity: 1; }
  @media only screen and (max-width: 991px) {
    .owl-btn-3 .owl-nav .owl-prev,
    .owl-btn-3 .owl-nav .owl-next {
      margin: 0 10px !important; } }
.owl-btn-3.btn-white .owl-nav .owl-prev,
.owl-btn-3.btn-white .owl-nav .owl-next {
  color: #fff; }
  .owl-btn-3.btn-white .owl-nav .owl-prev:hover,
  .owl-btn-3.btn-white .owl-nav .owl-next:hover {
    color: #fff; }

.owl-btn-4 {
  overflow: hidden; }
  .owl-btn-4 .owl-nav .owl-prev,
  .owl-btn-4 .owl-nav .owl-next {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 30px;
    box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.5);
    color: #000000;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    margin: 0 -10px !important;
    overflow: hidden;
    padding: 0;
    width: 50px;
    opacity: 0.7; }
    .owl-btn-4 .owl-nav .owl-prev:hover,
    .owl-btn-4 .owl-nav .owl-next:hover {
      background: #ffffff none repeat scroll 0 0;
      color: #000;
      opacity: 1; }

.owl-btn-primary .owl-prev,
.owl-btn-primary .owl-next {
  background-color: #896eff;
  color: #fff;
  transition: all 0.5s; }
  .owl-btn-primary .owl-prev:hover,
  .owl-btn-primary .owl-next:hover {
    background-color: #fff;
    color: #896eff; }

.owl-btn-white .owl-prev,
.owl-btn-white .owl-next {
  background-color: #fff;
  color: #896eff;
  transition: all 0.5s; }
  .owl-btn-white .owl-prev:hover,
  .owl-btn-white .owl-next:hover {
    background-color: #896eff;
    color: #fff; }

.owl-dots-none .owl-dots {
  display: none; }

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-primary-full .owl-dots .owl-dot span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 4px; }
.owl-dots-white-full .owl-dots .owl-dot:active span, .owl-dots-white-full .owl-dots .owl-dot.active span,
.owl-dots-black-full .owl-dots .owl-dot:active span,
.owl-dots-black-full .owl-dots .owl-dot.active span,
.owl-dots-primary-full .owl-dots .owl-dot:active span,
.owl-dots-primary-full .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: #896eff;
  width: 25px; }

.owl-dots-white-big .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 4px; }
.owl-dots-white-big .owl-dots .owl-dot:active span,
.owl-dots-black-big .owl-dots .owl-dot:active span,
.owl-dots-primary-big .owl-dots .owl-dot:active span {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0px 4px 2px 4px; }

.owl-dots-primary-full .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  background-color: #896eff; }

.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span {
  background-color: #333; }

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span {
  background-color: #f5f5f5; }

.pricingtable-inner {
  text-align: center; }

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none; }
  .pricingtable-features li {
    padding: 12px; }
    .pricingtable-features li i {
      margin: 0 3px; }

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px; }

.table-option {
  border: 2px solid #adadad;
  border-radius: 6px;
  padding: 50px 0px 30px 0; }
  .table-option .pricingtable-footer {
    border: 0;
    background-color: transparent; }
  .table-option .pricingtable-features {
    border: 0;
    background-color: transparent; }
    .table-option .pricingtable-features li {
      border: 0;
      background-color: transparent;
      color: #919191;
      font-size: 18px; }
  .table-option .pricingtable-title {
    border: 0;
    background-color: transparent;
    padding: 0; }
    .table-option .pricingtable-title * {
      color: #000; }
    .table-option .pricingtable-title h2 {
      font-size: 28px;
      line-height: 36px; }
  .table-option .pricingtable-price {
    padding: 0;
    border: 0;
    background-color: transparent; }
  .table-option .pricingtable-bx {
    color: #000;
    font-size: 63px;
    font-weight: 800; }
    .table-option .pricingtable-bx strong,
    .table-option .pricingtable-bx sup {
      font-size: 30px;
      font-weight: 700; }
  .table-option:hover, .table-option.active {
    background-color: #eeeeee;
    border: 2px solid #eeeeee; }
  .table-option.dark {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .table-option.dark:hover, .table-option.dark.active {
      background-color: #404040;
      border: 2px solid #404040;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
      transition: all 0.5s; }
      .table-option.dark:hover .pricingtable-features li,
      .table-option.dark:hover .pricingtable-title h2,
      .table-option.dark:hover .pricingtable-price .pricingtable-bx, .table-option.dark.active .pricingtable-features li,
      .table-option.dark.active .pricingtable-title h2,
      .table-option.dark.active .pricingtable-price .pricingtable-bx {
        color: #fff;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
      .table-option.dark:hover .pricingtable-footer .btn.black, .table-option.dark.active .pricingtable-footer .btn.black {
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0);
        color: #000; }

.pricingtable-toggle {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 0;
  margin-bottom: 10px; }
  .pricingtable-toggle span {
    font-size: 18px;
    margin-top: 5px;
    font-weight: 500; }
  .pricingtable-toggle .custom-control-label {
    margin: 0 35px 0 50px; }

.pricingtable-wrapper {
  box-shadow: 0px 0 10px rgba(31, 66, 135, 0.3);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s;
  background-color: #fff; }
  .pricingtable-wrapper.style-1 {
    padding: 50px 30px; }
    .pricingtable-wrapper.style-1 .pricingtable-bx {
      color: #896eff;
      margin: 0;
      transition: all 0.5s; }
    .pricingtable-wrapper.style-1 .pricingtable-features {
      padding: 15px 0; }
      .pricingtable-wrapper.style-1 .pricingtable-features li {
        padding: 8px 0;
        font-weight: 500; }
    .pricingtable-wrapper.style-1 .btn {
      transition: all 0.5s; }
    .pricingtable-wrapper.style-1:hover, .pricingtable-wrapper.style-1.active {
      background-image: var(--gradient);
      background-size: 200%; }
      .pricingtable-wrapper.style-1:hover .pricingtable-features li, .pricingtable-wrapper.style-1.active .pricingtable-features li {
        color: #fff; }
      .pricingtable-wrapper.style-1:hover .pricingtable-bx, .pricingtable-wrapper.style-1.active .pricingtable-bx {
        color: #fff; }
      .pricingtable-wrapper.style-1:hover .pricingtable-title h3, .pricingtable-wrapper.style-1.active .pricingtable-title h3 {
        color: #fff; }
      .pricingtable-wrapper.style-1:hover .btn, .pricingtable-wrapper.style-1.active .btn {
        color: #fff; }
        .pricingtable-wrapper.style-1:hover .btn i, .pricingtable-wrapper.style-1.active .btn i {
          color: #896eff;
          background-color: #fff; }
  .pricingtable-wrapper.style-2 {
    padding: 30px; }
    .pricingtable-wrapper.style-2 [class*="icon-bx-"] {
      position: relative;
      margin: 30px 20px; }
      .pricingtable-wrapper.style-2 [class*="icon-bx-"]:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        background-image: url(.././public/images/pattern/pattern1.png);
        height: 110px;
        width: 110px;
        background-repeat: no-repeat; }
      .pricingtable-wrapper.style-2 [class*="icon-bx-"] .icon-cell i {
        font-size: 42px;
        color: #896eff; }
    .pricingtable-wrapper.style-2 .pricingtable-features {
      padding: 10px 0 20px; }
      .pricingtable-wrapper.style-2 .pricingtable-features li {
        padding: 5px 0; }
    .pricingtable-wrapper.style-2 .pricingtable-bx {
      font-size: 42px; }
      .pricingtable-wrapper.style-2 .pricingtable-bx .pricingtable-type {
        font-size: 45%;
        font-weight: 600; }
    .pricingtable-wrapper.style-2.active, .pricingtable-wrapper.style-2:hover {
      background-image: var(--gradient);
      background-size: 200%; }
      .pricingtable-wrapper.style-2.active [class*="icon-bx-"], .pricingtable-wrapper.style-2:hover [class*="icon-bx-"] {
        background-color: rgba(255, 255, 255, 0.5); }
        .pricingtable-wrapper.style-2.active [class*="icon-bx-"] .icon-cell i, .pricingtable-wrapper.style-2:hover [class*="icon-bx-"] .icon-cell i {
          color: #fff; }
        .pricingtable-wrapper.style-2.active [class*="icon-bx-"]:before, .pricingtable-wrapper.style-2:hover [class*="icon-bx-"]:before {
          background-image: url(.././public/images/pattern/pattern1-light.png); }
      .pricingtable-wrapper.style-2.active .btn, .pricingtable-wrapper.style-2:hover .btn {
        color: #896eff;
        background-color: #fff;
        background-image: unset; }
      .pricingtable-wrapper.style-2.active .pricingtable-features li, .pricingtable-wrapper.style-2:hover .pricingtable-features li {
        color: #fff; }
      .pricingtable-wrapper.style-2.active .pricingtable-bx, .pricingtable-wrapper.style-2:hover .pricingtable-bx {
        color: #fff; }
      .pricingtable-wrapper.style-2.active .pricingtable-title h3, .pricingtable-wrapper.style-2:hover .pricingtable-title h3 {
        color: #fff; }
  .pricingtable-wrapper.style-3 {
    padding-bottom: 35px; }
    .pricingtable-wrapper.style-3 .pricingtable-head {
      position: relative;
      background-image: var(--gradient-sec);
      background-size: 200%;
      padding: 30px 30px 0;
      margin-bottom: 10px; }
      .pricingtable-wrapper.style-3 .pricingtable-head:after {
        content: "";
        background-image: url(.././public/images/pattern/pattern4.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0; }
      .pricingtable-wrapper.style-3 .pricingtable-head .pricingtable-title h3 {
        color: #fff; }
      .pricingtable-wrapper.style-3 .pricingtable-head .icon-cell {
        z-index: 9;
        position: relative; }
    .pricingtable-wrapper.style-3 .pricingtable-features {
      margin: 25px 0; }
      .pricingtable-wrapper.style-3 .pricingtable-features li {
        padding: 5px 10px; }
    .pricingtable-wrapper.style-3 .pricingtable-bx {
      font-size: 45px; }
      .pricingtable-wrapper.style-3 .pricingtable-bx small {
        font-size: 18px;
        color: #4454a2;
        font-weight: 600;
        margin-left: 8px; }
    .pricingtable-wrapper.style-3.active .btn, .pricingtable-wrapper.style-3:hover .btn {
      background-color: #896eff;
      color: #fff; }

.dlab-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle; }
  .dlab-img-effect img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dlab-img-effect.opacity img:hover {
    opacity: 0.8; }
  .dlab-img-effect.zoom-slow img {
    transition: all 3s;
    -moz-transition: all 3s;
    -webkit-transition: all 3s;
    -o-transition: all 3s; }
  .dlab-img-effect.zoom-slow:hover img {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dlab-img-effect.zoom:hover img {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dlab-img-effect.shrink:hover img {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8); }
  .dlab-img-effect.side-pan:hover img {
    margin-left: -11%;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dlab-img-effect.vertical-pan:hover img {
    margin-top: -10%;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dlab-img-effect.fade-in {
    background: #000; }
    .dlab-img-effect.fade-in img {
      opacity: 0.65; }
    .dlab-img-effect.fade-in:hover img {
      opacity: 1; }
  .dlab-img-effect.fade-out {
    background: #000; }
    .dlab-img-effect.fade-out:hover img {
      opacity: 0.7; }
  .dlab-img-effect.rotate:hover img {
    -moz-transform: scale(1.5) rotate(-20deg);
    -webkit-transform: scale(1.5) rotate(-20deg);
    -o-transform: scale(1.5) rotate(-20deg);
    -ms-transform: scale(1.5) rotate(-20deg);
    transform: scale(1.5) rotate(-20deg); }
  .dlab-img-effect.image-sepia img {
    -webkit-filter: sepia(100%);
    filter: sepia(100%); }
  .dlab-img-effect.blurr img {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dlab-img-effect.blurr:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px); }
  .dlab-img-effect.blurr-invert img {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px); }
  .dlab-img-effect.blurr-invert:hover img {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px); }
  .dlab-img-effect.off-color img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0); }
  .dlab-img-effect.off-color:hover img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1); }
  .dlab-img-effect.on-color img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1); }
  .dlab-img-effect.on-color:hover img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0); }

.dlab-box:hover .dlab-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg); }

.dlab-team.style-1 .dlab-social-icon {
  display: flex; }
  .dlab-team.style-1 .dlab-social-icon li {
    margin: 2px; }
.dlab-team.style-1 .dlab-content {
  box-shadow: 0px 5px 10px rgba(31, 66, 135, 0.1);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -60px;
  z-index: 99;
  background-color: #fff;
  margin-left: 30px;
  position: relative; }
.dlab-team.style-1 .dlab-name {
  margin-bottom: 3px; }
.dlab-team.style-1 .dlab-position {
  text-transform: uppercase;
  font-size: 13px; }
.dlab-team.style-1 .dlab-media {
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.1);
  border-radius: 10px;
  margin-right: 30px; }
@media only screen and (max-width: 575px) {
  .dlab-team.style-1 .dlab-content {
    padding: 20px;
    margin-left: 20px;
    margin-top: -50px; }
  .dlab-team.style-1 .dlab-media {
    margin-right: 20px; } }
.dlab-team.style-2 .dlab-media {
  border-radius: 50px 0 50px 50px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
.dlab-team.style-2:hover .dlab-media {
  border-radius: 50px 50px 50px 0px; }
.dlab-team.style-2 .dlab-content {
  padding: 20px;
  text-align: center; }
.dlab-team.style-2 .dlab-position {
  font-size: 14px; }
.dlab-team.style-2 .dlab-name {
  margin-bottom: 5px; }
.dlab-team.style-3 .dlab-media {
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.1);
  border-radius: 10px; }
.dlab-team.style-3 .overlay-content {
  position: absolute;
  background-color: rgba(137, 110, 255, 0.7);
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s; }
.dlab-team.style-3 .dlab-content {
  padding-top: 15px; }
.dlab-team.style-3 .dlab-position {
  font-size: 15px; }
.dlab-team.style-3 .dlab-name {
  margin-bottom: 5px;
  transition: all 0.5s; }
.dlab-team.style-3:hover .overlay-content {
  transition: all 0.5s;
  opacity: 1; }
.dlab-team.style-3:hover .dlab-name a {
  color: #896eff; }

.dlab-accordion .collapsed {
  display: none; }
  .dlab-accordion .collapsed.show {
    display: block; }
.dlab-accordion .card {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px; }
.dlab-accordion .card-body {
  padding: 25px 30px;
  border-radius: 0; }
.dlab-accordion .card-header {
  padding: 0;
  border: 0; }
  .dlab-accordion .card-header .dlab-title {
    margin: 0;
    width: 100%; }
    .dlab-accordion .card-header .dlab-title a {
      width: 100%;
      border-radius: 8px;
      padding: 24px 65px 24px 30px;
      transition: all 0.5s;
      background-image: var(--gradient);
      background-size: 200%;
      color: #fff;
      display: block;
      position: relative;
      cursor: pointer;
      box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
      .dlab-accordion .card-header .dlab-title a:after {
        content: "";
        background-color: #fff;
        font-family: themify;
        height: 55px;
        width: 55px;
        display: block;
        position: absolute;
        right: 5px;
        top: 6px;
        border-radius: 8px;
        line-height: 57px;
        text-align: center;
        font-size: 24px;
        transition: all 0.5s; }
      .dlab-accordion .card-header .dlab-title a:before {
        content: "\e61a";
        color: #896eff;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 24px;
        font-family: 'themify';
        z-index: 99;
        opacity: 1;
        transform: rotate(45deg);
        transition: all 0.5s; }
      .dlab-accordion .card-header .dlab-title a.collapsed {
        background: #fff;
        color: #1f2471; }
        .dlab-accordion .card-header .dlab-title a.collapsed:after {
          content: "\e61a";
          color: #fff;
          background-image: var(--gradient);
          background-size: 200%; }
        .dlab-accordion .card-header .dlab-title a.collapsed:before {
          opacity: 0;
          transform: rotate(0); }
  @media only screen and (max-width: 575px) {
    .dlab-accordion .card-header .dlab-title a {
      padding: 15px 65px 15px 15px; } }
.dlab-accordion.accordion-sm .card-header .dlab-title a {
  padding: 16px 55px 16px 20px; }
  .dlab-accordion.accordion-sm .card-header .dlab-title a:after {
    height: 40px;
    width: 40px;
    right: 5px;
    top: 6px;
    line-height: 40px;
    font-size: 20px;
    background-color: #fff; }
  .dlab-accordion.accordion-sm .card-header .dlab-title a:before {
    right: 14px;
    top: 14px;
    font-size: 20px; }
@media only screen and (max-width: 575px) {
  .dlab-accordion.accordion-sm .card-header .dlab-title a {
    padding: 15px 65px 15px 15px; } }

.widget {
  margin-bottom: 40px; }
  .widget .widget-title {
    color: #1f2471;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
    font-size: 30px; }
    .widget .widget-title:before {
      content: "";
      height: 100%;
      width: 5px;
      border-radius: 10px;
      background-color: #896eff;
      position: absolute;
      left: 0;
      top: 0; }
  .widget.style-1 .widget-title {
    padding: 0 0 12px;
    margin-bottom: 30px; }
    .widget.style-1 .widget-title:after, .widget.style-1 .widget-title:before {
      content: "";
      height: 6px;
      border-radius: 10px;
      background-image: var(--gradient);
      background-size: 200%;
      position: absolute;
      bottom: 0; }
    .widget.style-1 .widget-title:before {
      width: 25px;
      left: 0;
      top: auto; }
    .widget.style-1 .widget-title:after {
      width: 55px;
      left: 32px; }

.widget_archive ul li a {
  display: flex;
  padding: 8px 8px 8px 25px;
  color: #1f2471;
  transition: all .5s;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px; }
  .widget_archive ul li a span {
    font-size: 15px;
    background-color: rgba(137, 110, 255, 0.1);
    color: #1f2471;
    padding: 4px 15px;
    border-radius: 20px;
    transition: all .5s; }
  .widget_archive ul li a:hover {
    box-shadow: 0px 0 10px rgba(31, 66, 135, 0.2);
    color: #896eff; }
    .widget_archive ul li a:hover span {
      background-image: var(--gradient);
      background-size: 200%;
      color: #fff; }
.widget_archive.style-1 ul li a {
  border-radius: 0 20px 0 20px; }

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table; }
  .widget_tag_cloud .tagcloud a {
    position: relative;
    border: 1px solid;
    border-color: #1f2471;
    border-radius: 20px;
    padding: 3px 15px 3px 35px;
    display: inline-block;
    margin: 0 10px 15px 0;
    font-size: 15px;
    color: #1f2471; }
    .widget_tag_cloud .tagcloud a:before {
      content: "\f00c";
      font-family: 'Line Awesome Free';
      height: 24px;
      width: 24px;
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      border-radius: 50px;
      background-color: #1f2471;
      position: absolute;
      left: 3px;
      top: 3px;
      color: #1f2471;
      text-align: center;
      transition: all 0.5s; }
    .widget_tag_cloud .tagcloud a:hover:before {
      background-color: #896eff;
      color: #fff; }

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.5s; }
  .recent-posts-entry .widget-post-bx .widget-post .dlab-media {
    width: 130px;
    border-radius: 8px; }
  .recent-posts-entry .widget-post-bx .widget-post .dlab-info {
    padding-left: 15px; }
  .recent-posts-entry .widget-post-bx .widget-post .dlab-meta li {
    font-size: 14px;
    color: #1f2471; }
  .recent-posts-entry .widget-post-bx .widget-post .title {
    font-weight: 600;
    font-size: 18px; }
  .recent-posts-entry .widget-post-bx .widget-post:hover {
    box-shadow: 0px 0 10px rgba(31, 66, 135, 0.2); }
.recent-posts-entry.style-1 .widget-post-bx .widget-post {
  border-radius: 0 20px 0 20px; }

.search-bx .form-control {
  padding: 10px 100px 10px 50px; }
.search-bx .input-group-prepend {
  border-right: 0; }
  .search-bx .input-group-prepend .input-group-text i {
    color: #828282; }
.search-bx button {
  position: absolute;
  right: 0;
  height: 45px;
  border-radius: 30px;
  top: 0;
  font-size: 30px;
  padding: 0px 30px;
  z-index: 99; }
.search-bx.style-1 {
  padding: 30px;
  background-color: #1f2471;
  border-radius: 8px; }
  .search-bx.style-1 .input-group {
    margin-bottom: 0; }
  .search-bx.style-1 .form-control {
    border-radius: 0px 20px 0px 20px !important; }
  .search-bx.style-1 button {
    border-radius: 0 20px 0 0;
    padding: 0px 10px; }

.widget_nav_menu li a {
  background-color: #fff;
  display: block;
  border-radius: 4px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 5px 12px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  background-size: 200%; }
  .widget_nav_menu li a:hover {
    background-image: var(--gradient);
    color: #fff; }
.widget_nav_menu li.active a {
  background-image: var(--gradient);
  color: #fff; }

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px; }
  .download-file .title {
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600; }
  .download-file ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .download-file ul li a {
      background-color: #fff;
      color: #000;
      display: block;
      margin-bottom: 10px;
      border-radius: 4px;
      width: 100%;
      padding: 15px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .download-file ul li a i {
        width: 18px;
        height: 18px;
        position: relative; }
        .download-file ul li a i:after, .download-file ul li a i:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          left: 0;
          top: 0; }
        .download-file ul li a i:before {
          background-image: url(.././public/images/icon/download-icon2.png);
          -webkit-animation: download1 2s ease infinite;
          animation: download1 2s ease infinite; }
        .download-file ul li a i:after {
          background-image: url(.././public/images/icon/download-icon1.png); }
      .download-file ul li a .text {
        display: inline;
        position: relative; }
      .download-file ul li a:hover .text {
        color: #896eff; }

@-webkit-keyframes download1 {
  0%,
    10%,
    20%,
    50%,
    80%,
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }
@keyframes download1 {
  0%,
    10%,
    20%,
    50%,
    80%,
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }
.action-box.style-1 {
  padding: 80px 0 30px; }
  @media only screen and (max-width: 991px) {
    .action-box.style-1 {
      padding: 60px 0 30px; } }
  @media only screen and (max-width: 767px) {
    .action-box.style-1 .text-right {
      text-align: left !important; } }
  @media only screen and (max-width: 575px) {
    .action-box.style-1 {
      padding: 50px 0 10px; } }

.dlab-subscribe.style-1 {
  padding: 75px 60px;
  background-color: #fff;
  z-index: 99;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  background-image: url(.././public/images/pattern/pattern3.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 50px; }
  .dlab-subscribe.style-1 form {
    margin-left: 50px; }
    .dlab-subscribe.style-1 form .input-group,
    .dlab-subscribe.style-1 form .form-group {
      margin-bottom: 0; }
    .dlab-subscribe.style-1 form button {
      width: 45px;
      height: 45px;
      border-radius: 50px;
      padding: 0;
      position: absolute;
      right: 0;
      z-index: 99; }
  @media only screen and (max-width: 1199px) {
    .dlab-subscribe.style-1 {
      margin: 0;
      padding: 40px; } }
  @media only screen and (max-width: 991px) {
    .dlab-subscribe.style-1 form {
      margin-left: 0;
      margin-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .dlab-subscribe.style-1 .title {
      font-size: 32px; } }
  @media only screen and (max-width: 575px) {
    .dlab-subscribe.style-1 {
      padding: 30px; }
      .dlab-subscribe.style-1 .title {
        font-size: 32px; }
      .dlab-subscribe.style-1 form {
        margin-top: 15px; } }
.dlab-subscribe.style-2 form .form-control {
  height: 56px;
  border-radius: 50px !important; }
.dlab-subscribe.style-2 form button {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 9; }
@media only screen and (max-width: 575px) {
  .dlab-subscribe.style-2 form button {
    padding-left: 20px;
    padding-right: 20px; } }
.dlab-subscribe.style-3 form .form-control {
  height: 56px;
  border-radius: 0 22px 0 22px !important; }
.dlab-subscribe.style-3 form button {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 99; }

nav .pagination .page-item {
  margin: 0 5px; }
  nav .pagination .page-item .page-link {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50px;
    background-color: rgba(137, 110, 255, 0.1);
    color: #1f2471;
    text-align: center;
    border: 0;
    padding: 0;
    transition: all 0.5s; }
    nav .pagination .page-item .page-link.prev, nav .pagination .page-item .page-link.next {
      width: 80px; }
    nav .pagination .page-item .page-link.active, nav .pagination .page-item .page-link:hover {
      background-image: var(--gradient);
      background-size: 200%;
      color: #fff; }
nav .pagination.text-center {
  justify-content: center; }

.blockquote {
  position: relative;
  margin: 30px 0; }
  .blockquote:before {
    content: "\f11f";
    position: absolute;
    font-family: Flaticon; }
  .blockquote.style-1 {
    padding-left: 60px; }
    .blockquote.style-1:before {
      color: #896eff;
      left: 0;
      top: 50%;
      font-size: 42px;
      transform: translateY(-50%); }
    .blockquote.style-1 .blockquote-content {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 0;
      border-left: 3px solid rgba(137, 110, 255, 0.1);
      padding-left: 15px; }
  .blockquote.style-2 {
    padding: 20px 30px; }
    .blockquote.style-2:before {
      color: #896eff;
      left: 30px;
      font-size: 36px;
      opacity: 0.3;
      z-index: -1;
      top: -7px; }
    .blockquote.style-2:after {
      content: "";
      width: 10px;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: #896eff;
      border-radius: 30px; }
    .blockquote.style-2 .blockquote-content {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0; }
  .blockquote.style-3 {
    padding: 20px 30px 20px 80px;
    background-color: #f8f5ff;
    text-align: center; }
    .blockquote.style-3:before {
      color: #896eff;
      left: 20px;
      top: 50%;
      font-size: 42px;
      transform: translateY(-50%); }
    .blockquote.style-3 .blockquote-content {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0; }

.dlab-content-bx.style-1 {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transition: all 0.5s;
  padding: 15px; }
  .dlab-content-bx.style-1 .counter {
    font-size: 48px; }
  .dlab-content-bx.style-1 .title {
    font-size: 20px;
    color: #262F5A;
    font-weight: 500; }
  .dlab-content-bx.style-1:hover {
    transform: translateY(-15px); }
  @media only screen and (max-width: 1199px) {
    .dlab-content-bx.style-1 {
      height: 200px;
      width: 200px; }
      .dlab-content-bx.style-1 .counter {
        font-size: 42px; }
      .dlab-content-bx.style-1 .title {
        font-size: 16px; } }
.dlab-content-bx.style-2 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  padding: 15px; }
.dlab-content-bx.style-3 {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0px 0 15px rgba(255, 255, 255, 0.5);
  width: 235px;
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  transition: all 0.5s;
  padding: 15px; }
  .dlab-content-bx.style-3 .counter {
    font-size: 48px; }
  .dlab-content-bx.style-3 .title {
    font-size: 20px;
    color: #262F5A;
    font-weight: 500; }
  .dlab-content-bx.style-3:hover {
    transform: translateY(-15px); }
  @media only screen and (max-width: 1199px) {
    .dlab-content-bx.style-3 {
      height: 200px;
      width: 200px; }
      .dlab-content-bx.style-3 .counter {
        font-size: 42px; }
      .dlab-content-bx.style-3 .title {
        font-size: 16px; } }

ul[class*=list-] {
  font-size: 15px; }
  ul[class*=list-] li {
    position: relative;
    padding: 8px 5px 8px 35px; }
    ul[class*=list-] li:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
ul.list-check li:before {
  content: "\f120";
  font-family: Flaticon;
  font-size: 24px; }
ul.list-check.style-1 li {
  padding: 8px 5px 8px 28px; }
  ul.list-check.style-1 li:before {
    content: "\f058";
    font-family: 'Line Awesome Free';
    font-size: 24px; }
ul.list-arrow li:before {
  content: "\f101";
  font-family: fontawesome;
  font-size: 24px; }
ul.primary li:before {
  color: #896eff; }

.content-inner {
  padding-top: 100px;
  padding-bottom: 70px; }
  @media only screen and (max-width: 991px) {
    .content-inner {
      padding-top: 70px;
      padding-bottom: 40px; } }
  @media only screen and (max-width: 575px) {
    .content-inner {
      padding-top: 50px;
      padding-bottom: 20px; } }

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 991px) {
    .content-inner-1 {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .content-inner-1 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.content-inner-2 {
  padding-top: 100px;
  padding-bottom: 0; }
  @media only screen and (max-width: 991px) {
    .content-inner-2 {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .content-inner-2 {
      padding-top: 50px; } }

.content-inner-3 {
  padding-top: 70px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 991px) {
    .content-inner-3 {
      padding-top: 50px;
      padding-bottom: 20px; } }

.dlab-media.style-1, .dlab-media.style-2, .dlab-media.style-3 {
  border-radius: 50%;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 10px solid #fff; }
.dlab-media.style-1 {
  width: 335px;
  height: 335px; }
.dlab-media.style-2 {
  width: 250px;
  height: 250px; }
.dlab-media.style-3 {
  width: 215px;
  height: 215px; }

.star-rating li {
  display: inline-block; }

.setResizeMargin-right {
  padding: 0 15px; }

.quote-media .style-2 {
  float: right;
  margin: -100px 0 0; }
.quote-media .style-3 {
  margin: 100px 0 0 50px; }
@media only screen and (max-width: 991px) {
  .quote-media .style-2 {
    margin: -100px 70px 0 0; }
  .quote-media .style-3 {
    margin: 40px 0 0 50px; } }
@media only screen and (max-width: 767px) {
  .quote-media .style-2 {
    margin: -100px 0 0 0; } }
@media only screen and (max-width: 575px) {
  .quote-media .style-1 {
    width: 250px;
    height: 250px; }
  .quote-media .style-2 {
    margin: -100px 0 0 0;
    width: 200px;
    height: 200px; }
  .quote-media .style-3 {
    margin: -50px 0 0 50px;
    width: 150px;
    height: 150px;
    float: left; } }

.dlab-social-icon li {
  display: inline-block; }
  .dlab-social-icon li a {
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
    color: #896eff;
    background-color: #fff;
    transition: all 0.5s;
    margin: 2px; }
    .dlab-social-icon li a:hover {
      color: #fff;
      background-image: var(--gradient);
      background-size: 200%; }
.dlab-social-icon.primary li a {
  background-color: #896eff;
  color: #fff; }
.dlab-social-icon.primary-light li a {
  background-color: rgba(137, 110, 255, 0.1);
  color: #896eff; }
  .dlab-social-icon.primary-light li a:hover {
    color: #fff;
    background-image: var(--gradient);
    background-size: 200%; }

.dlab-separator.style-1 {
  height: 6px;
  width: 6px;
  background-color: #896eff;
  border-radius: 20px;
  position: relative;
  margin: 5px 23px; }
  .dlab-separator.style-1:after, .dlab-separator.style-1:before {
    height: 2px;
    width: 20px;
    background-color: #896eff;
    position: absolute;
    top: 3px; }
  .dlab-separator.style-1:before {
    left: auto;
    right: 10px; }
  .dlab-separator.style-1:after {
    right: auto;
    left: 10px; }
.dlab-separator.style-2 {
  position: relative;
  height: auto;
  width: 150px; }
  .dlab-separator.style-2:before, .dlab-separator.style-2:after {
    content: "";
    height: 6px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px; }
  .dlab-separator.style-2:before {
    width: 45px; }
  .dlab-separator.style-2:after {
    width: 95px;
    left: 55px; }
  .dlab-separator.style-2.bg-white {
    background-color: transparent; }
    .dlab-separator.style-2.bg-white:before, .dlab-separator.style-2.bg-white:after {
      background-color: #fff; }
  .dlab-separator.style-2.bg-primary {
    background-color: transparent; }
    .dlab-separator.style-2.bg-primary:before, .dlab-separator.style-2.bg-primary:after {
      background-image: var(--gradient);
      background-size: 200%; }

.section-head.text-white .title,
.section-head.text-white p,
.section-head.text-white .sub-title {
  color: #fff; }
.section-head.style-1 .sub-title {
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600; }
.section-head.style-2 .sub-title {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 18px; }
.section-head.style-3 h2.title {
  font-size: 45px; }
.section-head.style-3 .sub-title {
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px; }
@media only screen and (max-width: 1199px) {
  .section-head.style-3 h2.title {
    font-size: 36px; } }
@media only screen and (max-width: 575px) {
  .section-head.style-3 .sub-title {
    font-size: 16px; }
  .section-head.style-3 h2.title {
    font-size: 32px; } }

button.scroltop {
  box-shadow: -4px 4px 24px -10px #896eff !important;
  background: #896eff;
  border-color: #896eff;
  border-radius: 4px;
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all .8s;
  -moz-transition: all .8s;
  -webkit-transition: all .8s;
  -ms-transition: all .8s;
  -o-transition: all .8s; }

.section-wraper-one .icon-bx-wraper:nth-child(2) {
  margin-left: 100px; }
  .section-wraper-one .icon-bx-wraper:nth-child(2):before {
    content: "";
    background-image: url(.././public/images/pattern/pattern7.png);
    height: 450px;
    width: 100%;
    top: -140px;
    left: -50px;
    position: absolute;
    background-repeat: no-repeat;
    z-index: -2; }
  @media only screen and (max-width: 575px) {
    .section-wraper-one .icon-bx-wraper:nth-child(2) {
      margin-left: 0; } }

.banner-one {
  background-position: left bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 750px;
  position: relative; }
  .banner-one .banner-inner {
    padding-top: 150px; }
    .banner-one .banner-inner .img1 {
      position: absolute;
      left: 100px;
      bottom: 50px; }
    .banner-one .banner-inner .img2 {
      position: absolute;
      right: 0px;
      top: 120px; }
  .banner-one .banner-content h1 {
    margin-bottom: 20px; }
  .banner-one .banner-content .sub-title {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px; }
  @media only screen and (max-width: 991px) {
    .banner-one {
      height: 550px; }
      .banner-one .banner-content h1 {
        font-size: 42px; }
      .banner-one .banner-inner {
        padding-top: 120px; } }
  @media only screen and (max-width: 767px) {
    .banner-one {
      height: auto;
      padding-bottom: 30px; }
      .banner-one .banner-content {
        margin-bottom: 50px; } }
  @media only screen and (max-width: 575px) {
    .banner-one .banner-inner {
      padding-top: 100px; }
    .banner-one .banner-content h1 {
      font-size: 32px; } }

.banner-two {
  height: 950px;
  position: relative;
  overflow: hidden; }
  .banner-two:after {
    background-image: url(.././public/images/background/bg7.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0; }
  .banner-two .banner-inner {
    z-index: 99;
    position: relative;
    padding-top: 250px; }
  .banner-two .banner-content h1 {
    color: #fff; }
  .banner-two .banner-content .sub-title {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 30px;
    margin-bottom: 20px; }
  .banner-two .img1,
  .banner-two .img2,
  .banner-two .img3 {
    position: absolute; }
  .banner-two .img1 {
    left: -180px;
    top: 150px; }
  .banner-two .img2 {
    right: 150px;
    bottom: -100px; }
  .banner-two .img3 {
    right: -180px;
    bottom: -220px; }
  .banner-two .dz-media {
    -webkit-mask-image: url(.././public/images/main-slider/slider2/pic2.png);
    mask-image: url(.././public/images/main-slider/slider2/pic2.png);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 40vw; }
  @media only screen and (max-width: 1400px) {
    .banner-two .dz-media {
      width: 65vw; } }
  @media only screen and (max-width: 1199px) {
    .banner-two {
      height: 850px; } }
  @media only screen and (max-width: 991px) {
    .banner-two {
      height: auto; }
      .banner-two .dz-media {
        position: relative;
        width: 100%;
        -webkit-mask-image: unset !important; }
      .banner-two .banner-content {
        margin-bottom: 30px; }
      .banner-two .banner-content h1 {
        font-size: 42px; } }
  @media only screen and (max-width: 767px) {
    .banner-two .banner-inner {
      padding-top: 120px; } }
  @media only screen and (max-width: 575px) {
    .banner-two .banner-content h1 {
      font-size: 32px; }
    .banner-two .img2 {
      display: none; } }

.banner-three {
  height: 850px;
  position: relative;
  background-position: bottom, top;
  background-repeat: no-repeat; }
  .banner-three:after {
    background-image: url(.././public/images/main-slider/slider3/pic2.png);
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 0; }
  .banner-three .banner-content h1 {
    color: #fff; }
  .banner-three .banner-content .sub-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px; }
  .banner-three .banner-inner {
    z-index: 99;
    position: relative;
    padding-top: 200px; }
    .banner-three .banner-inner .dz-media {
      margin-right: -80px; }
  @media only screen and (max-width: 1400px) {
    .banner-three .banner-inner .dz-media {
      margin-right: 0; } }
  @media only screen and (max-width: 1199px) {
    .banner-three {
      height: 750px; } }
  @media only screen and (max-width: 991px) {
    .banner-three {
      height: 600px; }
      .banner-three:after {
        background-position: -280px bottom; }
      .banner-three .banner-content h1 {
        font-size: 42px; }
      .banner-three .banner-inner {
        padding-top: 150px; } }
  @media only screen and (max-width: 767px) {
    .banner-three {
      height: auto;
      padding-bottom: 30px; }
      .banner-three .banner-content {
        margin-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .banner-three:after {
      background-position: -120px bottom; }
    .banner-three .banner-content {
      margin-bottom: 50px; }
    .banner-three .banner-inner {
      padding-top: 120px; } }

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent; }

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9); }

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff; }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #896eff; }

.lightimg {
  cursor: pointer; }

.dlab-img-overlay1.primary:before, .dlab-img-overlay1.primary:after {
  background-color: #896eff; }

.dlab-overlay-box {
  overflow: hidden; }
  .dlab-overlay-box .dlab-info {
    position: absolute;
    z-index: 99;
    transition: all 0.5s;
    width: 100%; }
  .dlab-overlay-box.style-1 {
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(137, 110, 255, 0.2); }
    .dlab-overlay-box.style-1 .dlab-info {
      top: 50%;
      left: 0;
      text-align: center;
      transform: translateY(-50%);
      opacity: 0;
      padding: 30px; }
      .dlab-overlay-box.style-1 .dlab-info .title {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 20px; }
        .dlab-overlay-box.style-1 .dlab-info .title a {
          color: #fff; }
      .dlab-overlay-box.style-1 .dlab-info .icon-link {
        height: 60px;
        width: 60px;
        border-radius: 60px;
        background-color: rgba(250, 248, 242, 0.1);
        color: #fff;
        line-height: 60px;
        text-align: center;
        display: block;
        font-size: 24px;
        margin: auto; }
    .dlab-overlay-box.style-1:hover .dlab-media:before {
      opacity: 0.8; }
    .dlab-overlay-box.style-1:hover .dlab-info {
      opacity: 1; }
  .dlab-overlay-box.style-2 {
    border-radius: 10px;
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
    .dlab-overlay-box.style-2 .dlab-info {
      bottom: -100%;
      left: 0;
      text-align: left;
      padding: 30px; }
      .dlab-overlay-box.style-2 .dlab-info .title {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500; }
        .dlab-overlay-box.style-2 .dlab-info .title a {
          color: #fff; }
      .dlab-overlay-box.style-2 .dlab-info .post-author {
        color: #fff;
        margin-bottom: 0; }
        .dlab-overlay-box.style-2 .dlab-info .post-author a {
          color: #fff; }
    .dlab-overlay-box.style-2:hover .dlab-info {
      bottom: 0; }
  .dlab-overlay-box.style-3 {
    border-radius: 10px;
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1); }
    .dlab-overlay-box.style-3 .dlab-info {
      bottom: -100%;
      left: 30px;
      right: 30px;
      background-color: #fff;
      border-radius: 10px;
      padding: 30px 60px 30px 30px;
      width: auto; }
      .dlab-overlay-box.style-3 .dlab-info:before {
        content: "\f105";
        font-size: 45px;
        color: #896eff;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        font-family: fontawesome;
        position: absolute; }
      .dlab-overlay-box.style-3 .dlab-info .post-author {
        margin-bottom: 0; }
        .dlab-overlay-box.style-3 .dlab-info .post-author a {
          color: #5c6390; }
      .dlab-overlay-box.style-3 .dlab-info .title {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500; }
    .dlab-overlay-box.style-3:hover .dlab-info {
      bottom: 30px; }
    @media only screen and (max-width: 1199px) {
      .dlab-overlay-box.style-3 .dlab-info {
        padding: 20px 40px 20px 20px;
        left: 20px;
        right: 20px; }
        .dlab-overlay-box.style-3 .dlab-info:before {
          font-size: 36px;
          right: 20px; }
      .dlab-overlay-box.style-3:hover .dlab-info {
        bottom: 20px; } }

.pricingtable-wraper-1 {
  display: flex; }
  .pricingtable-wraper-1 .pricingtable-wrapper {
    padding: 30px;
    width: 100%;
    transition: all 0.5s; }
    .pricingtable-wraper-1 .pricingtable-wrapper .pricingtable-title h3 {
      font-size: 22px; }
    .pricingtable-wraper-1 .pricingtable-wrapper .pricingtable-features li {
      padding: 6px 0;
      font-size: 13px; }
    .pricingtable-wraper-1 .pricingtable-wrapper.center {
      margin: -30px -70px 0;
      padding: 60px 30px;
      opacity: 1; }
    .pricingtable-wraper-1 .pricingtable-wrapper.active {
      z-index: 9;
      opacity: 1; }
    .pricingtable-wraper-1 .pricingtable-wrapper:hover {
      z-index: 99;
      opacity: 1; }
  @media only screen and (max-width: 991px) {
    .pricingtable-wraper-1 {
      margin: 50px 0 20px; } }
  @media only screen and (max-width: 575px) {
    .pricingtable-wraper-1 {
      display: block;
      margin: 10px 0 0px; }
      .pricingtable-wraper-1 .pricingtable-wrapper.center {
        margin: 0 0 30px;
        padding: 30px; } }

.counter-wraper-1 {
  background-color: #fffbf6;
  padding-top: 70px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .counter-wraper-1 {
      padding-top: 50px;
      padding-bottom: 20px; } }

.about-wraper-1 {
  overflow: hidden; }
  .about-wraper-1 .section-head {
    margin-bottom: 40px; }
  .about-wraper-1 .dz-media {
    width: 50vw;
    position: relative; }
    .about-wraper-1 .dz-media img {
      width: 100%; }
    .about-wraper-1 .dz-media:after {
      content: "";
      height: 100%;
      background-image: var(--gradient);
      position: absolute;
      top: 0;
      width: 50vw;
      z-index: -1; }
    .about-wraper-1 .dz-media.left {
      float: right;
      margin: 60px 70px 60px 0; }
      .about-wraper-1 .dz-media.left img {
        border-radius: 0 100px 100px 0; }
      .about-wraper-1 .dz-media.left:after {
        right: -20px;
        transform: rotate(8deg);
        border-radius: 0 100px 100px 0; }
    .about-wraper-1 .dz-media.right {
      float: left;
      margin: 60px 0 60px 70px; }
      .about-wraper-1 .dz-media.right img {
        border-radius: 100px 0 0 100px; }
      .about-wraper-1 .dz-media.right:after {
        left: -20px;
        transform: rotate(-8deg);
        border-radius: 100px 0 0 100px; }
  @media only screen and (max-width: 1199px) {
    .about-wraper-1 .dz-media {
      margin: 60px 30px 60px 0; }
      .about-wraper-1 .dz-media img {
        border-radius: 0 30px 30px 0; }
      .about-wraper-1 .dz-media:after {
        border-radius: 0 30px 30px 0; } }
  @media only screen and (max-width: 991px) {
    .about-wraper-1 .dz-media {
      margin: 30px 0 !important;
      width: 100%; }
      .about-wraper-1 .dz-media img {
        border-radius: 30px !important; }
      .about-wraper-1 .dz-media:after {
        border-radius: 30px !important;
        right: 0px !important;
        width: 100%; }
      .about-wraper-1 .dz-media.right:after {
        left: auto !important; } }

@media only screen and (max-width: 575px) {
  .about-wraper-2 .m-b50 {
    margin-bottom: 30px; } }

.about-wraper-3 {
  position: relative;
  margin-top: 50px; }
  .about-wraper-3:after {
    content: "";
    background-image: url(.././public/images/pattern/pattern11.png);
    height: 100%;
    width: 800px;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 20px; }
  @media only screen and (max-width: 1199px) {
    .about-wraper-3:after {
      width: 700px; } }
  @media only screen and (max-width: 991px) {
    .about-wraper-3:after {
      content: none; } }

@media only screen and (max-width: 991px) {
  .subscribe-wraper-1 .title {
    font-size: 32px; } }
@media only screen and (max-width: 575px) {
  .subscribe-wraper-1 .title {
    font-size: 24px; } }

.clients-logo {
  padding: 20px 30px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .clients-logo img {
    width: auto !important;
    margin: auto; }
  .clients-logo .logo-hover {
    display: none !important; }
  .clients-logo:hover {
    background: #896eff; }
    .clients-logo:hover .logo-main {
      display: none; }
    .clients-logo:hover .logo-hover {
      display: block !important; }

.video-bx {
  position: relative; }
  .video-bx.style-1 {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.2);
    border: 15px solid #fff;
    border-radius: 5px; }
    .video-bx.style-1 .video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
    .video-bx.style-1 .popup-youtube {
      height: 100px;
      width: 100px;
      line-height: 100px;
      text-align: center;
      background-image: var(--gradient);
      background-size: 200%;
      border-radius: 10px;
      display: block;
      color: #fff;
      font-size: 42px;
      margin: 0 auto 10px;
      box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.2);
      transition: all 0.5s; }
      .video-bx.style-1 .popup-youtube:hover {
        color: #896eff;
        background-color: #fff; }
    .video-bx.style-1 .title {
      color: #fff;
      margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .video-bx.style-1 {
        height: 300px; }
        .video-bx.style-1 img {
          height: 100%;
          object-fit: cover; } }
    @media only screen and (max-width: 575px) {
      .video-bx.style-1 .popup-youtube {
        height: 80px;
        width: 80px;
        line-height: 80px; }
      .video-bx.style-1 .title {
        font-size: 30px; } }
  .video-bx.style-2 .popup-youtube {
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    background-color: #896eff;
    border-radius: 50%;
    display: block;
    color: #fff;
    font-size: 32px;
    transition: all 0.5s;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9; }
    .video-bx.style-2 .popup-youtube:before, .video-bx.style-2 .popup-youtube:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      height: 90px;
      width: 90px;
      left: 0;
      top: 0;
      background-color: inherit;
      opacity: 0.5; }
    .video-bx.style-2 .popup-youtube:before {
      transform: scale(1.3);
      z-index: -1; }
    .video-bx.style-2 .popup-youtube:after {
      transform: scale(1.6);
      z-index: -2; }
  @media only screen and (max-width: 991px) {
    .video-bx.style-2 .popup-youtube {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); } }
  @media only screen and (max-width: 575px) {
    .video-bx.style-2 .popup-youtube {
      height: 70px;
      width: 70px;
      line-height: 70px;
      font-size: 24px; }
      .video-bx.style-2 .popup-youtube:before, .video-bx.style-2 .popup-youtube:after {
        height: 70px;
        width: 70px; } }
  .video-bx.style-3 {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.2);
    border-radius: 10px;
    overflow: hidden; }
    .video-bx.style-3 img {
      width: 100%; }
    .video-bx.style-3 .video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
    .video-bx.style-3 .popup-youtube {
      height: 100px;
      width: 100px;
      line-height: 100px;
      text-align: center;
      background-color: #896eff;
      border-radius: 10px;
      display: block;
      color: #fff;
      font-size: 42px;
      margin: 0 auto 10px;
      box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.2);
      transition: all 0.5s; }
      .video-bx.style-3 .popup-youtube:hover {
        color: #896eff;
        background-color: #fff; }
    @media only screen and (max-width: 767px) {
      .video-bx.style-3 {
        height: 300px; }
        .video-bx.style-3 img {
          height: 100%;
          object-fit: cover; } }
    @media only screen and (max-width: 575px) {
      .video-bx.style-3 .popup-youtube {
        height: 80px;
        width: 80px;
        line-height: 80px; } }

.video-wraper-1 {
  overflow: hidden;
  padding-top: 50px; }
  .video-wraper-1 .video-bx {
    width: 50vw;
    margin: -150px 0 0 80px; }
    .video-wraper-1 .video-bx img {
      width: 100%;
      border-radius: 10px; }
  @media only screen and (max-width: 1199px) {
    .video-wraper-1 {
      padding-top: 0; }
      .video-wraper-1 .video-bx {
        margin: 0 0 0 80px; } }
  @media only screen and (max-width: 991px) {
    .video-wraper-1 {
      padding-top: 0; }
      .video-wraper-1 .video-bx {
        margin: 0 0 30px 0;
        width: 100%; } }

@media only screen and (max-width: 991px) {
  .testimonial-wraper-1 {
    background-image: unset !important; } }

.progress-bx {
  margin-bottom: 30px; }
  .progress-bx .progress-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px; }
    .progress-bx .progress-info span {
      font-size: 18px; }
  .progress-bx .progress {
    height: 8px;
    border-radius: 10px;
    overflow: unset !important; }
    .progress-bx .progress .progress-bar {
      position: relative;
      border-radius: 10px;
      overflow: unset !important; }
      .progress-bx .progress .progress-bar:after {
        content: "";
        height: 25px;
        border: 3px solid #896eff;
        width: 25px;
        position: absolute;
        right: 0;
        background-color: #fff;
        top: 50%;
        border-radius: 50px;
        transform: translateY(-50%); }

@media only screen and (max-width: 991px) {
  .testimonials-wraper-1 {
    flex-direction: column-reverse; } }

.blog-carousel1 .dlab-blog {
  margin: 10px; }

/* Move Images */
.move-box {
  position: relative; }
  .move-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .move-box img:first-child {
      position: unset; }

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite; }

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite; }

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite; }

.move-4 {
  -webkit-animation: move4 5s infinite;
  animation: move4 5s infinite; }

@-webkit-keyframes move4 {
  0% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px); }
  50% {
    -webkit-transform: translate(0, 5px);
    transform: translate(0, 5px); }
  100% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px); } }
@keyframes move4 {
  0% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px); }
  50% {
    -webkit-transform: translate(0, 5px);
    transform: translate(0, 5px); }
  100% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px); } }
@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }
@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }
@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); } }
@keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); } }
@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0); }
  20% {
    transform: translate(5px, 0); }
  40% {
    transform: translate(5px, 5px); }
  65% {
    transform: translate(0, 5px); }
  65% {
    transform: translate(5px, 0); }
  100% {
    transform: translate(0, 0); } }
@keyframes move3 {
  0% {
    transform: translate(0, 0); }
  20% {
    transform: translate(5px, 0); }
  40% {
    transform: translate(5px, 5px); }
  65% {
    transform: translate(0, 5px); }
  65% {
    transform: translate(5px, 0); }
  100% {
    transform: translate(0, 0); } }
.icon-up [class*="icon-bx-"] i,
.icon-bx-wraper.style-5 .icon-bx,
.pricingtable-wrapper.style-2 [class*="icon-bx-"] .icon-cell,
icon-bx-wraper style-4 .icon-cell,
.icon-bx-wraper.style-1 .icon-cell {
  overflow: hidden; }

.icon-up:hover [class*="icon-bx-"] i,
.icon-bx-wraper.style-5:hover .icon-bx .icon-cell i,
.pricingtable-wrapper.style-2:hover [class*="icon-bx-"] .icon-cell i,
icon-bx-wraper style-4:hover .icon-cell i,
.icon-bx-wraper.style-1:hover .icon-cell i {
  display: inline-block;
  -webkit-animation: toTopFromBottom 0.8s forwards;
  -moz-animation: toTopFromBottom 0.8s forwards;
  animation: toTopFromBottom 0.8s forwards; }

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%); }
  51% {
    opacity: 1; } }
@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(100%); }
  51% {
    opacity: 1; } }
@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%); }
  50% {
    opacity: 0;
    transform: translateY(100%); }
  51% {
    opacity: 1; } }
.icon-wraper-1 > div:nth-child(4), .icon-wraper-1 > div:nth-child(7), .icon-wraper-1 > div:nth-child(10), .icon-wraper-1 > div:nth-child(13) {
  margin-top: -100px; }
  @media only screen and (max-width: 1199px) {
    .icon-wraper-1 > div:nth-child(4), .icon-wraper-1 > div:nth-child(7), .icon-wraper-1 > div:nth-child(10), .icon-wraper-1 > div:nth-child(13) {
      margin-top: -70px; } }
  @media only screen and (max-width: 991px) {
    .icon-wraper-1 > div:nth-child(4), .icon-wraper-1 > div:nth-child(7), .icon-wraper-1 > div:nth-child(10), .icon-wraper-1 > div:nth-child(13) {
      margin-top: 0; } }

.cf-r-img {
  min-width: 42vw;
  padding-left: 20px; }

.mfp-bg {
  z-index: 999999; }

.mfp-wrap {
  z-index: 9999999; }

.toggle-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px; }
  .toggle-tabs span {
    font-size: 16px;
    font-weight: 500; }
  .toggle-tabs .tabs-toggle-switch {
    cursor: pointer;
    margin: 0 10px;
    width: 60px;
    height: 30px;
    background: #d3cdcd;
    border-radius: 30px;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .toggle-tabs .tabs-toggle-switch:after {
      content: "";
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 30px;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
      display: block;
      left: 30px;
      position: absolute;
      box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.2); }
    .toggle-tabs .tabs-toggle-switch.active {
      background: #f8ae56; }
      .toggle-tabs .tabs-toggle-switch.active:after {
        left: 0; }
  .toggle-tabs .monthly {
    color: #636363; }
  .toggle-tabs .yearly {
    color: #f8ae56; }
  .toggle-tabs.active .monthly {
    color: #f8ae56; }
  .toggle-tabs.active .yearly {
    color: #636363; }

.counter-wraper-2:before {
  z-index: -1; }

.btn-link.style-1 {
  position: relative;
  padding: 6px 0 6px 15px; }
  .btn-link.style-1:before {
    content: "";
    position: absolute;
    height: 35px;
    width: 35px;
    left: 0;
    top: 0;
    border-radius: 50px;
    background-color: rgba(137, 110, 255, 0.1); }

.dlab-blog.style-2 {
  position: relative; }
  .dlab-blog.style-2 .post-date {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #896eff;
    border-radius: 10px;
    padding: 18px 10px;
    color: #fff;
    font-weight: 400;
    writing-mode: tb-rl; }
    .dlab-blog.style-2 .post-date span {
      font-size: 20px;
      position: relative;
      font-weight: 500;
      margin-bottom: 5px;
      padding-bottom: 10px; }
      .dlab-blog.style-2 .post-date span:after {
        content: "/";
        right: 6px;
        position: absolute;
        font-size: 14px;
        bottom: 0; }
  .dlab-blog.style-2 .post-category {
    display: inline-block; }
    .dlab-blog.style-2 .post-category a {
      text-transform: uppercase;
      position: relative;
      margin-right: 28px; }
      .dlab-blog.style-2 .post-category a:after {
        content: "//";
        right: -20px;
        position: absolute;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%); }
      .dlab-blog.style-2 .post-category a:last-child {
        margin-right: 0; }
        .dlab-blog.style-2 .post-category a:last-child:after {
          content: none; }
  .dlab-blog.style-2 .dlab-info {
    position: unset;
    padding-top: 30px; }

.testimonial-4 {
  padding: 30px;
  text-align: center;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  margin: 20px 100px;
  border-radius: 10px;
  background-color: #fff; }
  .testimonial-4.quote-right:before {
    font-family: 'Flaticon';
    top: 10px;
    content: "\f121";
    right: 30px;
    font-size: 70px;
    color: #896eff; }
  @media only screen and (max-width: 767px) {
    .testimonial-4 {
      margin: 10px; } }
  @media only screen and (max-width: 575px) {
    .testimonial-4.quote-right:before {
      right: 20px;
      font-size: 36px; } }

.testimonials-wraper-2 .testimonial-thumbs {
  width: 300px;
  margin: auto;
  box-sizing: border-box;
  height: 170px; }
  .testimonials-wraper-2 .testimonial-thumbs .swiper-slide {
    width: 100px; }
  .testimonials-wraper-2 .testimonial-thumbs .owl-item .item {
    text-align: center; }
  .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic {
    width: auto;
    height: auto;
    overflow: unset !important;
    border: 0;
    background-color: transparent;
    padding: 30px 0 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic img {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      padding: 4px;
      position: relative;
      z-index: 2;
      background-color: #896eff; }
    .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic .shape-bx {
      position: relative;
      height: 35px;
      width: 50px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      z-index: 0;
      opacity: 0;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic .shape-bx:before, .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic .shape-bx:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
      .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic .shape-bx:after {
        background-image: url(.././public/images/pattern/pattern12.png);
        background-position: bottom;
        background-size: contain;
        height: 35px;
        width: 50px; }
      .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic .shape-bx:before {
        background-color: #896eff;
        height: 35px;
        width: 50px; }
    .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic:before, .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic:after {
      content: "";
      background-color: #896eff;
      border-radius: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0; }
    .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic:before {
      height: 15px;
      width: 15px;
      bottom: 20px; }
    .testimonials-wraper-2 .testimonial-thumbs .testimonial-pic:after {
      height: 10px;
      width: 10px;
      bottom: 0; }
  .testimonials-wraper-2 .testimonial-thumbs .swiper-slide-thumb-active .testimonial-pic {
    padding: 0 0 40px; }
    .testimonials-wraper-2 .testimonial-thumbs .swiper-slide-thumb-active .testimonial-pic .shape-bx {
      opacity: 1; }
    .testimonials-wraper-2 .testimonial-thumbs .swiper-slide-thumb-active .testimonial-pic:before, .testimonials-wraper-2 .testimonial-thumbs .swiper-slide-thumb-active .testimonial-pic:after {
      opacity: 1; }
.testimonials-wraper-2 .swiper-slide {
  margin: auto;
  text-align: center; }
.testimonials-wraper-2 .testimonial-content {
  min-height: 300px;
  width: 100%; }
  .testimonials-wraper-2 .testimonial-content .swiper-wrapper {
    padding-bottom: 40px; }
  .testimonials-wraper-2 .testimonial-content .swiper-pagination-bullet-active {
    background-color: #896eff; }

/* Support and Buy Button */
.theme-btn {
  background-color: #ffffff;
  border-radius: 40px;
  bottom: 10px;
  color: #ffffff;
  display: table;
  height: 50px;
  left: 10px;
  min-width: 50px;
  position: fixed;
  text-align: center;
  z-index: 9999; }

.theme-btn i {
  font-size: 22px;
  line-height: 50px; }

.theme-btn.bt-support-now {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
  background: #1ebbf0;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ebbf0', endColorstr='#39dfaa', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  bottom: 70px; }

.theme-btn.bt-buy-now {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1fdf61+0,88BA46+100 */
  background: #1fdf61;
  /* Old browsers */
  background: -moz-linear-gradient(top, #A3D179 0%, #88BA46 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #A3D179 0%, #88BA46 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #A3D179 0%, #88BA46 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1fdf61', endColorstr='#88BA46', GradientType=0);
  /* IE6-9 */ }

.theme-btn:hover {
  color: #fff;
  padding: 0 20px; }

.theme-btn span {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: -15px;
  opacity: 0;
  line-height: 50px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  text-transform: uppercase; }

.theme-btn:hover span {
  opacity: 1;
  letter-spacing: 1px;
  padding-left: 10px; }

.at-expanding-share-button[data-position="bottom-left"] {
  bottom: 130px !important; }

.pricingtable-wraper-2 .pricingtable-wrapper {
  margin-top: 25px; }
  @media only screen and (max-width: 1199px) {
    .pricingtable-wraper-2 .pricingtable-wrapper {
      margin-top: 0; } }
  .pricingtable-wraper-2 .pricingtable-wrapper:hover, .pricingtable-wraper-2 .pricingtable-wrapper.active {
    margin-top: 0; }
    .pricingtable-wraper-2 .pricingtable-wrapper:hover .text, .pricingtable-wraper-2 .pricingtable-wrapper.active .text {
      color: #fff; }
    .pricingtable-wraper-2 .pricingtable-wrapper:hover .btn i, .pricingtable-wraper-2 .pricingtable-wrapper.active .btn i {
      background: transparent;
      color: #fff; }
  .pricingtable-wraper-2 .pricingtable-wrapper.style-1 {
    padding: 35px 60px;
    position: relative;
    z-index: 1; }

.alignleft {
  float: left;
  margin-right: 25px;
  margin-bottom: 25px; }

.alignright {
  float: right;
  margin-left: 25px;
  margin-bottom: 25px; }

.blog-single.style-1 {
  box-shadow: unset;
  border-radius: 0;
  overflow: unset;
  margin-bottom: 50px; }
  .blog-single.style-1 .dlab-meta {
    padding-top: 15px; }
.blog-single.style-2, .blog-single.style-3 {
  margin-bottom: 50px; }
  .blog-single.style-2 .meta-bottom, .blog-single.style-3 .meta-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0; }
  @media only screen and (max-width: 575px) {
    .blog-single.style-2 .meta-bottom, .blog-single.style-3 .meta-bottom {
      display: block; } }

.comments-area.style-1 .comments-title,
.comments-area.style-1 .comment-reply-title,
.comments-area.style-1 .blog-title,
.comment-respond.style-1 .comments-title,
.comment-respond.style-1 .comment-reply-title,
.comment-respond.style-1 .blog-title,
.extra-blog.style-1 .comments-title,
.extra-blog.style-1 .comment-reply-title,
.extra-blog.style-1 .blog-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 40px; }
  .comments-area.style-1 .comments-title:before,
  .comments-area.style-1 .comment-reply-title:before,
  .comments-area.style-1 .blog-title:before,
  .comment-respond.style-1 .comments-title:before,
  .comment-respond.style-1 .comment-reply-title:before,
  .comment-respond.style-1 .blog-title:before,
  .extra-blog.style-1 .comments-title:before,
  .extra-blog.style-1 .comment-reply-title:before,
  .extra-blog.style-1 .blog-title:before {
    content: "";
    height: 100%;
    width: 5px;
    border-radius: 10px;
    background-color: #896eff;
    position: absolute;
    left: 0;
    top: 0; }

.extra-blog {
  margin-bottom: 30px; }

.comments-area .comment-list {
  margin-bottom: 50px; }
  .comments-area .comment-list > .comment .comment-body {
    position: relative;
    padding: 0 0 0 110px;
    margin-bottom: 30px; }
    .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
      position: absolute;
      left: 0;
      height: 95px;
      width: 95px; }
    .comments-area .comment-list > .comment .comment-body .comment-author .fn {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      line-height: 18px;
      color: #1f2471;
      font-weight: 700;
      font-style: normal;
      margin-bottom: 10px;
      display: block; }
    .comments-area .comment-list > .comment .comment-body p {
      font-size: 15px;
      margin-bottom: 10px; }
    .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px; }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
        margin-right: 8px; }
  .comments-area .comment-list > .comment > .children {
    padding-left: 50px; }
.comments-area.style-1 .comment-list > .comment {
  border-bottom: 1px solid #E1E1F0;
  margin-bottom: 30px; }
  .comments-area.style-1 .comment-list > .comment .comment-body .comment-author .avatar {
    border-radius: 10px; }
  .comments-area.style-1 .comment-list > .comment:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
.comments-area.style-2 .comment-list > .comment {
  border-bottom: 1px solid #E1E1F0;
  margin-bottom: 30px; }
  .comments-area.style-2 .comment-list > .comment .comment-body .comment-author .avatar {
    border-radius: 50%;
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
    border: 5px solid #fff; }
  .comments-area.style-2 .comment-list > .comment:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
.comments-area.style-3 .comment-list > .comment {
  margin-bottom: 30px; }
  .comments-area.style-3 .comment-list > .comment .comment-body .comment-author .avatar {
    border-radius: 5px;
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
    border: 5px solid #fff; }
  .comments-area.style-3 .comment-list > .comment:last-child {
    margin-bottom: 0; }

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  .comment-respond .comment-form p {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px; }
    .comment-respond .comment-form p label {
      display: none; }
    .comment-respond .comment-form p.comment-form-author input, .comment-respond .comment-form p.comment-form-email input, .comment-respond .comment-form p.comment-form-url input,
    .comment-respond .comment-form p textarea {
      width: 100%;
      box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
      height: 45px;
      border-radius: 30px !important;
      padding: 10px 20px;
      position: relative;
      font-size: 15px;
      border: 1px solid;
      border-color: #fff; }
    .comment-respond .comment-form p .textarea {
      height: 120px !important; }
  .comment-respond .comment-form .comment-form-comment {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
.comment-respond.style-1 .comment-form .comment-form-author,
.comment-respond.style-1 .comment-form .comment-form-email,
.comment-respond.style-1 .comment-form .comment-form-url, .comment-respond.style-3 .comment-form .comment-form-author,
.comment-respond.style-3 .comment-form .comment-form-email,
.comment-respond.style-3 .comment-form .comment-form-url {
  width: 33.33%; }
.comment-respond.style-2 .comment-form .comment-form-author,
.comment-respond.style-2 .comment-form .comment-form-email {
  width: 50%; }
.comment-respond.style-2 .comment-form .comment-form-url {
  width: 100%; }
.comment-respond.style-3 .comment-form .comment-form-author input,
.comment-respond.style-3 .comment-form .comment-form-email input,
.comment-respond.style-3 .comment-form .comment-form-url input,
.comment-respond.style-3 .comment-form textarea {
  border-radius: 0 15px 0px 15px !important; }
@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important; } }

/* Error Page */
.error-page {
  padding: 150px 0; }
  .error-page .dlab_error {
    font-size: 190px;
    font-weight: 700;
    line-height: 160px;
    margin: auto;
    color: #896eff;
    opacity: 0.2; }
  .error-page .error-head {
    font-size: 30px;
    font-weight: 500;
    margin: 15px 0 25px;
    max-width: 600px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    color: #000; }
  @media only screen and (max-width: 575px) {
    .error-page {
      padding: 50px 0; }
      .error-page .dlab_error {
        font-size: 120px;
        line-height: 120px; }
      .error-page .error-head {
        font-size: 24px;
        line-height: 34px; } }

/* Coming Soon */
.countdown {
  margin-bottom: 40px; }
  .countdown .date {
    width: 120px;
    display: inline-block;
    color: #000;
    padding: 0 10px;
    position: relative; }
    .countdown .date:after {
      content: ":";
      position: absolute;
      font-size: 50px;
      right: -8px;
      top: 2px;
      line-height: 60px;
      color: #fff; }
    .countdown .date:last-child:after {
      content: none; }
    .countdown .date span:last-child {
      color: #fff;
      height: auto;
      width: auto;
      display: block;
      background: transparent;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px; }
    .countdown .date .time {
      font-size: 70px;
      color: #fff;
      line-height: 70px;
      display: block;
      width: auto;
      height: auto;
      background: transparent;
      margin: 0;
      font-weight: 600; }
  @media only screen and (max-width: 575px) {
    .countdown {
      margin-bottom: 20px; }
      .countdown .date {
        width: 75px;
        padding: 0 5px; }
        .countdown .date:after {
          font-size: 36px;
          line-height: 36px; }
        .countdown .date span:last-child {
          font-size: 10px; }
        .countdown .date .time {
          font-size: 36px;
          line-height: 36px; } }

.coming-soon .cs-title {
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 15px;
  opacity: 1;
  text-transform: uppercase;
  letter-spacing: 2px; }
.coming-soon .cs-logo {
  margin-bottom: 20px; }
  .coming-soon .cs-logo .logo {
    width: 250px;
    display: inline-block; }
@media only screen and (max-width: 575px) {
  .coming-soon .cs-title {
    font-size: 36px;
    line-height: 50px; } }

.countdown-social {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%; }
  .countdown-social li {
    display: inline-block;
    margin: 0 2px; }
    .countdown-social li a {
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 50px;
      font-size: 18px;
      line-height: 50px;
      color: #000; }
      .countdown-social li a:hover {
        background: #896eff;
        color: #fff; }
  @media only screen and (max-width: 575px) {
    .countdown-social li a {
      width: 40px;
      height: 40px;
      font-size: 14px;
      line-height: 40px; } }

.page-down,
.coming-soon {
  height: 100vh;
  display: table;
  width: 100%; }

.page-down .container-fluid,
.coming-soon .container {
  display: table-cell;
  vertical-align: middle; }

.pagedown-title {
  padding-left: 165px;
  position: relative;
  margin-left: 50px; }
  .pagedown-title h1 {
    margin: 0;
    font-family: Roboto;
    font-weight: 500;
    color: #fff;
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 15px; }
  .pagedown-title img {
    position: absolute;
    left: -10px;
    top: 10px;
    width: 160px; }
  .pagedown-title p {
    margin: 0;
    font-family: Roboto;
    font-weight: 300;
    color: #fff;
    font-size: 28px;
    line-height: 40px;
    opacity: 0.8; }
  @media only screen and (max-width: 991px) {
    .pagedown-title h1 {
      font-size: 60px; } }
  @media only screen and (max-width: 767px) {
    .pagedown-title h1 {
      font-size: 42px;
      line-height: 54px; }
    .pagedown-title p {
      color: #fff;
      font-size: 21px;
      line-height: 32px; }
    .pagedown-title .pagedown-title img {
      width: 140px; } }
  @media only screen and (max-width: 575px) {
    .pagedown-title {
      padding-left: 100px;
      margin-left: 20px; }
      .pagedown-title img {
        width: 90px; }
      .pagedown-title h1 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 10px; }
      .pagedown-title p {
        font-size: 18px;
        line-height: 28px; } }

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #EFBB20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 150px;
  height: 85px;
  position: relative;
  z-index: 9; }
  @media only screen and (max-width: 1199px) {
    .logo-header {
      width: 130px; } }
  @media only screen and (max-width: 575px) {
    .logo-header {
      width: 120px;
      height: 75px; } }
  .logo-header a {
    display: table-cell;
    vertical-align: middle; }
  .logo-header img {
    height: auto;
    width: 100%; }
  .logo-header span {
    font-size: 20px;
    letter-spacing: 20px; }

.top-bar {
  background-color: #fff;
  color: #262F5A;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .top-bar .dlab-topbar-left {
    float: left; }
    .top-bar .dlab-topbar-left li {
      padding-right: 20px; }
  .top-bar .dlab-topbar-right {
    float: right; }
    .top-bar .dlab-topbar-right li {
      padding-left: 15px; }
  .top-bar .dlab-topbar-center,
  .top-bar .dlab-topbar-left,
  .top-bar .dlab-topbar-right {
    padding-left: 15px;
    padding-right: 15px; }
    .top-bar .dlab-topbar-center ul,
    .top-bar .dlab-topbar-left ul,
    .top-bar .dlab-topbar-right ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .top-bar .dlab-topbar-center ul li,
      .top-bar .dlab-topbar-left ul li,
      .top-bar .dlab-topbar-right ul li {
        display: inline-block;
        font-size: 14px; }
      .top-bar .dlab-topbar-center ul i,
      .top-bar .dlab-topbar-left ul i,
      .top-bar .dlab-topbar-right ul i {
        color: #896eff;
        transform: scale(1.3);
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        margin-right: 5px; }
    .top-bar .dlab-topbar-center .dlab-social-icon li,
    .top-bar .dlab-topbar-left .dlab-social-icon li,
    .top-bar .dlab-topbar-right .dlab-social-icon li {
      padding-left: 0; }
  @media only screen and (max-width: 767px) {
    .top-bar {
      display: none; } }

.dlab-quik-search {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none; }
  .dlab-quik-search form {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: 50%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 1; }
  .dlab-quik-search .form-control {
    padding: 15px 60px 15px 15px;
    width: 100%;
    height: 90px;
    border: none;
    background: 0 0;
    color: #fff;
    font-size: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2); }
  .dlab-quik-search span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer; }

.site-header {
  position: relative;
  z-index: 9999; }
  .site-header .main-bar {
    background: #fff;
    width: 100%;
    position: relative; }
  .site-header .container {
    position: relative; }
  .site-header .extra-nav {
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    margin-left: 20px;
    height: 85px; }
    .site-header .extra-nav #quik-search-btn {
      color: #020d26;
      cursor: pointer;
      margin-right: 0px;
      border: 0; }
    @media only screen and (max-width: 575px) {
      .site-header .extra-nav .btn {
        padding-left: 15px;
        padding-right: 15px; } }
    .site-header .extra-nav ul li {
      display: inline-block; }
      .site-header .extra-nav ul li .icon {
        padding: 0;
        background: transparent;
        min-width: 40px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border-radius: 4px; }
    @media only screen and (max-width: 575px) {
      .site-header .extra-nav {
        display: none; } }

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 34px 0 32px 15px;
  padding: 0;
  float: right;
  outline: none !important; }
  @media only screen and (max-width: 575px) {
    .navbar-toggler {
      margin: 28px 0 28px 15px; } }

.navicon {
  width: 20px;
  height: 18px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  .navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: #000; }
    .navicon span:nth-child(1) {
      top: 0px; }
    .navicon span:nth-child(2) {
      top: 7px; }
    .navicon span:nth-child(3) {
      top: 14px; }
  .navicon.open span:nth-child(1) {
    top: 7px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .navicon.open span:nth-child(3) {
    top: 7px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg); }

.header-tb .navicon span {
  background: #fff; }

.header-nav {
  padding: 0; }
  @media only screen and (max-width: 991px) {
    .header-nav {
      overflow-y: scroll;
      position: fixed;
      width: 60px;
      left: -280px;
      height: 100vh !important;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
      top: 0;
      background-color: #fff;
      margin: 0;
      z-index: 99; } }
  @media only screen and (max-width: 991px) {
    .header-nav.show {
      left: -1px;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      margin: 0;
      width: 280px; } }
  .header-nav .logo-header {
    display: none; }
    @media only screen and (max-width: 991px) {
      .header-nav .logo-header {
        padding: 30px 20px;
        float: none;
        height: auto;
        width: 240px; } }
  .header-nav .nav {
    float: right;
    padding: 0; }
    .header-nav .nav > li {
      margin: 0px;
      font-weight: 400;
      position: relative; }
      .header-nav .nav > li.has-mega-menu {
        position: inherit; }
      @media only screen and (max-width: 991px) {
        .header-nav .nav > li {
          border-bottom: 1px solid #eee;
          width: 100%; } }
      .header-nav .nav > li > a {
        color: #262F5A;
        font-size: 15px;
        padding: 35px 15px;
        cursor: pointer;
        font-weight: 600;
        display: inline-block;
        position: relative;
        border-width: 0 0 0 1px;
        text-transform: capitalize;
        line-height: 1; }
        @media only screen and (max-width: 1199px) {
          .header-nav .nav > li > a {
            padding: 35px 12px; } }
        .header-nav .nav > li > a span {
          display: inline-block; }
        .header-nav .nav > li > a i {
          font-size: 9px;
          margin-left: 3px;
          margin-top: -3px;
          vertical-align: middle; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li > a i {
              background-color: #896eff;
              color: #fff;
              height: 30px;
              line-height: 30px;
              right: 0;
              text-align: center;
              width: 30px;
              z-index: 3;
              float: right;
              font-size: 20px;
              margin: 0; } }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li > a i:before {
              content: "\f105"; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li > a {
            padding: 8px 0;
            display: block;
            line-height: 30px; } }
      @media only screen and (min-width: 991px) {
        .header-nav .nav > li:hover > a span {
          -webkit-animation: upToDown 0.3s forwards;
          -moz-animation: upToDown 0.3s forwards;
          animation: upToDown 0.3s forwards; } }
      .header-nav .nav > li .mega-menu,
      .header-nav .nav > li .sub-menu {
        background-color: #fff;
        display: block;
        left: 0;
        list-style: none;
        opacity: 0;
        padding: 5px 0;
        position: absolute;
        visibility: hidden;
        width: 220px;
        z-index: 10;
        margin-top: 20px;
        box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu,
          .header-nav .nav > li .sub-menu {
            display: none;
            position: static;
            visibility: visible;
            width: auto;
            background: transparent;
            box-shadow: none;
            margin: 0;
            border-top: 1px solid #eee;
            padding: 10px 0;
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; }
            .header-nav .nav > li .mega-menu > li > a i,
            .header-nav .nav > li .sub-menu > li > a i {
              color: #fff !important;
              height: 30px;
              line-height: 30px;
              right: 0;
              text-align: center;
              width: 30px;
              z-index: 3;
              font-size: 20px !important;
              margin: 0 !important;
              background: #aaa; } }
        .header-nav .nav > li .mega-menu li,
        .header-nav .nav > li .sub-menu li {
          position: relative; }
          .header-nav .nav > li .mega-menu li a,
          .header-nav .nav > li .sub-menu li a {
            color: #262F5A;
            display: block;
            font-size: 15px;
            padding: 10px 25px;
            text-transform: capitalize;
            position: relative;
            transition: all 0.15s linear;
            -webkit-transition: all 0.15s linear;
            -moz-transition: all 0.15s linear;
            -o-transition: all 0.15s linear;
            font-weight: 500; }
            .header-nav .nav > li .mega-menu li a:after,
            .header-nav .nav > li .sub-menu li a:after {
              content: "";
              position: absolute;
              width: 0;
              height: 1px;
              background: #896eff;
              left: 20px;
              top: 20px;
              transition: all .5s;
              -moz-transition: all .5s;
              -webkit-transition: all .5s;
              -ms-transition: all .5s;
              -o-transition: all .5s; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li a,
              .header-nav .nav > li .sub-menu li a {
                padding: 2px 0 1px 15px;
                display: block;
                line-height: 30px;
                position: relative; }
                .header-nav .nav > li .mega-menu li a:after,
                .header-nav .nav > li .sub-menu li a:after {
                  content: "\f105";
                  font-family: FontAwesome;
                  width: auto;
                  height: auto;
                  background: 0 0 !important;
                  top: 1px;
                  color: #262F5A;
                  font-size: 16px;
                  position: absolute;
                  left: 0;
                  font-weight: 700; } }
          .header-nav .nav > li .mega-menu li:hover > a,
          .header-nav .nav > li .sub-menu li:hover > a {
            background-color: #F2F2F2;
            padding: 10px 20px 10px 35px;
            color: #896eff; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li:hover > a,
              .header-nav .nav > li .sub-menu li:hover > a {
                padding: 2px 0 1px 15px;
                background: transparent; } }
            .header-nav .nav > li .mega-menu li:hover > a:after,
            .header-nav .nav > li .sub-menu li:hover > a:after {
              width: 10px; }
        .header-nav .nav > li .mega-menu li > .sub-menu,
        .header-nav .nav > li .sub-menu li > .sub-menu {
          left: 220px;
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu li > .sub-menu,
            .header-nav .nav > li .sub-menu li > .sub-menu {
              display: none; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu .mega-menu,
          .header-nav .nav > li .mega-menu .sub-menu,
          .header-nav .nav > li .sub-menu .mega-menu,
          .header-nav .nav > li .sub-menu .sub-menu {
            border-top: 0;
            padding-left: 20px; }
            .header-nav .nav > li .mega-menu .mega-menu li a:after,
            .header-nav .nav > li .mega-menu .sub-menu li a:after,
            .header-nav .nav > li .sub-menu .mega-menu li a:after,
            .header-nav .nav > li .sub-menu .sub-menu li a:after {
              width: 5px;
              content: "";
              height: 5px;
              background: #262F5A !important;
              border-radius: 4px;
              top: 13px; } }
      .header-nav .nav > li .sub-menu li .fa {
        color: inherit;
        display: block;
        float: right;
        font-size: 16px;
        opacity: 1;
        margin-top: 4px; }
      .header-nav .nav > li .mega-menu {
        display: flex;
        right: 0px;
        width: 100%;
        z-index: 9;
        padding: 0; }
        .header-nav .nav > li .mega-menu > li {
          display: block;
          padding: 30px 0 25px;
          position: relative;
          vertical-align: top;
          width: 25%; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu > li {
              width: 100%;
              padding: 0; } }
          .header-nav .nav > li .mega-menu > li > a {
            color: #262F5A;
            display: block;
            padding: 0 20px;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 15px; }
            .header-nav .nav > li .mega-menu > li > a > i {
              font-size: 14px;
              margin-right: 5px;
              text-align: center;
              width: 15px; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li > a {
                display: none; } }
          .header-nav .nav > li .mega-menu > li:after {
            content: "";
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            right: 0px;
            top: 0px;
            display: block;
            width: 1px;
            height: 100%; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li:after {
                content: none; } }
          .header-nav .nav > li .mega-menu > li:last-child:after {
            display: none; }
          .header-nav .nav > li .mega-menu > li:hover > a {
            background-color: transparent; }
          .header-nav .nav > li .mega-menu > li li i {
            font-size: 14px;
            margin-right: 5px;
            text-align: center;
            width: 15px; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu {
            display: none;
            padding: 10px 0; } }
      .header-nav .nav > li:hover > .mega-menu, .header-nav .nav > li:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li:hover > .mega-menu, .header-nav .nav > li:hover > .sub-menu {
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; } }
        .header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu, .header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
          top: -1px; }
      .header-nav .nav > li.open .mega-menu,
      .header-nav .nav > li.open .sub-menu {
        display: block;
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
      .header-nav .nav > li.open .open.menu-item-has-children > a:before {
        transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg); }
      .header-nav .nav > li.open .open .mega-menu,
      .header-nav .nav > li.open .open .sub-menu {
        display: block;
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
    @media only screen and (max-width: 1400px) {
      .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu, .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu, .header-nav .nav > li:last-child .sub-menu .sub-menu {
        left: -220px; } }
    @media only screen and (max-width: 1199px) {
      .header-nav .nav > li:last-child .sub-menu {
        left: auto;
        right: 0; } }
    @media only screen and (max-width: 991px) {
      .header-nav .nav {
        float: none;
        padding: 0 20px; } }
  .header-nav .dlab-social-icon {
    display: none;
    text-align: center; }
    .header-nav .dlab-social-icon li {
      display: inline-block;
      margin: 0 2px; }
    .header-nav .dlab-social-icon a {
      width: 40px;
      height: 40px;
      padding: 0;
      border: 1px solid #eee;
      line-height: 38px;
      text-align: center;
      border-radius: 40px; }
    @media only screen and (max-width: 991px) {
      .header-nav .dlab-social-icon {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px; } }

.header-transparent {
  position: absolute;
  width: 100%; }
  .header-transparent a:not([href]):not([tabindex]):hover, .header-transparent a:not([href]):not([tabindex]):focus {
    color: #fff; }
  .header-transparent .is-fixed a:not([href]):not([tabindex]):hover, .header-transparent .is-fixed a:not([href]):not([tabindex]):focus, .header-transparent.text-black a:not([href]):not([tabindex]):hover, .header-transparent.text-black a:not([href]):not([tabindex]):focus {
    color: inherit; }
  .header-transparent .top-bar,
  .header-transparent .main-bar {
    background: transparent; }
  .header-transparent .container-fluid {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (max-width: 1199px) {
      .header-transparent .container-fluid {
        padding-left: 15px;
        padding-right: 15px; } }
  .header-transparent .side-menu-btn .menu-icon-in span {
    background-color: #fff; }
  .header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
    background-color: #262F5A; }
  @media only screen and (min-width: 991px) {
    .header-transparent .nav > li > a {
      color: #fff; } }
  .header-transparent .navbar-toggler span {
    background: #fff; }
  .header-transparent .is-fixed .nav > li > a {
    color: #262F5A; }
  .header-transparent .is-fixed .navbar-toggler span {
    background: #262F5A; }
  .header-transparent .top-bar {
    border-color: #fff; }
    .header-transparent .top-bar .dlab-topbar-left li,
    .header-transparent .top-bar .dlab-topbar-left i,
    .header-transparent .top-bar .dlab-topbar-right li,
    .header-transparent .top-bar .dlab-topbar-right i {
      color: #fff; }

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  background: white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); }

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99; }
    .mo-left .header-nav.nav-dark {
      background-color: #202020; }
    .mo-left .header-nav.show {
      left: -1px;
      transition: all 0.8s;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      -o-transition: all 0.8s;
      margin: 0;
      width: 280px; }
    .mo-left .header-nav .logo-header {
      display: block;
      float: none;
      height: auto !important;
      max-width: 100%;
      padding: 25px 20px;
      width: 100%; }
      .mo-left .header-nav .logo-header .light-logo {
        display: none !important; }
      .mo-left .header-nav .logo-header .dark-logo {
        display: table-cell !important; }
      .mo-left .header-nav .logo-header img {
        max-width: unset;
        width: 160px;
        vertical-align: middle; }
    .mo-left .header-nav li.open a {
      position: relative; }
  .mo-left .navbar-toggler.open {
    z-index: 99; }
    .mo-left .navbar-toggler.open:after {
      background-color: rgba(0, 0, 0, 0.6);
      content: "";
      left: 0;
      position: fixed;
      right: -20px;
      top: -63px;
      transform: scale(100);
      -o-transform: scale(100);
      -moz-transform: scale(100);
      -webkit-transform: scale(100);
      width: 100%;
      z-index: -1;
      transition: all 0.5s;
      transform-origin: top right;
      margin: 0 0px 0px 20px;
      -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6); }
    .mo-left .navbar-toggler.open span {
      background: #fff; } }
.text-black .nav > li > a {
  color: #262F5A; }
.text-black .navbar-toggler span {
  background: #262F5A; }

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }
@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%); }
  51% {
    opacity: 1; } }
@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  51% {
    opacity: 1; } }
/* Logo */
.site-header .main-bar-wraper .logo-header .dark-logo {
  display: table-cell; }
.site-header .main-bar-wraper .logo-header .light-logo {
  display: none; }
.site-header .main-bar-wraper.is-fixed .logo-header .dark-logo {
  display: table-cell; }
.site-header.header-transparent .main-bar-wraper .logo-header .dark-logo {
  display: none; }
.site-header.header-transparent .main-bar-wraper .logo-header .light-logo {
  display: table-cell; }
.site-header.header-transparent .main-bar-wraper.is-fixed .logo-header .light-logo {
  display: none; }
.site-header.header-transparent .main-bar-wraper.is-fixed .logo-header .dark-logo {
  display: table-cell; }
.site-header.text-black .main-bar-wraper .logo-header .dark-logo {
  display: table-cell; }
.site-header.text-black .main-bar-wraper .logo-header .light-logo {
  display: none; }
.site-header.text-black .main-bar-wraper.is-fixed .logo-header .dark-logo {
  display: table-cell; }

.site-header .is-fixed .extra-nav .btn.btn-light {
  background-color: #896eff;
  color: #fff; }

.dlab-bnr-inr {
  background: #faf8f2;
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 991px) {
    .dlab-bnr-inr {
      height: 300px; } }
  .dlab-bnr-inr .container {
    display: table;
    height: 100%; }
  .dlab-bnr-inr .dlab-bnr-inr-entry {
    height: 450px;
    vertical-align: middle;
    display: table-cell;
    padding-top: 30px; }
    @media only screen and (max-width: 991px) {
      .dlab-bnr-inr .dlab-bnr-inr-entry {
        height: 300px; } }
    @media only screen and (max-width: 767px) {
      .dlab-bnr-inr .dlab-bnr-inr-entry {
        text-align: center; }
        .dlab-bnr-inr .dlab-bnr-inr-entry .breadcrumb-row {
          display: inline-block; } }
    .dlab-bnr-inr .dlab-bnr-inr-entry h1 {
      margin-bottom: 20px;
      font-size: 60px;
      line-height: 75px; }
  .dlab-bnr-inr h1 {
    font-weight: 700;
    font-size: 60px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: capitalize; }
    @media only screen and (max-width: 991px) {
      .dlab-bnr-inr h1 {
        font-size: 42px; } }

.breadcrumb-row ul {
  background: transparent;
  padding: 0; }
  .breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    color: #fff; }
    .breadcrumb-row ul li.active {
      color: #fff; }
    .breadcrumb-row ul li a {
      color: #fff; }
  .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
    content: "\f054";
    color: #fff;
    font-family: fontawesome;
    font-size: 14px; }
.breadcrumb-row.style-1 .breadcrumb {
  background-image: var(--gradient);
  background-size: 200%;
  padding: 8px 50px;
  border-radius: 30px;
  display: inline-block; }

.dlab-bnr-inr.style-1 {
  height: 480px; }
  .dlab-bnr-inr.style-1:after {
    content: "";
    background-image: url(../public/images/banner/bnr4.png);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat; }
  .dlab-bnr-inr.style-1 .dlab-bnr-inr-entry {
    text-align: center;
    height: 480px;
    z-index: 99;
    position: relative; }
    .dlab-bnr-inr.style-1 .dlab-bnr-inr-entry h1 {
      margin-bottom: 20px;
      font-size: 60px;
      line-height: 75px; }
  @media only screen and (max-width: 575px) {
    .dlab-bnr-inr.style-1 {
      height: 300px; }
      .dlab-bnr-inr.style-1 .dlab-bnr-inr-entry {
        height: 300px; } }
.dlab-bnr-inr.style-2 {
  height: 600px; }
  .dlab-bnr-inr.style-2:after {
    content: "";
    background-image: url(../public/images/banner/bnr5.png);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain; }
  .dlab-bnr-inr.style-2 .dlab-bnr-inr-entry {
    text-align: center;
    height: 600px; }
  .dlab-bnr-inr.style-2 .breadcrumb {
    justify-content: center; }
  @media only screen and (max-width: 767px) {
    .dlab-bnr-inr.style-2 {
      height: 300px; }
      .dlab-bnr-inr.style-2 .dlab-bnr-inr-entry {
        height: 300px; } }

.page-title {
  padding: 30px 0; }
  .page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px; }
  .page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px; }
    .page-title .page-title-content p span {
      font-weight: 500;
      color: #896eff; }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 575px) {
    .breadcrumbs {
      justify-content: flex-start; } }
  .breadcrumbs li a {
    color: #AEAED5;
    display: inline-block;
    margin-left: 15px;
    font-size: 14px; }
  .breadcrumbs li:first-child a {
    margin-left: 0px; }
  .breadcrumbs li.active a {
    color: #896eff; }

.bottom {
  background: #1b2a4e; }
  @media only screen and (max-width: 575px) {
    .bottom {
      padding-bottom: 0; } }
  .bottom .bottom-logo p {
    line-height: 30px;
    font-weight: 400;
    color: #bbbbbb; }
  @media only screen and (max-width: 991px) {
    .bottom .bottom-widget {
      margin-bottom: 30px; } }
  .bottom .bottom-widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase; }
  .bottom .bottom-widget ul li a {
    color: #bbbbbb;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block; }

footer {
  color: #fff;
  font-size: 15px; }
  footer .widget {
    margin-bottom: 30px; }
  footer strong {
    color: #fff; }
  footer .footer-logo {
    margin-bottom: 25px; }
    footer .footer-logo img {
      max-width: 100%; }
  footer .widget-logo ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    footer .widget-logo ul li {
      display: inline-block;
      flex: 50%;
      padding: 0 15px 15px 0px; }
  footer .widget_about p {
    margin-bottom: 20px;
    line-height: 24px; }
  footer .footer-title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
    color: #fff;
    line-height: 1.2; }
    footer .footer-title:before, footer .footer-title:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      border-radius: 20px;
      background-color: #fff; }
    footer .footer-title:before {
      width: 15px; }
    footer .footer-title:after {
      width: 45px;
      left: 20px; }
  footer .footer-top {
    background: #896eff;
    padding: 70px 0 30px; }
    @media only screen and (max-width: 767px) {
      footer .footer-top {
        padding: 50px 0 20px; } }
  footer .footer-bottom {
    background-color: #b3a1ff;
    font-size: 15px;
    padding: 15px 0; }
    footer .footer-bottom .copyright-text a {
      color: #fff !important;
      font-weight: 500; }
    @media only screen and (max-width: 767px) {
      footer .footer-bottom .text-left,
      footer .footer-bottom .text-right {
        text-align: center !important; }
      footer .footer-bottom .text-right {
        margin-top: 10px; } }
  footer .widget-link li {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 20px; }
    @media only screen and (max-width: 767px) {
      footer .widget-link li {
        margin-left: 8px;
        margin-right: 7px; } }
  footer .widget-link a {
    color: #fff; }
  footer .widget_services ul li a {
    position: relative;
    color: #fff;
    padding: 5px 0 5px 0;
    display: block;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    footer .widget_services ul li a:hover {
      padding: 5px 0 5px 10px; }
  footer .widget_services.style-1 ul li a {
    padding: 5px 0 5px 15px; }
    footer .widget_services.style-1 ul li a:before {
      content: "\f105";
      font-family: fontawesome;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff !important; }
  footer .widget_getintuch ul li {
    position: relative;
    padding: 8px 0;
    display: flex;
    align-items: center; }
    footer .widget_getintuch ul li i {
      height: 40px;
      width: 40px;
      min-width: 40px;
      line-height: 40px;
      margin-right: 10px;
      text-align: center;
      background-color: #896eff;
      display: block;
      border-radius: 50px; }

.footer-info {
  border-bottom: 1px solid #E1E1F0;
  margin-bottom: 30px;
  padding-bottom: 10px; }
  .footer-info .footer-logo {
    margin-bottom: 10px; }
  .footer-info .icon-bx-wraper {
    display: flex;
    align-items: center; }
    .footer-info .icon-bx-wraper .icon-cell {
      color: #fff; }
      .footer-info .icon-bx-wraper .icon-cell i {
        font-size: 50px; }
    .footer-info .icon-bx-wraper p {
      font-size: 18px; }
  @media only screen and (max-width: 991px) {
    .footer-info .footer-info .icon-bx-wraper p {
      font-size: 16px; }
    .footer-info .footer-info .footer-logo {
      margin-bottom: 20px; } }

.contact-info-bx li {
  padding-bottom: 10px;
  position: relative;
  padding-left: 30px;
  padding-top: 2px; }
  .contact-info-bx li strong {
    font-weight: 400; }
.contact-info-bx i {
  width: auto;
  height: 22px;
  color: #fff;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 22px; }

.site-footer.style-1 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #896eff; }
  .site-footer.style-1 .footer-top {
    background: transparent; }
    .site-footer.style-1 .footer-top .widget_services {
      padding-left: 100px; }
  @media only screen and (max-width: 1199px) {
    .site-footer.style-1 .footer-top .widget_services {
      padding-left: 0; } }

.site-footer.style-2 {
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: auto !important;
  background-color: #0e1b1f;
  background-size: contain; }
  .site-footer.style-2:before {
    content: "";
    background-image: url(../public/images/pattern/pattern2.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .site-footer.style-2 .footer-top {
    background: transparent;
    padding-top: 300px; }
    .site-footer.style-2 .footer-top .widget_services {
      padding-left: 100px; }
  .site-footer.style-2 .container + .footer-top {
    padding-top: 70px; }
  .site-footer.style-2 .footer-bottom {
    background-color: #1f343b;
    padding: 10px 0; }
  @media only screen and (max-width: 1199px) {
    .site-footer.style-2 .footer-top .widget_services {
      padding-left: 0; } }

.site-footer.style-3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #896eff; }
  .site-footer.style-3 .footer-top {
    background: transparent; }


