// Widget
.widget {
    margin-bottom: 40px;

    .widget-title {
        color        : $secondary;
        position     : relative;
        padding-left : 20px;
        margin-bottom: 20px;
        font-size    : 30px;

        &:before {
            content         : "";
            height          : 100%;
            width           : 5px;
            border-radius   : 10px;
            background-color: $primary;
            position        : absolute;
            left            : 0;
            top             : 0;
        }
    }

    &.style-1 {
        .widget-title {
            padding      : 0 0 12px;
            margin-bottom: 30px;

            &:after,
            &:before {
                content         : "";
                height          : 6px;
                border-radius   : 10px;
                background-image: var(--gradient);
                background-size : 200%;
                position        : absolute;
                bottom          : 0;
            }

            &:before {
                width: 25px;
                left : 0;
                top  : auto;
            }

            &:after {
                width: 55px;
                left : 32px;
            }
        }
    }
}

// widget_archive
.widget_archive {
    ul {
        li {
            a {
                display        : flex;
                padding        : 8px 8px 8px 25px;
                color          : $secondary;
                transition     : all .5s;
                align-items    : center;
                justify-content: space-between;
                border-radius  : 50px;

                span {
                    font-size       : 15px;
                    background-color: $primary-light;
                    color           : $secondary;
                    padding         : 4px 15px;
                    border-radius   : 20px;
                    transition      : all .5s;
                }

                &:hover {
                    box-shadow: 0px 0 10px rgba(31, 66, 135, 0.2);
                    color     : $primary;

                    span {
                        background-image: var(--gradient);
                        background-size : 200%;
                        color           : $white;
                    }
                }
            }
        }
    }

    &.style-1 {
        ul li a {
            border-radius: 0 20px 0 20px;
        }
    }
}

// widget_tag_cloud
.widget_tag_cloud {
    .tagcloud {
        clear  : both;
        display: table;

        a {
            position     : relative;
            border       : 1px solid;
            border-color : $secondary;
            border-radius: 20px;
            padding      : 3px 15px 3px 35px;
            display      : inline-block;
            margin       : 0 10px 15px 0;
            font-size    : 15px;
            color        : $secondary;

            &:before {
                content         : "\f00c";
                font-family     : 'Line Awesome Free';
                height          : 24px;
                width           : 24px;
                font-size       : 12px;
                line-height     : 24px;
                font-weight     : 600;
                border-radius   : 50px;
                background-color: $secondary;
                position        : absolute;
                left            : 3px;
                top             : 3px;
                color           : $secondary;
                text-align      : center;
                transition      : all 0.5s;
            }

            &:hover {
                &:before {
                    background-color: $primary;
                    color           : $white;
                }
            }
        }
    }
}

// recent-posts-entry
.recent-posts-entry {
    .widget-post-bx {
        .widget-post {
            display      : flex;
            align-items  : center;
            padding      : 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            transition   : all 0.5s;

            .dlab-media {
                width        : 130px;
                border-radius: 8px;
            }

            .dlab-info {
                padding-left: 15px;
            }

            .dlab-meta {
                li {
                    font-size: 14px;
                    color    : $secondary;
                }
            }

            .title {
                font-weight: 600;
                font-size  : 18px;
            }

            &:hover {
                box-shadow: 0px 0 10px rgba(31, 66, 135, 0.2);
            }
        }
    }

    &.style-1 {
        .widget-post-bx {
            .widget-post {
                border-radius: 0 20px 0 20px;
            }
        }
    }
}

// search-bx
.search-bx {
    .form-control {
        padding: 10px 100px 10px 50px;
    }

    .input-group-prepend {
        border-right: 0;

        .input-group-text {
            i {
                color: #828282;
            }
        }
    }

    button {
        position     : absolute;
        right        : 0;
        height       : 45px;
        border-radius: 30px;
        top          : 0;
        font-size    : 30px;
        padding      : 0px 30px;
        z-index      : 99;
    }

    &.style-1 {
        padding         : 30px;
        background-color: $secondary;
        border-radius   : 8px;

        .input-group {
            margin-bottom: 0;
        }

        .form-control {
            border-radius: 0px 20px 0px 20px !important;
        }

        button {
            border-radius: 0 20px 0 0;
            padding      : 0px 10px;
        }
    }
}

// widget_services
.widget_nav_menu {
    li {
        a {
            background-color  : $white;
            display           : block;
            border-radius     : 4px;
            padding           : 15px 25px;
            margin-bottom     : 10px;
            color             : $black;
            font-weight       : 600;
            border            : 1px solid rgba(0, 0, 0, 0.1);
            box-shadow        : 5px 12px 20px 0 rgba(0, 0, 0, 0.05);
            transition        : all 0.5s;
            -moz-transition   : all 0.5s;
            -webkit-transition: all 0.5s;
            -ms-transition    : all 0.5s;
            -o-transition     : all 0.5s;
            background-size   : 200%;

            &:hover {
                background-image: var(--gradient);
                color           : $white;
            }
        }

        &.active {
            a {
                background-image: var(--gradient);
                color           : $white;
            }
        }
    }
}

// download-file
.download-file {
    background-image: var(--gradient);
    color           : $white;
    padding         : 25px 25px 20px 20px;
    border-radius   : 4px;

    .title {
        color        : $white;
        margin-top   : 0;
        margin-bottom: 20px;
        text-align   : center;
        font-size    : 20px;
        font-weight  : 600;
    }

    ul {
        list-style: none;
        margin    : 0;
        padding   : 0;

        li {
            a {
                background-color: $white;
                color           : $black;
                display         : block;
                margin-bottom   : 10px;
                border-radius   : 4px;
                width           : 100%;
                padding         : 15px;
                text-transform  : uppercase;
                font-size       : 14px;
                font-weight     : 600;
                text-align      : left;
                align-items     : center;
                display         : flex;
                justify-content : space-between;

                i {
                    width   : 18px;
                    height  : 18px;
                    position: relative;

                    &:after,
                    &:before {
                        content          : "";
                        position         : absolute;
                        width            : 100%;
                        height           : 100%;
                        background-size  : contain;
                        background-repeat: no-repeat;
                        left             : 0;
                        top              : 0;
                    }

                    &:before {
                        background-image : url(.././public/images/icon/download-icon2.png);
                        -webkit-animation: download1 2s ease infinite;
                        animation        : download1 2s ease infinite;
                    }

                    &:after {
                        background-image: url(.././public/images/icon/download-icon1.png);
                    }
                }

                .text {
                    display : inline;
                    position: relative;
                }

                &:hover {
                    .text {
                        color: $primary;
                    }
                }
            }
        }
    }
}

@-webkit-keyframes download1 {

    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform        : translateY(0);
    }

    40% {
        -webkit-transform: translateY(-10px);
        transform        : translateY(-10px);
    }

    60% {
        -webkit-transform: translateY(-10px);
        transform        : translateY(-10px);
    }
}

@keyframes download1 {

    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform        : translateY(0);
    }

    40% {
        -webkit-transform: translateY(-10px);
        transform        : translateY(-10px);
    }

    60% {
        -webkit-transform: translateY(-10px);
        transform        : translateY(-10px);
    }
}