// Header Logo
.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #EFBB20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 150px;
    height: 85px;
    position: relative;
    z-index: 9;
	@include respond('tab-land'){
		width: 130px;
	}
	@include respond('phone'){
		width: 120px;
		height: 75px;
	}
	a{
		display: table-cell;
		vertical-align: middle;
	}
	img {
		height: auto;
		width: 100%;
	}
	
	// Text Logo
	span{
		font-size: 20px;
		letter-spacing: 20px;
	}
}

// Top Bar
.top-bar {
	background-color: $white;
    color: $dark;
    padding: 8px 0;
	border-bottom:1px solid rgba(0,0,0,0.1);
	.dlab-topbar-left {
		float: left;
		li{
			padding-right: 20px;
		}
	}
	.dlab-topbar-right {
		float: right;
		li{
			padding-left: 15px;
		}
	}
	
	.dlab-topbar-center,
	.dlab-topbar-left,
	.dlab-topbar-right {
		padding-left: 15px;
		padding-right: 15px;
		ul{
			list-style:none;
			margin:0;
			padding:0;
			li{
				display:inline-block;
				font-size: 14px;
			}
			i{
				color:$primary;
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				margin-right: 5px;
			}
		}
		.dlab-social-icon{
			li{
				padding-left: 0;
			}
		}
	}
	@include respond('phone-land'){
		display:none;
	}	
}

// Quik Search
.dlab-quik-search{
	background:rgba($black,0.9);
	position:fixed;
	height:100%;
	width:100%;
	top:0;
	left:0;
	padding:0px 30px;
	z-index:999;
	overflow:hidden;
	display:none;
	form{
		width:100%;
		max-width:1200px;
		margin:auto;
		position:relative;
		top:50%;
		@include transitionMedium;
		opacity:1;
	}
	.form-control{
		padding:15px 60px 15px 15px;
		width:100%;
		height:90px;
		border:none;
		background:0 0;
		color:$white;
		font-size:20px;
		border-bottom:2px solid rgba($white,0.2);
	}
	span{
		position:absolute;
		right:15px;
		top:50%;
		margin:-15px 0;
		height:25px;
		font-size:20px;
		cursor:pointer;
	}
}

// Site Header
.site-header {
	position:relative;
	z-index:9999;
	.main-bar {
		background: $white;
		width: 100%;
		position: relative;
	}
	.container{
		position: relative;
	}
	.extra-nav {
		float: right;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 9;
		margin-left: 20px;
		height: 85px;
	
		#quik-search-btn{
			color: #020d26;
			cursor: pointer;
			margin-right: 0px;
			border: 0;
		}
		.btn{
			@include respond('phone'){
				padding-left:15px;
				padding-right:15px;
			}
		}
		
		ul{
			li{
				display:inline-block;
				.icon{
					padding: 0;
					background: transparent;
					min-width: 40px;
					text-align: center;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
				}
			}
		}
		@include respond('phone'){
			display: none;
		}
	}
}

// Navbar Toggler
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 34px 0 32px 15px;
    padding: 0;
	float: right;
	outline: none !important;
	@include respond('phone'){
		margin: 28px 0 28px 15px;
	}
}

// Navicon
.navicon{
	width: 20px;
	height: 18px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	
	span{
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		background: #000;
		
		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2) {
			top: 7px;
		}
		&:nth-child(3) {
			top: 14px;
		}
		
	}
	
	&.open span:nth-child(1) {
	  top: 7px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}
	&.open span:nth-child(2) {
	  opacity: 0;
	  left: -60px;
	}
	&.open span:nth-child(3) {
		top: 7px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}

// Header-tb
.header-tb .navicon span{
	background:#fff;
}



// Header Nav
.header-nav {
    padding: 0;
	
	@include respond('tab-port'){
		overflow-y: scroll;
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh !important;
		@include transitionMedium;
		top:0;
		background-color: $white;
		margin:0;
		z-index:99;
	}
	&.show {
		@include respond('tab-port'){
			left: -1px;
			@include transitionSlow;
			margin:0;
			width: 280px;
		}
	}
	.logo-header{
		display: none;
		@include respond('tab-port'){
			padding: 30px 20px;
			float: none;
			height: auto;
			width: 240px;
		}
	}
	.nav{
		float: right;
		padding: 0;
			
		&>li {
			margin: 0px;
			font-weight: 400;
			position: relative;
			&.has-mega-menu {
				position: inherit;
			}
			@include respond('tab-port'){	
				border-bottom: 1px solid #eee;	
				width: 100%;	
			}
			&>a {
					color: $dark;
					font-size: 15px;
					padding: 35px 15px;
					cursor: pointer;
					font-weight: 600;
					display: inline-block;
					position: relative;
					border-width: 0 0 0 1px;
					text-transform: capitalize;
					line-height: 1;
					@include respond('tab-land'){
						padding:35px 12px;			
					}
					
					span{
						display:inline-block;
					}
				i{
					font-size: 9px;
					margin-left: 3px;
					margin-top: -3px;
					vertical-align: middle;
					
					@include respond('tab-port'){	
						background-color:$primary;
						color: #fff;
						height: 30px;
						line-height: 30px;
						right: 0;
						text-align: center;
						width: 30px;
						z-index: 3;
						float: right;
						font-size: 20px;
						margin: 0;
					}
					&:before {
						@include respond('tab-port'){
							content: "\f105";
						}
					}
				}
				@include respond('tab-port'){	
					padding: 8px 0;
					display: block;	
					line-height: 30px;	
				}
			}
			@include respond('tab-port-min'){	
				&:hover > a span{
					-webkit-animation: upToDown 0.3s forwards;
					-moz-animation: upToDown 0.3s forwards;
					animation: upToDown 0.3s forwards;
				}
			}
			.mega-menu,
			.sub-menu {
				background-color: $white;
				display: block;
				left: 0;
				list-style: none;
				opacity: 0;
				padding: 5px 0;
				position: absolute;
				visibility: hidden;
				width: 220px;
				z-index: 10;
				margin-top: 20px;
				box-shadow:0px 1px 40px 0px rgba(0, 0, 0, 0.1);
				@include transitionSlow;
				@include respond('tab-port'){	
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top:1px solid #eee;
					padding:10px 0;
					@include transitionNone;
					& > li > a i{
						color: #fff!important;
						height: 30px;
						line-height: 30px;
						right: 0;
						text-align: center;
						width: 30px;
						z-index: 3;
						font-size: 20px!important;
						margin: 0!important;	
						background: #aaa;						
					}
				}
				li {
					position: relative;
					a {
						color: #262F5A;
						display: block;
						font-size: 15px;
						padding:10px 25px;
						text-transform: capitalize;
						position:relative;
						transition: all 0.15s linear;
						-webkit-transition: all 0.15s linear;
						-moz-transition: all 0.15s linear;
						-o-transition: all 0.15s linear;
						font-weight: 500;
						
						&:after {
							content: "";
							position: absolute;
							width: 0;
							height: 1px;
							background: $primary;
							
							left: 20px;
							top: 20px;
							transition: all .5s;
							-moz-transition: all .5s;
							-webkit-transition: all .5s;
							-ms-transition: all .5s;
							-o-transition: all .5s;
						}
						
						@include respond('tab-port'){
							padding: 2px 0 1px 15px;
							display: block;
							line-height: 30px;
							position: relative;
							
							&:after{
								content: "\f105";
								font-family: FontAwesome;
								width: auto;
								height: auto;
								background: 0 0!important;
								top: 1px;
								color: #262F5A;
								font-size: 16px;
								position: absolute;
								left: 0;
								font-weight: 700;
							}
						}
					}
					&:hover > a{
						background-color: #F2F2F2;
						padding: 10px 20px 10px 35px;
						color:$primary;
						@include respond('tab-port'){
							padding: 2px 0 1px 15px;
							background:transparent;
						}
						&:after {
							width: 10px;
						}
					}
				}
				li>.sub-menu {
					left: 220px;
					@include transitionMedium;
					
					@include respond('tab-port'){
						display:none;
					}
				}
				@include respond('tab-port'){
					.mega-menu,
					.sub-menu{
						border-top:0;
						padding-left: 20px;
						li  a:after{
							width: 5px;
							content: "";
							height: 5px;
							background: #262F5A !important;
							border-radius: 4px;
							top: 13px;
						}
					}
				}
			}
			.sub-menu{
				li .fa {
					color: inherit;
					display: block;
					float: right;
					font-size: 16px;
					opacity: 1;
					margin-top: 4px;	
				}				
			}
			.mega-menu {
				display: flex;
				right: 0px;
				width: 100%;
				z-index: 9;
				padding: 0;
				&>li {
					display: block;
					padding: 30px 0 25px;
					position: relative;
					vertical-align: top;
					width: 25%;
					@include respond('tab-port'){
						width: 100%;
						padding:0;
					}
					&>a {
						color: $dark;
						display: block;
						padding: 0 20px;
						font-size: 16px;
						font-weight: 600;
						border-bottom: 1px solid rgba(0,0,0,0.1);
						padding-bottom: 15px;
						&>i{
							font-size: 14px;
							margin-right: 5px;
							text-align: center;
							width: 15px;
						}
						@include respond('tab-port'){
							display:none;
						}
					}
					&:after {
						content: "";
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						right: 0px;
						top: 0px;
						display: block;
						width: 1px;
						height: 100%;
						@include respond('tab-port'){
							content:none;
						}
					}
					&:last-child:after {
						display: none;
					}
					&:hover > a{
						background-color: transparent;
					}
					li i{
						font-size: 14px;
						margin-right: 5px;
						text-align: center;
						width: 15px;
					}
					
				}
				@include respond('tab-port'){	
					display: none;
					padding:10px 0;
				}
			}
			&:hover{
				&>.mega-menu,
				&>.sub-menu{
					opacity: 1;
					visibility: visible;
					margin-top: 0;
					@include transitionMedium;
					@include respond('tab-port'){
						@include transitionNone;
					}
					li:hover > .sub-menu{
						opacity: 1;
						visibility: visible;
						margin-top: 0;
						top: -1px;
					}
				}
			}
			&.open{
				.mega-menu,
				.sub-menu{
					display:block;
					opacity:1;
					@include transitionNone;
					
				}
				.open{
					&.menu-item-has-children > a:before{
						transform: rotate(90deg);
						-moz-transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
						-o-transform: rotate(90deg);
					}
					.mega-menu,
					.sub-menu{
						display:block;
						opacity:1;
						@include transitionNone;
					}
				}
			}
		}
		@include respond('laptop'){
			&>li:nth-last-child(3),
			&>li:nth-last-child(2),
			&>li:last-child{
				.sub-menu .sub-menu{
					left:-220px;
				}
			}
		}
		@include custommq($max: 1199px) {
			&>li:last-child{
				.sub-menu{
					left:auto;
					right:0;
				}
			}
		}
		@include respond('tab-port'){
			float: none;
			padding: 0 20px;	
		}
	}
	.dlab-social-icon{
	    display: none;
		text-align:center;
		li {
			display:inline-block;
			margin: 0 2px;
		}
		a{
			width:40px;
			height:40px;
			padding: 0;
			border:1px solid #eee;
			line-height:38px;
			text-align:center;
			border-radius:40px;
		}
		@include respond('tab-port'){
			display:block;
			padding-top:30px;
			padding-bottom:20px;
		}
	}
}

// Header Transparent{
.header-transparent{
	position:absolute;
	width:100%;
	
	a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
		color:#fff;
	}
	.is-fixed,
	&.text-black{
		a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
			color:inherit;
		}
	}
	
	.top-bar,
	.main-bar{
		background: transparent;
	}
	.container-fluid{
		padding-left:80px;
		padding-right:80px;
		@include respond('tab-land'){
			padding-left:15px;
			padding-right:15px;
		}
	}
	
	// Side Menu Btn
	.side-menu-btn{
		.menu-icon-in{
			span{
				background-color:$white;
			}
		}
	}
	.is-fixed{
		.side-menu-btn{
			.menu-icon-in{
				span{
					background-color:$dark;
				}
			}
		}
	}
	@include respond('tab-port-min'){
		.nav{
			&>li {
				&>a {
					color: $white;
				}
			}
		}
	}
	.navbar-toggler{
		span{
			background:$white;
		}
	}
	.is-fixed{
		.nav{
			&>li {
				&>a {
					color: $dark;
				}
			}
		}
		.navbar-toggler{
			span{
				background:$dark;
			}
		}
	}
	
	// Top Bar
	.top-bar{
		border-color:$white;
		.dlab-topbar-left,
		.dlab-topbar-right{
			li,
			i{
				color: $white;
			}
		}
	}
}

// Is Fixed
.is-fixed{
	.main-bar {
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
		transition: all 0.5s;
		background: rgba(255,255,255,1);
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
	}
}

// Mobile Nav
@include respond('tab-port'){
	.mo-left{
		.header-nav{
			overflow-y: scroll;
			position: fixed;
			width: 60px;
			left:-280px;
			height:100vh !important;
			transition:all 0.5s;
			-webkit-transition:all 0.5s;
			-moz-transition:all 0.5s;
			-o-transition:all 0.5s;
			top:0;
			background-color:#fff;
			margin:0;
			z-index:99;
			&.nav-dark{
				background-color:#202020;
			}
			&.show {
				left: -1px;
				transition:all 0.8s;
				-webkit-transition:all 0.8s;
				-moz-transition:all 0.8s;
				-o-transition:all 0.8s;
				margin:0;
				width: 280px;
			}
			.logo-header {
				display: block;
				float: none;
				height: auto!important;
				max-width: 100%;
				padding: 25px 20px;
				width: 100%;
				.light-logo{
					display: none!important;
				}
				.dark-logo{
					display: table-cell!important;
				}
				img{
					max-width: unset;
					width: 160px;
					vertical-align: middle;
				}
			}
			li{
				&.open{
					a{
						position:relative;
					}
				}
			}
		}	
		.navbar-toggler{
			&.open{
				z-index: 99;
				&:after {
					background-color: rgba(0, 0, 0, 0.6);
					content: "";
					left: 0;
					position: fixed;
					right: -20px;
					top: -63px;
					transform: scale(100);
					-o-transform: scale(100);
					-moz-transform: scale(100);
					-webkit-transform: scale(100);
					width: 100%;
					z-index: -1;
					transition: all 0.5s;
					transform-origin: top right;
					margin: 0 0px 0px 20px;
					-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
					box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
				}
				span{
					background:#fff;
				}
			}
		}
	}
}

.text-black{
	.nav{
		&>li {
			&>a {
				color: $dark;
			}
		}
	}
	.navbar-toggler{
		span{
			background:$dark;
		}
	}
}
@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
} 
@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}
@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

/* Logo */
.site-header{
	.main-bar-wraper{
		.logo-header{
			.dark-logo{
				display: table-cell;
			}
			.light-logo{
				display: none;
			}
		}
		&.is-fixed{
			.logo-header{
				.dark-logo{
					display: table-cell;
				}
			}
		}
	}
	&.header-transparent{
		.main-bar-wraper{
			.logo-header{
				.dark-logo{
					display: none;
				}
				.light-logo{
					display: table-cell;
				}
			}
			&.is-fixed{
				.logo-header{
					.light-logo{
						display: none;
					}
					.dark-logo{
						display: table-cell;
					}
				}
			}
		}
	}
	&.text-black{
		.main-bar-wraper{
			.logo-header{
				.dark-logo{
					display: table-cell;
				}
				.light-logo{
					display: none;
				}
			}
			&.is-fixed{
				.logo-header{
					.dark-logo{
						display: table-cell;
					}
				}
			}
		}
	}
}
.site-header{
	.is-fixed{
		.extra-nav{
			.btn.btn-light{
				background-color: #896eff;
				color: #fff;
			}
		}
	}
}