* {
  margin: 0;
  padding: 0;
}

.lla-container {
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
}

.video-container {
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.video-container__player {
  height: 100%;
  width: 100%;
}
.video-container__engine {
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}
.video-container__engine__control-box {
  border-radius: 0.5rem;
  position: absolute;
  background-color: rebeccapurple;
  bottom: 8rem;
  left: 1rem;
  width: 2rem;
  opacity: 0.4;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.video-container__engine__control-box__button {
  border: none;
  background-color: transparent;
  height: 2rem;
}
.video-container__engine__control-box__icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: white;
}
.video-container__engine__overlay {
  color: black;
  border-radius: 0.5rem;
  position: absolute;
  bottom: 15%;
  left: 20%;
  width: 60%;
  opacity: 0.75;
}
.video-container__engine__overlay__item {
  font-size: 1.2rem;
}
.video-container__engine__setting-box {
  border: solid;
  border-radius: 0.5rem;
  border-color: black;
  border-width: 0.2rem;
  position: absolute;
  background-color: white;
  bottom: 1.8rem;
  right: 13.5rem;
  height: 2rem;
  opacity: 0.4;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.video-container__engine__setting-box__button {
  border: none;
  background-color: transparent;
  width: 2rem;
}
.video-container__engine__setting-box__icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: white;
}

